import { BrowserRouter as Router, Route, Routes, Link, useParams, } from 'react-router-dom';

import Landingpage from './Components/homepage/Landinhpage/Landingpage'
import Community from './Components/community/Community'
import Onboard from './Components/community/onboard/Onboard';
import Onboarderror from './Components/community/onboard/Onboarderror';
import Images from './Components/homepage/Image'
import Login from './Components/Login/Login/Login';
import Otp from './Components/Login/OTP/Otp';
import Signup from './Components/Login/Signup/Signup';
import Loading from './Components/Login/Loading/Loading';
import Popup from './Components/homepage/LIkepopup/Popup';
import Tripdetails from './Components/Tripdetails/Tripdetail';
import PaymentComponent from './Components/Razorpay/Razorpay';
import Saved from './Components/Saved-Upcoming-Completed/Saved';
import Future from './Components/future escapes/Future';
import Cancel from './Components/Cancel/Cancel';
import Onboardbooking from './Components/Tripdetails/onboardpopup/onboardbooking';
// import SendOtp from './Components/Login/Login/sendotp';
// import Dropdown from './Components/dropdown/Dropdown';
import Discount from './Components/Tripdetails/Discount/Discountpopup';
// import Responsivefilter from './Components/future escapes/responsivefilter/Responsivefolter';
import Blogs from './Components/Blogs/Blogs';
import BlogDetails from './Components/Blogs/Individualblogs';
import TermsAndConditions from './Components/Termsandconditions/Termsandconditions';
import PrivacyPolicy from './Components/Privacypolicy/Privacypolicy';
import { Helmet } from 'react-helmet';
function App() {


  return (
    <>
      <Router>
        <div>
          <Helmet>
            <title>YEAH - Your Adventure Awaits</title>
            <meta name='description' content='Your Twenties Ticket to Adventure. Explore destinations, enjoy group tours, and make unforgettable memories.' />
            <meta name='keywords' content='trip, tour, adventure trips, youth travel, solo travel, group tours, travel experiences, budget travel, backpacking, travel deals, weekend getaways, adventure seekers, cultural tours, outdoor activities' />
          </Helmet>

          <Routes>
            <Route path='/' exact element={<Landingpage />} />
            <Route path='/community' exact element={<Community />} />
            <Route path='/login' exact element={<Login />} />
            <Route path='/signup' exact element={<Signup />} />
            <Route path='/otp' exact element={<Otp />} />
            <Route path='/:slug' exact element={<Tripdetails />} />
            <Route path='/logincomponent' exact element={<Saved />} />
            <Route path='/futureescapes' exact element={<Future />} />
            <Route path='/blogs' exact element={<Blogs />} />
            <Route path="/blogs/:slug" element={<BlogDetails />} />
            <Route path='/termsandconditions' element={<TermsAndConditions />} />
            <Route path='/privacy-policies' element={<PrivacyPolicy />} />
          </Routes>
        </div>
      </Router>
      {/* <Onboarderror/> */}
      {/* <Onboardbooking/> */}
      {/* <Saved/> */}
      {/* <Tripdetails/> */}
      {/* <PaymentComponent/> */}
      {/* <Dropdown/> */}
      {/* <Images/> */}
      {/* <Onboard/> */}
      {/* <Onboarderror/> */}
      {/* <Popup/> */}
      {/* <Cancel/> */}
      {/* <TripBooking/> */}
    </>

  )
}

export default App
// src/App.js
// import React from 'react';
// import LocationLink from './Components/googlemap/Googlemap';

// const App = () => {
//     const googleMapsUrl = 'https://www.google.com/maps/place/latitude,longitude'; // Replace with actual Google Maps URL
//     const imageUrl = 'path/to/your/image.jpg'; // Replace with actual image path or URL

//     return (
//         <div className="App">
//             <h1>View Location on Google Maps</h1>
//             <LocationLink locationUrl={googleMapsUrl} imageUrl={imageUrl} altText="Location Image" />
//         </div>
//     );
// };

// export default App;
