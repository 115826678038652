import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import arrowleft from './images/arrowleft.png';
import arrowright from './images/arrowright.png';
import './Happytravellers.css';
import axios from 'axios';

function HappyTravellers() {
    const [slides, setSlides] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://yeahtrips.in/happytravellersdetails');
                setSlides(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, 5000);

        return () => clearInterval(interval);
    }, [slides]);

    const handlePrevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
    };

    const handleNextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    };

    const handleIndicatorClick = (index) => {
        setCurrentSlide(index);
    };
    function decodeHTMLEntities(text) {
        if (!text) return text;
        const textarea = document.createElement('textarea');
        textarea.innerHTML = text;
        return textarea.value;
    }
    return (
        <div className="happy-travellers-container">
            <div className="titletravel-container">
                <h1 className="title">Happy Travellers Speak 💖</h1>
            </div>

            <div className="carouseltravel-container">
                {slides.length > 0 && (
                    <div className="carouseltravel-content">
                        <div className="rating">
                            {slides[currentSlide] && Array.from({ length: slides[currentSlide].rating }, (_, index) => (
                                <FontAwesomeIcon key={index} icon={faStar} className="star-icon" />
                            ))}
                        </div>

                        <p className="testimonialtravel-text">{decodeHTMLEntities(slides[currentSlide].title)}</p>
                        <div className="reviewer">
                            <img
                                src={`https://admin.yeahtrips.in/${slides[currentSlide].author_image}`}
                                alt="Reviewer"
                                className="review-image"
                                onError={(e) => { e.target.src = 'https://via.placeholder.com/150'; }} // Fallback image
                            />
                            <p className="reviewer-name">{decodeHTMLEntities(slides[currentSlide].author)}</p>

                            <div className="carouseltravel-controls">
                                <div>
                                    <img src={arrowleft} alt="Previous" className="arrow-icon" onClick={handlePrevSlide} />
                                </div>

                                <div>
                                    <img src={arrowright} alt="Next" className="arrow-icon" onClick={handleNextSlide} />
                                </div>
                            </div>
                        </div>
                        <div className="carouseltravel-indicators">
                            {slides.map((slide, index) => (
                                <div
                                    key={index}
                                    className={`carouseltravel-indicator ${index === currentSlide ? 'carouseltravel-indicator-active' : ''}`}
                                    onClick={() => handleIndicatorClick(index)}
                                ></div>
                            ))}
                        </div>
                    </div>
                )}
                {slides.length > 0 && (
                    <div className="carouseltravel-image">
                        <img
                            src={`https://admin.yeahtrips.in/${slides[currentSlide].image}`}
                            alt="Happy travel"
                            onError={(e) => { e.target.src = 'https://via.placeholder.com/150'; }} // Fallback image
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default HappyTravellers;
