import React, { useEffect } from 'react';
import './Dropdown.css';
// import Search from './Images/search-sm.svg'

function Dropdown({ data, selectedOptions, setSelectedOptions }) {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [groupedOptions, setGroupedOptions] = React.useState({});

  useEffect(() => {
    const groupedData = data.reduce((acc, item) => {
      if (!acc[item.group]) {
        acc[item.group] = new Set();
      }
      acc[item.group].add(item.option);
      return acc;
    }, {});

    const groupedOptionsArray = Object.keys(groupedData).reduce((acc, group) => {
      acc[group] = Array.from(groupedData[group]);
      return acc;
    }, {});

    setGroupedOptions(groupedOptionsArray);
  }, [data]);

  const handleCheckboxChange = (event, option) => {
    event.stopPropagation();
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.includes(option)
        ? prevSelectedOptions.filter((o) => o !== option)
        : [...prevSelectedOptions, option]
    );
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDropdownClick = (event) => {
    event.stopPropagation();
  };

  const handleRemoveSelectedOption = (option) => {
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.filter((o) => o !== option)
    );
  };

  const filteredOptions = Object.keys(groupedOptions).reduce((acc, group) => {
    const filteredGroup = groupedOptions[group].filter((option) =>
      option.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (filteredGroup.length > 0) {
      acc[group] = filteredGroup;
    }
    return acc;
  }, {});

  return (
    <div className='dropdown-container' onClick={handleDropdownClick}>
      <input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={handleSearchChange}
        className="input-search"
      />
      <div className='dropdown-choice'>
        {Object.keys(filteredOptions).map((group) => (
          <div key={group} className="option-group">
            <div className="group-title">{group}</div>
            {filteredOptions[group].map((option, index) => (
              <div className='dropdown-option' key={index}>
                <label>
                  <input
                    type="checkbox"
                    checked={selectedOptions.includes(option)}
                    onChange={(e) => handleCheckboxChange(e, option)}
                  />
                  {option}
                </label>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Dropdown;