import React, { useState, useEffect } from "react";
import alerticon from './images/alerticon.svg';
import whatsapp from './images/whatsapp.svg';
import cross from './images/cross.svg';
import './onboardbooking.css';
import axios from 'axios';

function Onboardbooking({ trip_id }) {
    const [isVisible, setIsVisible] = useState(true);
    const [whatsapplink, setWhatsapplink] = useState("");
    const [slug, setSlug] = useState(""); // State to hold the slug

    useEffect(() => {
        // Fetch the WhatsApp link and slug from the database
        const fetchTripDetails = async () => {
            try {
                const response = await axios.get(`https://yeahtrips.in/tripdetailswhatsappabdslug/${trip_id}`);
                setWhatsapplink(response.data.whatsapplink);
                setSlug(response.data.slug);
            } catch (error) {
                console.error("Error fetching trip details:", error);
            }
        };

        fetchTripDetails();

        if (isVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        // Cleanup on component unmount
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isVisible, trip_id]);

    const onClose = () => {
        setIsVisible(false);
        window.location.reload(); // This will cause a full page reload
    };

    const openWhatsApp = () => {
        if (whatsapplink) {
            window.open(whatsapplink, '_blank');
        }
    };

    const shareViaWhatsApp = () => {
        const message = `Check out this amazing trip! https://yeahtrips.in/${slug}`;
        const url = `https://wa.me/?text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
    };

    if (!isVisible) {
        return null;
    }

    return (
        <div>
            <div className="overlaybooking"></div>
            <div className="onboard-container">
                <div className="onboardcross" onClick={onClose}>
                    <img src={cross} alt="closeicon" />
                </div>
                <div className="oncontainer">
                    <div className="boardtext">
                        <div className="boardimage">
                            <img src={alerticon} alt="success alert icon" />
                        </div>
                        <div className="onboardText">
                            <h6>Congratulations, You’re On Board!</h6>
                            <p>We have added you to our WhatsApp Community</p>
                        </div>
                        <div className="onboardbutton">
                            <button onClick={openWhatsApp}>
                                <img src={whatsapp} alt="WhatsApp icon" />
                                <span>Go to WhatsApp</span>
                            </button>
                        </div>
                        <div className="sharetripdetail">
                            <p onClick={shareViaWhatsApp} style={{ cursor: 'pointer' }}>Share Trip Details</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Onboardbooking;
