import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import Funnel from './Images/filter-funnel-02.svg';
import './similartrips.css';
import Calendar from './Images/calendar-date.svg';
import Arrow from './Images/arrow-right.svg';
import Location from './Images/Icon.svg';
import X from './Images/x-close.svg';
import Arrowleft from './Images/arrow-left.svg';
import Arrowright from './Images/rightarrow.svg';
import similararrowright from './Images/similarheadingarrow.svg'
import '@fortawesome/fontawesome-free/css/all.min.css';
import { format, startOfMonth, endOfYear, isAfter, isBefore } from 'date-fns';
import Onboardliketripdetailpoperror from '../liketripsdetails/Liketripdetailssave';
function Similartrips() {
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedDestinations, setSelectedDestinations] = useState([]);
  const [selectedTripTypes, setSelectedTripTypes] = useState([]);
  const [selectedSortBy, setSelectedSortBy] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [visible, setVisible] = useState(false);
  const [dropdownData, setDropdownData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const [likedItems, setLikedItems] = useState({});
  const [isDateVisible, setIsDateVisible] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const navigate = useNavigate();
  const [likedTripIds, setLikedTripIds] = useState([]);
  // Helper function to parse custom date strings
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://yeahtrips.in/future/escapes');
        const today = new Date(); // Current date
  
        const filteredTrips = response.data.filter((trip) => {
          // Remove ordinal suffixes and parse the trip_start_date
          const cleanedDateString = trip.trip_start_date.replace(/(\d+)(st|nd|rd|th)/g, '$1');
          const tripStartDate = new Date(cleanedDateString); // Create a Date object
  
          // Check if the trip is published and the start date is today or in the past
          return trip.status === 'published' && tripStartDate && tripStartDate >= today;
        });
  
        setDatas(response.data); // You may want to adjust this if needed
        setFilteredData(filteredTrips);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.response ? error.response.data : error.message || 'Error fetching data');
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  

  useEffect(() => {
    const loadLikedItems = () => {
      const likedTripIds = JSON.parse(localStorage.getItem('likedTripIds')) || [];
      const likedItemsMap = likedTripIds.reduce((acc, trip_id) => {
        acc[trip_id] = true;
        return acc;
      }, {});
      setLikedItems(likedItemsMap);
    };
    loadLikedItems();
  }, []);

  const parseCustomDate = (dateString) => {
    try {
      const regex = /^(\d{1,2})[a-z]{2}\s(\w+)\s(\d{4})$/;
      const match = dateString.match(regex);
      if (match) {
        const [, day, month, year] = match;
        const monthIndex = new Date(Date.parse(month + " 1, 2024")).getMonth();
        return new Date(year, monthIndex, day);
      } else {
        throw new Error('Invalid date format');
      }
    } catch (error) {
      console.error('Date parsing error:', error);
      return null;
    }
  };
  const formatDate = (dateString) => {
    const months = {
        January: 'Jan', February: 'Feb', March: 'Mar', April: 'Apr', May: 'May', June: 'Jun',
        July: 'Jul', August: 'Aug', September: 'Sep', October: 'Oct', November: 'Nov', December: 'Dec'
    };

    // Extract the date and month from the input string
    const [day, monthName, year] = dateString.split(' ');

    // Convert month name to abbreviated month
    const month = months[monthName.replace(/[^a-zA-Z]/g, '')];

    // Return the formatted date string
    return `${day.replace(/[^0-9]/g, '')} ${month}' ${year}`;
};
  const formatCustomDate = (date) => {
    try {
      return date && !isNaN(date.getTime()) ? format(date, 'dd MMMM yyyy') : 'Invalid date';
    } catch (error) {
      console.error('Date formatting error:', error);
      return 'Invalid date';
    }
  };
  const handleClosePopup = () => {
    setShowPopup(false);
    window.location.reload();
  };
  const toggleDropdown = (event, identifier) => {
    if (event) event.stopPropagation();

    let dataToPass;
    if (identifier === 'destination') {
      dataToPass = uniqueDestinations.map(option => ({ group: "Destination", option }));
    } else if (identifier === 'tripType') {
      dataToPass = uniqueTripTypes.map(option => ({ group: "Trip Types", option }));
    } else if (identifier === 'SortBy') {
      dataToPass = uniqueSortBy.map(option => ({ group: "Sort By", option }));
    }

    setActiveDropdown(activeDropdown === identifier ? null : identifier);
    setDropdownData(dataToPass);
  };

  const onClick = () => {
    setVisible(!visible);
    document.body.classList.toggle('no-scroll', !visible);
  };

  const clearAllFilters = () => {
    setSelectedDestinations([]);
    setSelectedTripTypes([]);
    setSelectedSortBy([]);
    setFilteredData(datas);
    setStartDate('');
    setEndDate('');
    setIsDateVisible(false);
  };

  const removeSelectedOption = (option, setSelectedFunction, filterType) => {
    setSelectedFunction(prevSelected => {
      const updatedSelected = prevSelected.filter(item => item !== option);
      applyFilters(updatedSelected, filterType);
      return updatedSelected;
    });
  };

  const applyFilters = (updatedSelections = null, filterType = null) => {
    let filtered = datas;

    const destinations = filterType === 'destination' ? updatedSelections : selectedDestinations;
    const tripTypes = filterType === 'tripType' ? updatedSelections : selectedTripTypes;
    const sortBy = filterType === 'SortBy' ? updatedSelections : selectedSortBy;

    if (startDate && endDate) {
      filtered = filtered.filter(item => {
        const tripStartDate = new Date(item.trip_start_date);
        const tripEndDate = new Date(item.end_date);
        const selectedStartDate = new Date(startDate);
        const selectedEndDate = new Date(endDate);

        return tripStartDate >= selectedStartDate && tripStartDate <= selectedEndDate &&
          tripEndDate >= selectedStartDate && tripEndDate <= selectedEndDate ||
          (tripStartDate <= selectedStartDate && tripEndDate >= selectedEndDate);
      });
    }

    if (destinations.length > 0) {
      filtered = filtered.filter(item => destinations.includes(item.destination));
    }

    if (tripTypes.length > 0) {
      filtered = filtered.filter(item => tripTypes.includes(item.trip_type));
    }

    if (sortBy.length > 0) {
      filtered = filtered.filter(item => sortBy.includes(item.seat_type));
    }

    setFilteredData(filtered);
    setVisible(false);
    document.body.classList.remove('no-scroll');
  };

  const selectedFiltersCount = selectedDestinations.length + selectedTripTypes.length + selectedSortBy.length;

  const handleNextPage = () => {
    if (currentPage * itemsPerPage < filteredData.length) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const currentItems = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const handleLikeToggle = async (trip_id, e) => {
    if (e) {
      e.preventDefault();
    }
  
    const token = localStorage.getItem('accessToken');
    if (!token) {
      setShowPopup(true); // Show the popup if no token is found
      return; // Exit the function to prevent liking the trip
    }
  
    try {
      const { userId } = JSON.parse(atob(token.split('.')[1]));
  
      setLikedItems(prev => {
        const newLikedItems = {
          ...prev,
          [trip_id]: !prev[trip_id],
        };
        
        const updatedLikedTripIds = Object.keys(newLikedItems).filter(id => newLikedItems[id]);
        localStorage.setItem('likedTripIds', JSON.stringify(updatedLikedTripIds));
        
        return newLikedItems;
      });
  
      const currentState = { ...likedItems, [trip_id]: !likedItems[trip_id] };
  
      if (currentState[trip_id]) {
        await axios.post('https://yeahtrips.in/api/saveTrip', {
          trip_id,
          userId,
        });
      } else {
        await axios.post('https://yeahtrips.in/api/removeTrip', {
          trip_id,
          userId,
        });
      }
    } catch (error) {
      console.error('Error saving or removing liked trip:', error);
    }
  };
  
  return (
    <>
      <div className='similartripsheadingcontainer'>
        <div className='similartripsheading'>
          <h1>Similar Trips 🚗</h1>
        </div>
        <div className='similartripsviewmmorecontainer'>
          <div className='similartripviewmorecontainertext'>
            <Link to='/futureescapes'><p>View All Trips ({currentItems.length}) </p></Link>
          </div>
          <div className='viewmoresimilartriparrowimg'>
            <img src={similararrowright} alt="" />
          </div>
        </div>
      </div>

      <div className='background_futuredetail'>


        <div className='future_outlinedetail'>
          <div className='future_imagecontainerdetail'>
            {currentItems.map((item) => (
              
              <img src={`https://admin.yeahtrips.in${item.file_path}`} alt="images" key={item.trip_id} />
            ))}
          </div>

          {currentItems.map((item) => {
            const formattedStartDate = formatDate(item.trip_start_date);
            return (
                <Link to={`/${item.slug}`} className='future-contentsdetail' key={item.trip_id}>
                    <div className='future-entiredetail'>
                        <div className='content-group1detail'>
                            <div className='future-seatsdetail'>Only {item.totalseats-item.seats} seats left</div>
                            <div className='heart-containerdetail'>
                                <i
                                    className={`fa-heart ${likedItems[item.trip_id] ? 'fas red' : 'far'}`}
                                    onClick={(e) => handleLikeToggle(item.trip_id, e)}
                                ></i>
                            </div>
                        </div>
                        <div className='content-group2detail'>
                            <div className='futuretripnamehomepagedetail'>{item.trip_name}</div>
                            <div className='content-group3detail'>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}  className='tripnameinsimilartrips'>
                                    <img src={Calendar} alt="Calendar Icon" />
                                    <span>{formattedStartDate}</span>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }} className='tripnameinsimilartrips'>
                                    <img src={Arrow} alt="Arrow Icon" />
                                    <span>{formatDate(item.end_date)}</span>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                                <img src={Location} alt="Location Icon" />
                                <span>Starts from {item.trip_start_point}</span>
                            </div>
                        </div>
                    </div>
                </Link>
            );
        })}
        {showPopup && <div className="overlaypopup"></div>}
        {showPopup && <Onboardliketripdetailpoperror onClose={handleClosePopup}  isVisible={showPopup}/>}
        </div>

      </div>



    </>
  );
}

export default Similartrips;
