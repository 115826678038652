import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Team.css';
import background from './images/BACKGROUND.svg';
import backgroundpurple from './images/backgroundframepurple.svg';

function Team() {
    const [teamMembers, setTeamMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTeamData = async () => {
            try {
                const response = await axios.get('https://yeahtrips.in/gettheteam');
                
                if (response.data.success) {
                    const displayedMembers = response.data.data.filter(member => member.display === 1);
                    setTeamMembers(displayedMembers);
                } else {
                    setError('Failed to load team info.');
                }
            } catch (err) {
                setError('Error fetching team info.');
            } finally {
                setLoading(false);
            }
        };

        fetchTeamData();
    }, []);

    const founders = teamMembers.filter(
        (member) => member.position.toLowerCase() === 'founder' || member.position.toLowerCase() === 'co-founder'
    );

    const others = teamMembers.filter(
        (member) => member.position.toLowerCase() !== 'founder' && member.position.toLowerCase() !== 'co-founder'
    );

    const displayedMembers = [...founders, ...others].slice(0, 6);

    return (
        <div className="team-container">
            <div className='backgroundtopteamss'>
                <div className="backgroundformeetganghomepage">
                    <img src={background} alt="" />
                </div>
            </div>
            <div className="meetganghomepagehearderandbackgrndcontainer">
                <div>
                    <div>
                       
                        <div className="team-header">
                            <div className="team-title">
                                <h1>Say Hello to Our Gang 👋🏻</h1>
                            </div>
                            <div className="team-subtitle">
                                <p>
                                    Our philosophy is simple — hire a team of diverse, passionate people and foster a <br />
                                    culture that empowers you to do your best work.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="team-members">
                {displayedMembers.map((member) => (
                    <div className="member" key={member.id}>
                        <div className="member-image">
                            <img src={`https://admin.yeahtrips.in${member.profile_image}`} alt="" />
                        </div>
                        <div className="member-info">
                            <p className="member-name">{member.name}</p>
                            <p className="member-role">{member.position}</p>
                        </div>
                    </div>
                ))}
            </div>

            <div className="backgroundpurplesetusaprthomepage">
                <img src={backgroundpurple} alt="" />
            </div>
        </div>
    );
}

export default Team;
