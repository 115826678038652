import React, { useState, useEffect, useRef } from "react";
import { CSSTransition } from 'react-transition-group';
import axios from 'axios';
import { useParams, useLocation, Link } from "react-router-dom"
import './Tripdetails.css';
import home from './Images/home.svg';
import righticonpath from './Images/righticonpath.svg'
import zapimage from './Images/zap.svg';
import ticket from './Images/ticket.svg';
import heart from './Images/heart.svg';
import payment from './Images/payment.svg';
import visa from './Images/visa.svg';
import pay from './Images/pay.svg';
import gpay from './Images/gpay.svg';
import mastercard from './Images/mastercard.svg';
import amazonpay from './Images/amazonpay.svg';
import chevrondown from './Images/chevrondown.svg';
import chevrondownreadmore from './Images/chevron-down.svg';
import tripimage from './Images/tripimage.svg';
import inclusionicon from './Images/inclusionicon.svg';
import inclusion from './Images/inclusion.svg';
import exclusion from './Images/exclusion.svg';
import pointtonoteicon from './Images/infocircle.svg';
import flagicon from './Images/flagicon.svg';
import map from './Images/map.svg';
import googlemap from './Images/googlemaps.svg';
import startingpointimage from './Images/startingpoint.svg';
import arrowright from './Images/arrowright.svg';
import coordinatorsicon from './Images/cordinatorsicon.svg';
import chevronleft from './Images/chevronleft.svg';
import chevronright from './Images/chevronright.svg';
import cordinator1 from './Images/cordinator1.svg';
import refundpolicy from './Images/refundpolicyicon.svg';
import tripstartdateimg from './Images/tripstartdate.svg';
import enddateicon from './Images/enddate.svg';
import locationicon from './Images/locationicon.svg';
import tripendslocation from './Images/tripendslocation.svg';
import durationicon from './Images/durationicon.svg';
import seatsicon from './Images/seatsicon.svg';
import travellertypeicon from './Images/travellertypeicon.svg';
import Setusapart from "../homepage/setusapart/Setusapart";
import HappyTravellers from "../homepage/happytraveller/Happytravellers";
import Moments from "../homepage/moments/Moments";
import Getintouch from "../homepage/getintouch/Getintouch";
import Footer from "../homepage/Footer/Footer";
import ImageCarousel from './courasaltrip/Courosal';
import Navbar from "../homepage/Navbar/Navbar";
import Onboardbooking from "./onboardpopup/onboardbooking";
import { FaHeart } from 'react-icons/fa';
import Similartrips from "./similartrips/similartrips";
import Onboardliketripdetailpoperror from "./liketripsdetails/Liketripdetailssave";
import compass from './Images/compass.svg'
import Discount from "./Discount/Discountpopup";
import { Helmet } from "react-helmet";
const Tripdetails = () => {
    const { slug } = useParams();
    const [phoneCodes, setPhoneCodes] = useState([]);
    const [phoneCode, setPhoneCode] = useState('+91');
    const [likedItems, setLikedItems] = useState({});
    const [selectedIndicator, setSelectedIndicator] = useState('itinerary');
    const [visibleDay, setVisibleDay] = useState(0);
    const [seats, setSeats] = useState(1);
    const [selectedCountry, setSelectedCountry] = useState('IND'); // Default selected country code
    const [city, setCity] = useState(['']);
    const [showInput, setShowInput] = useState(false);
    const [ages, setAges] = useState(Array.from({ length: seats }, () => ''));
    const [showAdditionalInfo, setShowAdditionalInfo] = useState(Array.from({ length: seats }, () => false));
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const scrollContainerRef = useRef(null);
    const [tripId, setTripId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showdiscountPopup, setShowdiscountPopup] = useState(false);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [showPopuplike, setShowPopuplike] = useState(false);
    const [coupon, setCoupon] = useState(null);
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [expandedDays, setExpandedDays] = useState([0]); // Day 1 is open by default

    const [coordinators, setCoordinators] = useState([]);
    const [memberdetails, setMemberdetails] = useState([
        { fullname: '', age: '', phonenumber: '+91', whatsappnumber: '+91', email: '', city: '', state: '', country: 'India' }
    ]);
    const [tripData, setTripData] = useState(0);
    const [images, setImages] = useState([]);
    const [isWhatsAppEnabled, setIsWhatsAppEnabled] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState("");
    const [whatsappNumber, setWhatsappNumber] = useState("");

    const handlecountriesChange = (index, e) => {
        const { name, value } = e.target;
        const updatedMembers = [...memberdetails];
        updatedMembers[index] = { ...updatedMembers[index], [name]: value };
        setMemberdetails(updatedMembers);
    };

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            const { userId } = JSON.parse(atob(token.split('.')[1]));
            setUserId(userId);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            window.scrollTo(0, 0);
            try {
                const response = await axios.get(`https://yeahtrips.in/trips/${slug}`);
                const tripDetails = response.data.tripDetails;
                
                setData(tripDetails);
                const tripIds = tripDetails.map(day => day.trip_id);
                setTripId(response.data.trip_id);

                const imageUrls = tripDetails.map(day => day.file_path);

                setImages(imageUrls);
                const coordinatorResponse = await axios.get(`https://yeahtrips.in/tripcoordinators/${slug}`);
                setCoordinators(coordinatorResponse.data);

                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error);
                setLoading(false);
            }
        };
        fetchData();
    }, [slug]);
    const [isProcessing, setIsProcessing] = useState(false);




    useEffect(() => {
        fetchPhoneCodes();
    }, []);


    const fetchPhoneCodes = () => {
        axios.get('https://yeahtrips.in/phonecode')
            .then(response => {
                const data = response.data;
                setPhoneCodes(data);

                const defaultCode = data.find(c => c.iso3 === selectedCountry)?.phonecode || '91';
                setPhoneCode(`+${defaultCode}`);

                const updatedMembers = [...memberdetails];
                updatedMembers[0] = {
                    ...updatedMembers[0],

                    phonenumber: `+${defaultCode} `
                };
                setMemberdetails(updatedMembers);
            })
            .catch(error => console.error('Error fetching phone codes:', error));
    };



    const formatDate = (dateString) => {
        if (!dateString) {
            return 'Invalid date';
        }

        const months = {
            January: 'Jan', February: 'Feb', March: 'Mar', April: 'Apr',
            May: 'May', June: 'Jun', July: 'Jul', August: 'Aug',
            September: 'Sep', October: 'Oct', November: 'Nov', December: 'Dec'
        };

        const parts = dateString.split(' ');
        if (parts.length < 3) {
            return 'Invalid date';
        }

        const [day, monthName, year] = parts;

        const month = months[monthName.replace(/[^a-zA-Z]/g, '')];

        return month ? `${day.replace(/[^0-9]/g, '')} ${month}' ${year}` : 'Invalid date';
    };


    const handleAgeChange = (e, index) => {
        const ageValue = e.target.value;

        if (!isNaN(ageValue) || ageValue === '') {
            const updatedAges = [...ages];
            updatedAges[index] = ageValue;
            setAges(updatedAges);

            const updatedMembers = [...memberdetails];
            updatedMembers[index] = { ...updatedMembers[index], age: ageValue };
            setMemberdetails(updatedMembers);

            if (ageValue === '' || !isNaN(ageValue)) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    age: '',
                }));
            }

            const updatedInfo = [...showAdditionalInfo];
            updatedInfo[index] = parseInt(ageValue) > 30;
            setShowAdditionalInfo(updatedInfo);


        }
    };




    const handleCountryChange = (e, index) => {
        const selectedCode = phoneCodes.find(code => code.iso3 === e.target.value)?.phonecode || '91';
        const formattedCode = `+${selectedCode}`;

        const updatedMembers = [...memberdetails];
        updatedMembers[index] = {
            ...updatedMembers[index],
            country: e.target.value,
            phonenumber: updatedMembers[index].phonenumber.startsWith('+') ?
                `${formattedCode} ${updatedMembers[index].phonenumber.split(' ').slice(1).join('')}` :
                formattedCode,
            whatsappnumber: updatedMembers[index].whatsappnumber.startsWith('+') ?
                `${formattedCode} ${updatedMembers[index].whatsappnumber.split(' ').slice(1).join('')}` :
                formattedCode
        };

        setSelectedCountry(e.target.value);
        setMemberdetails(updatedMembers);
    };
    const handleCountryChangewaitinglist = (e) => {
        // Get the selected country code
        const country = e.target.value;
        setSelectedCountry(country);

        // Find the corresponding phone code or default to '91' (India)
        const code = phoneCodes.find(c => c.iso3 === country)?.phonecode || '91';
        setPhoneCode(`+${code}`);

        // Reset the phone number when country changes
        setPhoneNumber('');
    };




    const handleChevronClick = (dayIndex) => {
        if (expandedDays.includes(dayIndex)) {
            // If the day is already expanded, remove it from the array (collapse it)
            setExpandedDays(expandedDays.filter((day) => day !== dayIndex));
        } else {
            // Otherwise, add it to the array (expand it)
            setExpandedDays([...expandedDays, dayIndex]);
        }
    };


    const handleSeatChange = (delta) => {
        setSeats(prevSeats => Math.max(1, prevSeats + delta));
    };

    useEffect(() => {
        const uniqueCosts = [...new Set(data.map(item => item.cost))];
        if (uniqueCosts.length > 0) {
            setTripData(uniqueCosts[0]);
        }
    }, [data]);

    const availableSeatsArray = data.map(item => ({
        availableSeats: item.totalseats - item.seats
    }));

    const availableSeats = availableSeatsArray[0]?.availableSeats || 0;



    const handleSeatChanges = (change) => {
        setSeats(prevSeats => {
            const newSeats = prevSeats + change;
            return newSeats <= availableSeats ? newSeats : availableSeats; // Ensure seats do not exceed availableSeats
        });

        const newMembers = [...memberdetails];
        if (newSeats > newMembers.length) {
            for (let i = newMembers.length; i < newSeats; i++) {
                newMembers.push({ fullname: '', email: '', phonenumber: '', city: '', whatsappnumber: '', state: '', age: '', country: '' });
            }
        } else {
            newMembers.length = newSeats;
        }
        setMemberdetails(newMembers);
        setAges(Array.from({ length: newSeats }, () => ''));
        setShowAdditionalInfo(Array.from({ length: newSeats }, () => false));
    };

    useEffect(() => {
        fetchStates('India'); // Fetch Indian states on initial load
    }, []);


    const [paymentData, setPaymentData] = useState(null);
    const handlePrev = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: -scrollContainerRef.current.clientWidth,
                behavior: 'smooth'
            });
        }
    };

    const handleNext = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: scrollContainerRef.current.clientWidth,
                behavior: 'smooth'
            });
        }
    };
    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    const handlePayment = async () => {
        setIsProcessing(true);

        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

        if (!res) {

            setIsProcessing(false);
            return;
        }

        try {
            const response = await axios.post('https://yeahtrips.in/api/payment/order', {
                trip_id: tripId,
                amount: totalcost - discountAmount,
            });
            const { data } = response;
            setPaymentData(data);
            setIsProcessing(false);

            const options = {
                key: 'rzp_live_6ZvRZ79iUjcTRQ',
                amount: data.amount,
                currency: data.currency,
                order_id: data.id,
                name: 'LetsGoForACamp Travel And Innovation LLP',
                description: 'payment',
                image: 'https://via.placeholder.com/150',
                handler: async function (response) {
                    const paymentResult = {
                        razorpay_order_id: response.razorpay_order_id,
                        razorpay_payment_id: response.razorpay_payment_id,
                        razorpay_signature: response.razorpay_signature,
                        amount: totalcost,
                        currency: data.currency,
                        trip_id: tripId,
                        user_id: userId,
                        seats: seats,
                        couponcode: couponcode,
                        discount_amount: discountAmount,

                    };


                    try {
                        const verifyResponse = await axios.post('https://yeahtrips.in/api/payment/verify', { paymentResult, memberdetails });
                        if (verifyResponse.data.status === 'success') {
                            setShowPopup(true);
                        }
                    } catch (verifyError) {
                        console.error('Error in payment verification:', verifyError);
                    }
                },
                theme: {
                    color: '#F37254',
                },
                method: {
                    netbanking: true,
                    card: true,
                    wallet: true,
                    upi: true,
                    qr: true,
                },
            };
            const rzp1 = new window.Razorpay(options);
            rzp1.open();
        } catch (error) {
            console.error('Error in creating order:', error);
        }
    };


    const handleWhatsAppsliderToggle = (index) => {
        const updatedMembers = [...memberdetails];

        if (isWhatsAppEnabled) {
            // If WhatsApp is enabled, set whatsappnumber to be the same as phonenumber
            updatedMembers[index] = {
                ...updatedMembers[index],
                whatsappnumber: updatedMembers[index].phonenumber
            };
        } else {
            // If WhatsApp is disabled, clear the whatsappnumber
            updatedMembers[index] = {
                ...updatedMembers[index],
                whatsappnumber: ''
            };
        }

        setMemberdetails(updatedMembers);
        setIsWhatsAppEnabled(!isWhatsAppEnabled);

        // If WhatsApp is now enabled, ensure that the change is handled properly
        if (!isWhatsAppEnabled) {
            handleChange(index, { target: { name: 'whatsappnumber', value: updatedMembers[index].phonenumber } });
        }
    };

    const sectionRefs = useRef({
        itinerary: null,
        'trip-essentials': null,
        'other-details': null,
    });

    const handleIndicatorClick = (indicatorId) => {
        setSelectedIndicator(indicatorId);
        document.getElementById(indicatorId).scrollIntoView({ behavior: 'smooth' });
    };
    useEffect(() => {
        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setSelectedIndicator(entry.target.id);
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, {
            root: null,
            threshold: 0.2, // Adjust this value to control when the section is considered "visible"
        });

        Object.keys(sectionRefs.current).forEach((sectionId) => {
            const sectionElement = document.getElementById(sectionId);
            if (sectionElement) {
                observer.observe(sectionElement);
            }
        });

        return () => observer.disconnect();
    }, []);


    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };
    const handleClosePopup = () => {
        setShowPopup(false);
        window.location.reload();
    };
    const handleClosediscountPopup = () => {
        setShowdiscountPopup(false);
    };
    const handleClosePopupdetailtrip = () => {
        setShowPopuplike(false);
        window.location.reload();

    };
    const [isSaved, setIsSaved] = useState(false);

    const handleWhatsAppSliderToggleWaitingList = () => {
        setIsWhatsAppEnabled(prevState => !prevState);
        if (!isWhatsAppEnabled) {
            setWhatsappNumber(phoneNumber);
        }
    };
    useEffect(() => {
        // Load initial liked items from localStorage
        const savedTrips = JSON.parse(localStorage.getItem('likedTripIds')) || [];
        const likedItemsState = savedTrips.reduce((acc, id) => {
            acc[id] = true;
            return acc;
        }, {});
        setLikedItems(likedItemsState);
    }, []);

    const handleLikeToggle = async (trip_id, e) => {
        if (e) {
            e.preventDefault();
        }

        const token = localStorage.getItem('accessToken');
        if (!token) {
            setShowPopuplike(true);
            return;
        }
        if (token) {
            setLikedItems(prev => {
                const newLikedItems = {
                    ...prev,
                    [trip_id]: !prev[trip_id],
                };

                const updatedLikedTripIds = Object.keys(newLikedItems).filter(id => newLikedItems[id]);
                localStorage.setItem('likedTripIds', JSON.stringify(updatedLikedTripIds));

                return newLikedItems;
            });

            try {
                const { userId } = JSON.parse(atob(token.split('.')[1]));

                const apiPayload = {
                    trip_id,
                    userId,
                };


                if (likedItems[trip_id]) {
                    await axios.post('https://yeahtrips.in/api/removeTrip', apiPayload);
                } else {
                    await axios.post('https://yeahtrips.in/api/saveTrip', apiPayload);
                }
            } catch (error) {
                console.error('Error saving or removing liked trip:', error);
            }
        }

    };


    useEffect(() => {
        axios.get('https://yeahtrips.in/countries')
            .then(response => {
                const countryNames = response.data.map(country => country.name);
                setCountries(countryNames);
            })
            .catch(error => {
                console.error("There was an error fetching the countries!", error);
            });
    }, []);


    const fetchStates = (country) => {
        axios.get(`https://yeahtrips.in/states?country=${country}`)
            .then(response => {
                // Extract states array if the response is an object with a 'states' property
                if (response.data && Array.isArray(response.data.states)) {
                    setStates(response.data.states);
                } else {
                    console.error('Unexpected response format:', response.data);
                }
            })
            .catch(error => {
                console.error("There was an error fetching the states!", error);
            });
    };
    const [cities, setCities] = useState([]);
    const fetchCities = (state) => {
        axios.get(`https://yeahtrips.in/cities?state=${state}`)
            .then(response => {
                if (response.data && Array.isArray(response.data.cities)) {
                    setCities(response.data.cities);
                } else {
                    console.error('Unexpected response format:', response.data);
                }
            })
            .catch(error => {
                console.error("There was an error fetching the cities!", error);
            });
    };

    const handleCityChange = (index, e) => {
        const { value } = e.target;
        const updatedMembers = [...memberdetails];
        updatedMembers[index] = { ...updatedMembers[index], city: value };
        setMemberdetails(updatedMembers);
    };
    const handleChange = (index, e) => {
        const { name, value } = e.target;
        const updatedMembers = [...memberdetails];
        const newErrors = { ...errors };


        if (name === 'phonenumber' || name === 'whatsappnumber') {
            // Get the existing phone code
            const phoneCode = updatedMembers[index][name]?.split(' ')[0] || '';
            const phoneNumber = value.replace(phoneCode, '').trim(); // Remove phone code from the input

            // Update the member's details with the phone code and number
            updatedMembers[index] = {
                ...updatedMembers[index],
                [name]: `${phoneCode} ${phoneNumber}`.trim() // Make sure to concatenate phone code and number
            };


            // Validate phone number and WhatsApp number
            if (name === 'phonenumber') {
                newErrors.phonenumber = phoneNumber ? '' : 'Phone number is required.';
            }
            if (name === 'whatsappnumber') {
                newErrors.whatsappnumber = phoneNumber ? '' : 'WhatsApp number is required.';
            }

        } else {
            updatedMembers[index] = { ...updatedMembers[index], [name]: value };
        }

        // Clear errors for non-phone fields when data is entered
        if (name === 'fullname' && value.trim()) newErrors.fullname = '';
        if (name === 'age' && value.trim()) newErrors.age = '';
        if (name === 'email' && value.trim()) newErrors.email = ''; // Clear email error

        setMemberdetails(updatedMembers);
        setErrors(newErrors);


    };


    const [pickUpPoints, setPickUpPoints] = useState([]);


    const [loadingpickuppoint, setLoadingpickuppoint] = useState(true);
    const [errorpickkuppoint, setErrorpickuppoint] = useState(null);

    useEffect(() => {
        const fetchPickUpPoints = async () => {
            try {
                const response = await axios.get(`https://yeahtrips.in/trippickuppoints/${slug}`);
                setPickUpPoints(response.data);
                setLoadingpickuppoint(false);
            } catch (err) {
                setErrorpickuppoint('Error fetching data');
                setLoadingpickuppoint(false);
            }
        };

        fetchPickUpPoints();
    }, [slug]);







    const tripWaitingListContainerRef = useRef(null);
    const isFormwaitinglistValid = () => {
        // Validation logic here, e.g., check if fields are empty
        return (
            seats > 0 &&
            phoneNumber.trim() !== '' &&
            email.trim() !== '' &&
            (isWhatsAppEnabled || whatsappNumber.trim() !== '')
        );
    };
    const handleSubmitwaitinglist = (e) => {
        if (!isFormValid()) {
            tripWaitingListContainerRef.current.scrollIntoView({ behavior: 'smooth' });
            return;
        }

        // Payload to send to backend
        const waitingListData = {
            seats,
            phoneNumber: `${phoneCode} ${phoneNumber}`, // Combine phoneCode and phoneNumber
            whatsappNumber: isWhatsAppEnabled ? `${phoneCode} ${phoneNumber}` : `${phoneCode} ${whatsappNumber}`, // Combine phoneCode and whatsappNumber
            trip_id: tripId,
            email
        };

        axios.post('https://yeahtrips.in/waitinglistdetails', waitingListData)
            .then(response => {
                setMessage('Successfully joined the waitlist!');
                setIsError(false);

                setTimeout(() => {
                    window.location.reload();
                }, 5000);
            })
            .catch(error => {
                console.error('Error joining waitlist:', error);
                setMessage('Error joining waitlist. Please try again.');
                setIsError(true);

                setTimeout(() => {
                    setMessage('');
                }, 5000);
            });
    };



    const handleCountries = (index, e) => {
        const selectedCountry = e.target.value;

        // Update the country field using handleChange
        handleChange(index, {
            target: {
                name: 'country',
                value: selectedCountry,
            },
        });

        // Fetch the states based on the selected country
        fetchStates(selectedCountry);
    };


    const handleStateChange = async (index, e) => {
        const { value } = e.target;
        const updatedMembers = [...memberdetails];
        updatedMembers[index] = { ...updatedMembers[index], state: value, city: '' };
        setMemberdetails(updatedMembers);

        // Fetch cities for the selected state
        await fetchCities(value);
    };

    const renderedIdsss = new Set()
    const renderedIds = new Set()
    const renderedIdss = new Set()
    const renderedIdssss = new Set()

    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermcity, setSearchTermcity] = useState('');

    const [showDropdown, setShowDropdown] = useState(false);
    const [filteredStates, setFilteredStates] = useState(states);
    const [filteredCities, setFilteredCities] = useState(cities);
    const [showCityDropdown, setShowCityDropdown] = useState(false);
    useEffect(() => {
        // Filter states based on the search term
        setFilteredStates(
            states.filter(state =>
                state.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, states]);

    const handleSearchChange = (e, index) => {
        const inputValue = e.target.value;
        setSearchTerm(inputValue);

        // Update the state directly in member details
        const updatedMemberDetails = [...memberdetails];
        updatedMemberDetails[index].state = inputValue;
        setMemberdetails(updatedMemberDetails);

        // Filter the dropdown options based on the input
        const matchingState = states.find((state) =>
            state.toLowerCase() === inputValue.toLowerCase()
        );

        // Auto-select if a typed state matches the dropdown list
        if (matchingState) {
            handleOptionClick(matchingState, index);
        }
    };
    const handleSearchChangecity = (e, index) => {
        const value = e.target.value;
        setSearchTermcity(value);

        // Dynamically filter cities based on the input
        const filtered = cities.filter((city) =>
            city.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredCities(filtered);

        // Update the city for the member while typing
        const updatedMembers = [...memberdetails];
        updatedMembers[index] = { ...updatedMembers[index], city: value };
        setMemberdetails(updatedMembers);

        // Auto-select if the typed city matches one in the list
        const matchingCity = cities.find(
            (city) => city.toLowerCase() === value.toLowerCase()
        );

        if (matchingCity) {
            handleCityOptionClick(matchingCity, index);
        }
    };


    // Handle dropdown option click
    const handleOptionClick = (state, index) => {
        const updatedMemberDetails = [...memberdetails];
        updatedMemberDetails[index].state = state;
        setMemberdetails(updatedMemberDetails);
        setSearchTerm(state);
        setShowDropdown(false);
        handleStateChange(index, { target: { value: state } });
    };
    useEffect(() => {
        // Filter cities based on the search term
        setFilteredCities(
            cities.filter(city =>
                city.toLowerCase().includes(searchTermcity.toLowerCase())
            )
        );
    }, [searchTermcity, cities]);

    const handleCityOptionClick = (city, index) => {
        const updatedMembers = [...memberdetails];
        updatedMembers[index].city = city;
        setMemberdetails(updatedMembers);

        setSearchTermcity(city);
        setShowCityDropdown(false);
    };

    useEffect(() => {
        // Check if there's any item with seats greater than 0
        const availableSeats = data.find(item => item.seats > 0);
        if (availableSeats) {
            setSeats(availableSeats.seats === 0 ? 0 : 1); // Set seats to 0 if no seats are available
        } else {
            setSeats(1); // Set seats to 0 if no data is available
        }
    }, [data]);
    const [email, setEmail] = useState('');
    const handleChangewaitinglist = (e) => {
        const { name, value } = e.target;
        const trimmedValue = value.replace(phoneCode, '').trim(); // Remove the phone code from the input value

        // Update the correct state based on the input name
        if (name === 'phonenumber') {
            setPhoneNumber(trimmedValue);
        } else if (name === 'whatsappnumber') {
            setWhatsappNumber(trimmedValue);
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const isFormValid = () => {
        return seats > 0 && seats < 5 && phoneNumber && (isWhatsAppEnabled || whatsappNumber) && email;
    };

    const isFormValidbooking = () => {
        return seats > 0 && seats < 5 && seats < availableSeats;
    };

    const notripdetailsSeatsAvailable = data.every(item => (item.totalseats - item.seats) === 0);

    const [errors, setErrors] = useState({});
    const tripInfoRef = useRef(null);



    const handleScrollToSection = () => {
        // Scroll to the container
        tripInfoRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });



    };




    const handleButtonClick = () => {

        const newErrors = {
            fullname: '',
            age: '',
            phonenumber: '',
            email: '',
            whatsappnumber: '',
            country: '',
            state: '',
            city: '',
        };

        memberdetails.forEach((member, index) => {
            if (!member?.fullname?.trim()) newErrors.fullname = 'Full name is required.';
            if (!member?.age?.trim()) newErrors.age = 'Age is required.';

            if (index === 0) {
                const phoneNumber = member?.phonenumber?.split(' ')[1]?.trim();
                const whatsappNumber = member?.whatsappnumber?.split(' ')[1]?.trim();

                if (!phoneNumber) newErrors.phonenumber = 'Phone number is required.';
                if (!member?.email?.trim()) newErrors.email = 'Email is required.';

                if (!isWhatsAppEnabled && !whatsappNumber) {
                    newErrors.whatsappnumber = 'WhatsApp number is required.';
                }

                if (!member?.country?.trim() || member.country === 'Select Country') {
                    newErrors.country = 'Country is required.';
                }

                if (!member?.state?.trim()) {
                    newErrors.state = 'State is required.';
                }

                if (!member?.city?.trim()) {
                    newErrors.city = 'City is required.';
                }
            }
        });

        setErrors(newErrors);

        handleScrollToSection();

        if (seats > availableSeats) {
            handleScrollToSection();
            return;
        }

        if (!Object.values(newErrors).some(error => error)) {
            handlePayment(); // Proceed with payment if no errors
        }

        setTimeout(() => {
            setIsProcessing(false); // Stop loading
        }, 5000);
    };


    const [policies, setPolicies] = useState([]);


    useEffect(() => {
        const fetchCancellationPolicies = async () => {
            try {
                const response = await axios.get(`https://yeahtrips.in/getthetripcancellationpolicies/${slug}`);
                setPolicies(response.data.data)

            } catch (error) {
                console.error("Error fetching cancellation policies:", error);
            }
        };

        fetchCancellationPolicies();
    }, [slug]);






    const handleClick = () => {
        setShowdiscountPopup(true);
    };

    useEffect(() => {
    }, [showdiscountPopup]);

    const handleApplyCoupon = (couponData) => {
        setCoupon(couponData); // Store the coupon data in state
    };




    let discountAmount = 0;
    let totalcost = seats * tripData;

    if (coupon) {
        if (coupon.discount_type === "percentage") {
            const discountPercentage = parseFloat(coupon.discount_value);
            discountAmount = (discountPercentage / 100) * totalcost;
        } else if (coupon.discount_type === "amount") {
            const maxAmount = parseFloat(coupon.discount_value);
            discountAmount = maxAmount;
        }
    }


    const couponcode = coupon ? coupon.coupon_code : '';
    const GST = totalcost * 0.05;


    const [isFloating, setIsFloating] = useState(false); // To track if the button should float
    const buttonRef = useRef(null);
    const waitingListContainerRef = useRef(null); // Renamed ref

    const handleScroll = () => {
        if (buttonRef.current && waitingListContainerRef.current) {
            const buttonPosition = buttonRef.current.getBoundingClientRect();
            const containerPosition = waitingListContainerRef.current.getBoundingClientRect();

            // Check if the button has reached the top of the viewport
            if (buttonPosition.top <= 0 && containerPosition.top < 0) {
                setIsFloating(true);  // Activate floating effect
            } else {
                setIsFloating(false); // Deactivate floating effect
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [isBookingFloating, setIsBookingFloating] = useState(false);
    const previousFloatingRef = useRef(isBookingFloating); // Ref to store previous floating state

    useEffect(() => {
        const handleScroll = () => {
            const bookingContainer = document.querySelector('.tripdiscountonclicktexttripdetail');
            const targetElement = document.querySelector('.triprefundinfocontainer');

            if (bookingContainer && targetElement) {
                const bookingRect = bookingContainer.getBoundingClientRect();
                const targetRect = targetElement.getBoundingClientRect();

                // Calculate if it should float
                const shouldFloat = bookingRect.top <= 0 && targetRect.top > 0;

                // Only update state if it has changed
                if (shouldFloat !== previousFloatingRef.current) {
                    previousFloatingRef.current = shouldFloat; // Update the ref with the new value
                    setIsBookingFloating(shouldFloat);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Initial check to set the correct state on mount
        handleScroll();

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const debounce = (func, delay) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };
    const [isWaitingListFloating, setIsWaitingListFloating] = useState(false);
    const previousWaitingListFloatingRef = useRef(isWaitingListFloating);

    useEffect(() => {
        const handleScroll = debounce(() => {
            const waitingListContainer = document.querySelector('.tripmember1email');
            const targetElement = document.querySelector('.googlemapimg');

            if (waitingListContainer && targetElement) {
                const waitingListRect = waitingListContainer.getBoundingClientRect();
                const targetRect = targetElement.getBoundingClientRect();

                // Calculate if it should float
                const shouldFloat = waitingListRect.top <= 0 && targetRect.top > 0;

                // Only update state if it has changed
                if (shouldFloat !== previousWaitingListFloatingRef.current) {
                    previousWaitingListFloatingRef.current = shouldFloat; // Update the ref with the new value
                    setIsWaitingListFloating(shouldFloat);
                }
            }
        }, 100); // Adjust the debounce delay as needed

        window.addEventListener('scroll', handleScroll);

        // Initial check to set the correct state on mount
        handleScroll();

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const tripdescription = data.map(itemss => itemss.trip_description.replace(/<\/?[^>]+(>|$)/g, ""));

    useEffect(() => {
        const adjustLineHeight = () => {
            const dots = document.querySelectorAll('.dot-container');
            const lines = document.querySelectorAll('.line');

            dots.forEach((dot, index) => {
                const line = lines[index];
                if (line && index < dots.length - 1) {
                    const nextDot = dots[index + 1];
                    const dotBottom = dot.getBoundingClientRect().bottom;
                    const nextDotTop = nextDot.getBoundingClientRect().top;

                    // Calculate the height between the current dot and the next dot
                    const lineHeight = nextDotTop - dotBottom;
                    line.style.height = `${lineHeight + 10}px`;
                }
            });
        };

        adjustLineHeight(); // Call the function to adjust line height on render

        window.addEventListener('resize', adjustLineHeight); // Adjust on window resize

        return () => {
            window.removeEventListener('resize', adjustLineHeight);
        };
    }, [data, expandedDays]);







    return (
        <div className="tripdetailscontainerpbonescreenheightudjust">
            <Helmet>
                <title>YEAH - {slug} </title>
                <meta name="description" content={`Join us for an unforgettable experience on the ${slug} trip. ${tripdescription}. Plan your next adventure today!`} />
                <meta name="keywords" content={`trip, tour, adventure, YEAH, ${slug}, travel, getaway, destination, explore, travel community, ${slug} trip`} />
                <meta property="og:title" content={`YEAH - ${slug} | Explore ${tripdescription}`} />
                <meta property="og:description" content={`Join the YEAH community for the ${slug} trip. ${tripdescription} Explore new destinations and make unforgettable memories.`} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`https://yeahtrips.in/${slug}`} />
            </Helmet>

            <div className="tripdetailnavbar">
                <Navbar />
            </div>
            <div className="tripdetails">



                <div className="tripdetailswidthcompletemovetoleftorrightelement">
                    <div className="pathofeachtrip">
                        <div>
                            <Link to="/">
                                <img src={home} alt="home" />
                            </Link>
                        </div>
                        <div>
                            <img src={righticonpath} alt="chevron-right" />
                        </div>
                        <div className="detailpathtext1">
                            <Link to="/futureescapes">
                                <p>Future Escapes</p>
                            </Link>
                        </div>
                        <div>
                            <img src={righticonpath} alt="chevron-right" />
                        </div>
                        <div className="detailpathtext2">
                            <p>{slug}</p>
                        </div>

                    </div>
                    <div className="imagecorousal"  >
                        <ImageCarousel trip_id={tripId} />
                    </div>
                    {showPopuplike && (
                        <Onboardliketripdetailpoperror
                            isVisible={showPopuplike}
                            onClose={handleClosePopupdetailtrip}
                        />
                    )}
                    <div className="tripinformation">

                        <div className="tripinformationleft">

                            <div className="tripheadingleftsidecontainertop">
                                <div className="triptopbar">
                                    <div className="launchstatus">
                                        <div>
                                            <img src={zapimage} alt="thunderimage" />
                                        </div>
                                        {data.length > 0 ? (
                                            data.map((item, index) => {
                                                if (!renderedIdssss.has(item.id)) {
                                                    renderedIdssss.add(item.id);
                                                    const availableSeats = item.totalseats - item.seats;
                                                    const seatStatus = availableSeats < (item.totalseats / 2) ? 'Filling Fast' : 'Newly Launched';

                                                    return (
                                                        <div className="launchedtext" key={`${item.trip_id}-${index}`}>
                                                            <p>{seatStatus}</p>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })

                                        ) : (
                                            <p>No data available</p>
                                        )}

                                    </div>
                                    <div className="tripseatsavaliable">
                                        <div>
                                            <img src={ticket} alt="ticket" />
                                        </div>
                                        <div>
                                            {data.length > 0 ? (
                                                data.map((item) => {
                                                    if (!renderedIdsss.has(item.id)) {
                                                        renderedIdsss.add(item.id);
                                                        return (
                                                            <div className="seatlefttext" key={item.trip_id}>
                                                                <p>Only {(item.totalseats) - (item.seats)} seats left</p>
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                })
                                            ) : (
                                                <p>No data available</p>
                                            )}
                                        </div>


                                    </div>
                                    <div className="tripsaveoption" onClick={(e) => handleLikeToggle(tripId, e)}   >
                                        <div className="tripsaveimage"  >
                                            <FaHeart size={24} color={likedItems[tripId] ? 'red' : 'gray'} className="faheartsaveicon" />
                                        </div>
                                        <div className="savetextttrip">
                                            <h1>{likedItems[tripId] ? 'Saved' : 'Save'}</h1>
                                        </div>
                                    </div>

                                </div>
                                <div className="element-style">
                                    {data.map((trip) => {
                                        const formattedendDate = formatDate(trip.end_date);
                                        const formattedstartDate = formatDate(trip.trip_start_date);

                                        if (renderedIds.has(trip.trip_id)) {
                                            return null;
                                        }
                                        renderedIds.add(trip.trip_id);
                                        return (
                                            <div key={trip.trip_id} className="tripsummary">



                                                <div className="tripsummarydetailstextcontainer">
                                                    <div className="tripdetailtripname">
                                                        <h1>{trip.trip_name}</h1>
                                                    </div>
                                                    <div className={`tripdetailstripintro ${isExpanded ? 'expanded' : ''}`}>
                                                        <div dangerouslySetInnerHTML={{ __html: trip.trip_description }} />
                                                    </div>

                                                    <div className="read-more-container" onClick={handleToggle}>
                                                        <p className="read-more-text">{isExpanded ? 'READ LESS' : 'READ MORE'}</p>
                                                        <span className="read-more-icon">
                                                            <img src={chevrondownreadmore} alt="Readmore" />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="triplinebetweenthedetailsandtest"></div>
                                                <div className="tripdetailssintrodates">
                                                    <div className="tripdetailsintrostartdate">
                                                        <div className="tripdetailspagetripicons" >
                                                            <img src={tripstartdateimg} alt="startdate" />
                                                        </div>
                                                        <div className="tripstartdateinfodetail">
                                                            <div className="tripstartdateinfodetailtext">
                                                                <h1>Start Date</h1>
                                                            </div>
                                                            <div className="tripdetailstextsintrroduction">
                                                                <p>{formattedstartDate}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="tripdetailsintroenddate">

                                                        <div className="tripdetailspagetripicons">
                                                            <img src={enddateicon} alt="startdate" />
                                                        </div>
                                                        <div className="tripstartdateinfodetail">
                                                            <div className="tripstartdateinfodetailtext">
                                                                <h1>End Date</h1>
                                                            </div>
                                                            <div className="tripdetailstextsintrroduction" >
                                                                <p>{formattedendDate}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tripdetailssintrodates">
                                                    <div className="tripdetailsintrostartdate">
                                                        <div className="tripdetailspagetripicons">
                                                            <img src={locationicon} alt="startdate" />
                                                        </div>
                                                        <div className="tripstartdateinfodetail">
                                                            <div className="tripstartdateinfodetailtext">
                                                                <h1>Start From</h1>
                                                            </div>
                                                            <div className="tripdetailstextsintrroduction">
                                                                <p>{trip.trip_start_point}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="tripdetailsintroenddate">
                                                        <div className="tripdetailspagetripicons">
                                                            <img src={tripendslocation} alt="startdate" />
                                                        </div>
                                                        <div className="tripstartdateinfodetail">
                                                            <div className="tripstartdateinfodetailtext">
                                                                <h1>Trips Ends</h1>
                                                            </div>
                                                            <div className="tripdetailstextsintrroduction">
                                                                <p>{trip.trip_end_point}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tripdetailssintrodates">
                                                    <div className="tripdetailsintrostartdate">
                                                        <div >
                                                            <img src={durationicon} alt="startdate" />
                                                        </div>
                                                        <div className="tripstartdateinfodetail">
                                                            <div className="tripstartdateinfodetailtext">
                                                                <h1>Trip Duration</h1>
                                                            </div>
                                                            <div className="tripdetailstextsintrroduction">
                                                                <p>{trip.trip_duration}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="tripdetailsintroenddate">
                                                        <div >
                                                            <img src={seatsicon} alt="startdate" />
                                                        </div>
                                                        <div className="tripstartdateinfodetail">
                                                            <div className="tripstartdateinfodetailtext">
                                                                <h1>Avaliable Seats</h1>
                                                            </div>
                                                            <div className="tripdetailstextsintrroduction">
                                                                <p>{(trip.totalseats) - (trip.seats)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tripdetailssintrodates">
                                                    <div className="tripdetailsintrostartdate">
                                                        <div >
                                                            <img src={travellertypeicon} alt="startdate" />
                                                        </div>
                                                        <div className="tripstartdateinfodetail">
                                                            <div className="tripstartdateinfodetailtext">
                                                                <h1>Traveller Type</h1>
                                                            </div>
                                                            <div className="tripdetailstextsintrroduction">
                                                                <p>{trip.traveller_type}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tripdetailsintroenddate">
                                                        <div >
                                                            <img src={seatsicon} alt="startdate" />
                                                        </div>
                                                        <div className="tripstartdateinfodetail">
                                                            <div className="tripstartdateinfodetailtext">
                                                                <h1>Fee</h1>
                                                            </div>
                                                            <div className="tripdetailstextsintrroduction">
                                                                <p>₹&nbsp;{trip.cost}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>


                            </div>





                            <div className="trpdetailsindicatorsessentialsticky">
                                <div className="tripdetailsinfoindicators">
                                    <div className={`tripdetailsindiacator1 ${selectedIndicator === 'itinerary' ? 'selected' : ''}`} onClick={() => handleIndicatorClick('itinerary')}>
                                        <p>Itinerary</p>
                                    </div>
                                    <div className={`tripdetailsindiacator1 ${selectedIndicator === 'trip-essentials' ? 'selected' : ''}`} onClick={() => handleIndicatorClick('trip-essentials')}>
                                        <p>Trip Essentials</p>
                                    </div>
                                    <div className={`tripdetailsindiacator1 ${selectedIndicator === 'other-details' ? 'selected' : ''}`} onClick={() => handleIndicatorClick('other-details')}>
                                        <p>Other Details</p>
                                    </div>
                                </div>
                            </div>

                            <div className="tripitenary" id="itinerary">
                                <div className="tripitenarydaywise">
                                    <div className="tripitenaryheading">
                                        <div className="itenaryhead">
                                            <h1>📃 Itinerary</h1>
                                        </div>
                                    </div>


                                    {data.length === 0 ? (
                                        <p>No itinerary data available.</p>
                                    ) : (
                                        data.map((day, index) => {
                                            const descriptionParts = day.DAY_DESCRIPTION
                                                ? (day.DAY_DESCRIPTION.match(/<li>(.*?)<\/li>/g) || [])
                                                    .map(item => item.replace(/<\/?li>/g, '').trim())
                                                : [];

                                            const formattedDate = formatDate(day.DATE);
                                            const isExpanded = expandedDays.includes(index); // Check if the day is expanded

                                            return (
                                                <div key={day.DAY} className={`tripitenaryday1 ${isExpanded ? 'expanded' : ''}`}>
                                                    <div className="dot-line-container">
                                                        <div className="dot-container">
                                                            <div className="dot"></div>
                                                        </div>
                                                        {index < data.length - 1 && (
                                                            <div className={`line ${isExpanded ? 'expanded' : 'collapsed'}`}></div>
                                                        )}
                                                    </div>
                                                    <div className="tripday1info">
                                                        <div className="tripitenaryday1details">
                                                            <div className="tripitenaryday1text">
                                                                <div className="tripitenarydetailhead">
                                                                    <h1>DAY &nbsp;&nbsp;{day.DAY}&nbsp;&nbsp; {formattedDate}</h1>
                                                                </div>
                                                                <div className="tripitenarydheaddetail">
                                                                    <h1>{day.DAY_TITLE}</h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tripdetailchevron" onClick={() => handleChevronClick(index)}>
                                                            <img src={chevrondown} alt="chevrondown" className={`chevron-icon ${isExpanded ? 'rotated' : ''}`} />
                                                        </div>
                                                    </div>

                                                    {isExpanded && (
                                                        <div>
                                                            <div className="tripitenaryinfodetailimg">
                                                                <img src={`https://admin.yeahtrips.in${day.DAY_IMG}`} alt="Trip" />
                                                            </div>
                                                            <div className="tripitenaryinfodetail">
                                                                {descriptionParts.length === 0 ? (
                                                                    <p>No description data available for this day.</p>
                                                                ) : (
                                                                    descriptionParts.map((part, idx) => (
                                                                        <p key={`${day.DAY}-${idx}`}>{part}.</p>
                                                                    ))
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })
                                    )}



                                </div>
                            </div>


                            <div className="tripinclusionsandexclusions" id="trip-essentials">
                                <div className="tripessentials">
                                    <div className="tripessentialsheading">
                                        <h1>🎯  Trip Essentials</h1>
                                    </div>

                                </div>
                                <div className="tripinclusionexclusionheightudjustforborder"> </div>
                                <div className="tripinclusionscontainer">
                                    <div className="tripinclusionheading">
                                        <div>
                                            <img src={inclusionicon} alt="inclusionicon" className="tripinclusionheadingimg" />
                                        </div>
                                        <div className="inclusiontext">
                                            <h1>Inclusions</h1>
                                        </div>
                                    </div>
                                    <div className="inclusiondetails">
                                        {(() => {
                                            const uniqueInclusions = new Set();

                                            data.forEach(dummy => {
                                                const inclusionMatches = dummy.inclusion.match(/<li>(.*?)<\/li>/g);

                                                if (inclusionMatches) {
                                                    inclusionMatches.forEach(inclusionText => {
                                                        const sanitizedInclusionText = inclusionText.replace(/<\/?li>/g, "").trim();
                                                        if (sanitizedInclusionText) {
                                                            uniqueInclusions.add(sanitizedInclusionText);
                                                        }
                                                    });
                                                }
                                            });


                                            const uniqueInclusionArray = Array.from(uniqueInclusions);

                                            return uniqueInclusionArray.map((inclusionText, index) => {

                                                if (inclusionText.trim()) {
                                                    return (
                                                        <div key={inclusionText} className="tripinclusions">
                                                            <div className="tripinclusionsimg">
                                                                <img src={inclusion} alt="inclusion" />
                                                            </div>
                                                            <div className="inclusiondetailtext">
                                                                <p>{inclusionText}</p>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            });
                                        })()}
                                    </div>

                                </div>



                                <div className="tripinclusionexclusionheightudjustforborder"></div>

                                <div className="tripinclusionscontainer">
                                    <div className="tripinclusionheading">
                                        <div>
                                            <img src={inclusionicon} alt="inclusionicon" />
                                        </div>
                                        <div className="inclusiontext">
                                            <h1>Exclusion</h1>
                                        </div>
                                    </div>
                                    <div className="inclusiondetails">
                                        {(() => {
                                            const uniqueexclusions = new Set();
                                            data.forEach(dummy => {
                                                // Use regex to match <li> elements and capture their inner text
                                                const exclusionMatches = dummy.exclusion.match(/<li>(.*?)<\/li>/g);

                                                if (exclusionMatches) {
                                                    exclusionMatches.forEach(exclusionText => {
                                                        // Extract the inner text and clean it
                                                        const sanitizedExclusionText = exclusionText.replace(/<\/?li>/g, "").trim();
                                                        // Add to the uniqueexclusions set if it's not empty
                                                        if (sanitizedExclusionText) {
                                                            uniqueexclusions.add(sanitizedExclusionText);
                                                        }
                                                    });
                                                }
                                            });


                                            // Convert the set to an array
                                            const uniqueexclusionArray = Array.from(uniqueexclusions);

                                            return uniqueexclusionArray.map((exclusionText, index) => {
                                                // Only render if the text is non-empty
                                                if (exclusionText.trim()) {
                                                    return (
                                                        <div key={exclusionText} className="tripinclusions">
                                                            <div className="tripinclusionsimg">
                                                                <img src={exclusion} alt="exclusion" />
                                                            </div>
                                                            <div className="inclusiondetailtext">
                                                                <p>{exclusionText}</p>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                return null; // Return null if the text is empty or invalid
                                            });
                                        })()}
                                    </div>

                                </div>


                                <div className="tripinclusionexclusionheightudjustforborder"> </div>
                                <div className="tripinclusionscontainer">
                                    <div className="tripinclusionheading">
                                        <div className="tripdetailpointtonoteimg">
                                            <img src={inclusionicon} alt="inclusionicon" />
                                        </div>
                                        <div className="inclusiontext">
                                            <h1>Points To Note</h1>
                                        </div>
                                    </div>
                                    <div className="inclusiondetails">
                                        {(() => {
                                            const uniquepointstonote = new Set();

                                            data.forEach(dummy => {
                                                const pointsToNoteMatches = dummy.points_to_note.match(/<li>(.*?)<\/li>/g);

                                                if (pointsToNoteMatches) {
                                                    pointsToNoteMatches.forEach(pointsToNoteText => {
                                                        const sanitizedPointsToNoteText = pointsToNoteText.replace(/<\/?li>/g, "").trim();
                                                        if (sanitizedPointsToNoteText) {
                                                            uniquepointstonote.add(sanitizedPointsToNoteText);
                                                        }
                                                    });
                                                }
                                            });



                                            const uniquepointstonoteArray = Array.from(uniquepointstonote);

                                            return uniquepointstonoteArray.map((pointstonoteText, index) => {
                                                // Only render if the text is non-empty
                                                if (pointstonoteText.trim()) {
                                                    return (
                                                        <div key={pointstonoteText} className="tripinclusions">
                                                            <div className="tripinclusionsimg">
                                                                <img src={pointtonoteicon} alt="points to note" />
                                                            </div>
                                                            <div className="inclusiondetailtext">
                                                                <p>{pointstonoteText}</p>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                return null; // Return null if the text is empty or invalid
                                            });
                                        })()}
                                    </div>


                                </div>
                                <div className="tripinclusionexclusionheightudjustforborder"> </div>

                            </div>

                            <div className="otherdetailscontainer" id="other-details">
                                <div className="otherdetailshead">
                                    <div className="otherdetailheading">
                                        <h1>✈️️  Other Details</h1>
                                    </div>

                                </div>

                                <div className="tripdetailmap">
                                    <div className="tripmaparrangetoleftallign">
                                        <div className="tripdetailmaphead">
                                            <div>
                                                <img src={flagicon} alt="flagicon" />
                                            </div>
                                            <div className="tripmapheading">
                                                <p>Trip Starting Point</p>
                                            </div>
                                        </div>

                                        <div className="tripmapimage">
                                            <img
                                                src={data && data.length > 0 && data[0].mapimage ? `https://admin.yeahtrips.in${data[0].mapimage}` : map}
                                                alt="map" className="googlemapimagesss"
                                            />
                                            <div className="tripmaptoreach">
                                                <div className="googlemapimg">
                                                    <img src={googlemap} alt="googlemap" />
                                                </div>
                                                <div className="tripreachtext">
                                                    {data && data.length > 0 ? (
                                                        <>
                                                            <a href={data[0].googlemap} target="_blank" rel="noopener noreferrer">
                                                                <h1>How To Reach Here</h1>
                                                            </a>
                                                        </>
                                                    ) : (
                                                        <h1>Loading...</h1>
                                                    )}
                                                </div>


                                            </div>
                                        </div>

                                        <div className="tripstartandpointforrsmallscreens starting-point">
                                            <h2>Starting Point</h2>
                                            {pickUpPoints.length > 0 ? ( // Check if there are any pickup points
                                                <div className="startingpointcontainer">
                                                    {pickUpPoints.slice(0, 1).map((point) => ( // Map the first pickup point
                                                        <div className="tripstartingpoint" key={point.trip_id}>
                                                            <div>
                                                                <img src={startingpointimage} alt="startingpoint" />
                                                            </div>
                                                            <div className="startingponittext">
                                                                <div className="startingpointheading">
                                                                    <h1>Location</h1>
                                                                </div>
                                                                <div className="trippointdetail">
                                                                    <h1>{point.pickuppoint || 'N/A'}</h1> {/* Using optional chaining */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <div className="reportingtime">
                                                        <div>
                                                            <img src={startingpointimage} alt="time" />
                                                        </div>
                                                        <div className="reportingtimetext">
                                                            <div className="startingtimeheading">
                                                                <h1>Reporting Time</h1>
                                                            </div>
                                                            <div className="reportingtimetime">
                                                                <h1>{pickUpPoints[0]?.time || 'N/A'}</h1> {/* Safely accessing time */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <h1>No Pickup Points Available</h1> // Message if there are no pickup points
                                            )}
                                        </div>

                                        <div className="trippathtoreachsmallscrenncontainerr">
                                            <div className="tripmaptoreachsmallscreen">
                                                <div className="googlemapimg">
                                                    <img src={googlemap} alt="googlemap" />
                                                </div>
                                                <div className="tripreachtext">
                                                    {data && data.length > 0 ? (
                                                        <>
                                                            <a href={data[0].googlemap} target="_blank" rel="noopener noreferrer">
                                                                <h1>How To Reach Here</h1>
                                                            </a>
                                                        </>
                                                    ) : (
                                                        <h1>Loading...</h1>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="additional-pickup-points">

                                            {pickUpPoints.length > 1 && (
                                                <h2>Additional Pickup Points</h2>
                                            )}
                                            {pickUpPoints.slice(1).map((point) => (
                                                <div className="tripstartandpointforrsmallscreens" key={point.trip_id}>
                                                    <div className="startingpointcontainer">
                                                        <div className="tripstartingpoint">
                                                            <div>
                                                                <img src={startingpointimage} alt="startingpoint" />
                                                            </div>
                                                            <div className="startingponittext">
                                                                <div className="startingpointheading">
                                                                    <h1>Location</h1>
                                                                </div>
                                                                <div className="trippointdetail">
                                                                    <h1>{point.pickuppoint}</h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="reportingtime">
                                                            <div>
                                                                <img src={startingpointimage} alt="time" />
                                                            </div>
                                                            <div className="reportingtimetext">
                                                                <div className="startingtimeheading">
                                                                    <h1>Reporting Time</h1>
                                                                </div>
                                                                <div className="reportingtimetime">
                                                                    <h1>{point.time}</h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>





                                    </div>

                                </div>

                                <div className="tripcordinatorscontainer">
                                    <div className="tripcordinatorheadingcontainer">
                                        <div className="tripcordinatorheading">
                                            <div>
                                                <img src={coordinatorsicon} alt="coordinator" />
                                            </div>
                                            <div className="triptravellersheading">
                                                <h1>Trip Travellers</h1>
                                            </div>
                                            <div className="tripcordinatormoveicon">
                                                <div className="cordinatormoveicontoleft" onClick={handlePrev}>
                                                    <img src={chevronleft} alt="chevron left" />
                                                </div>
                                                <div className="cordinatormove_icon" onClick={handleNext}>
                                                    <img src={chevronright} alt="chevron right" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tripcordinatorwrapper" ref={scrollContainerRef}>
                                        {coordinators.map((coordinator, index) => (
                                            <div className="tripcordinatordetails" key={index}>
                                                <div className="cordinatordetailcontainor">
                                                    <div className="tripcordinatordetails">
                                                        <img src={`https://admin.yeahtrips.in${coordinator.image}`} alt={coordinator.name} />
                                                    </div>
                                                    <div className="tripcordinatorname">
                                                        <h1>{coordinator.name}</h1>
                                                    </div>
                                                    <div className="tripcordinatorrole">
                                                        <h1>{coordinator.role}</h1>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>



                                <div className="payment">
                                    <div className="paymenttopbar">
                                        <div>
                                            <img src={payment} alt="payment" />
                                        </div>
                                        <div className="paymenttext">
                                            <p>Payment Options</p>
                                        </div>
                                    </div>
                                    <div className="paymentdescription">
                                        <p> We accept the following payment options. 100% advance payment accepted.</p>
                                    </div>

                                    <div className="paymentimages">
                                        <div className="visapay">
                                            <img src={visa} alt="visa" />
                                        </div>
                                        <div className="paypalpay">
                                            <img src={pay} alt="paypal" />
                                        </div>
                                        <div className="gpaypayment">
                                            <img src={gpay} alt="googlepay" />
                                        </div>
                                        <div className="mastercardpay">
                                            <img src={mastercard} alt="mastercard" />
                                        </div>
                                        <div className="amazonpay">
                                            <img src={amazonpay} alt="amazonpay" />
                                        </div>
                                    </div>
                                </div>

                                <div className="triprefundpolicy">
                                    <div className="refundpolicyheadingcontainer">
                                        <div className="refundpolicyicon">
                                            <img src={refundpolicy} alt="refundicon" />
                                        </div>
                                        <div className="triprefundtext">
                                            <h1>Refund Policy</h1>
                                        </div>
                                    </div>

                                    <div className="triprefunddetailtext">
                                        <p>A participant will be refunded his/her paid fees after deducting the following cancellation fees.</p>
                                    </div>


                                    {policies.length === 0 ? (
                                        <div className="no-cancellation-policies">
                                            <h2>No cancellation policies</h2>
                                        </div>
                                    ) : (
                                        <div className="triprefundinfocontainer">
                                            <div className="triprefundpolicyinfo">
                                                <div className="tripinfodetailsheadingscontainer">
                                                    <div className="tripdetailsheading">
                                                        <div className="tripfundheading">
                                                            <h1>Name</h1>
                                                        </div>
                                                    </div>


                                                    {policies.map((policy, index) => (
                                                        policy.dateRanges.map((item, dateIndex) => (
                                                            <div className={`triprefundinfo1container${dateIndex === policy.dateRanges.length - 1 ? ' last' : ''}`} key={`${item.date_range_id}-${index}`}>
                                                                <div className="triprefund1detail">
                                                                    <h1>
                                                                        {item.end_date === 0
                                                                            ? `Within ${item.start_date} days of camp start`
                                                                            : `Before ${item.end_date} days of camp start`}
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ))}

                                                </div>
                                            </div>

                                            <div className="triprefundcashcontainer">
                                                <div className="triprefundinfocashheadingcontainer">
                                                    <div className="triprefundcash">
                                                        <h1>Cancellation Fees</h1>
                                                    </div>
                                                </div>

                                                {/* Mapping cancellation fees */}
                                                {policies.map((policiesss, index) => (
                                                    policiesss.dateRanges.map((item, dateIndex) => (
                                                        <div className={`triprefund1cash${index === policies.length - 1 ? 'last' : ''}`} key={item.id}>
                                                            <div className="triprefund1amountcontainer">

                                                                <h1>
                                                                    {policiesss.feeType === 'percentage'
                                                                        ? `₹${((tripData * parseFloat(item.fee)) / 100).toFixed(2)} (${item.fee}%)`
                                                                        : `₹${item.fee}`}
                                                                </h1>
                                                            </div>
                                                        </div>
                                                    ))
                                                ))}
                                            </div>
                                        </div>
                                    )}



                                    < div className="triprefundbottomtextcontainer">

                                        <div className="triprefundbottomtext2container">
                                            <h1>Alternatively, a registered participant may transfer his/her registration to a friend without any cancellation fee.</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="tripinformationright" ref={waitingListContainerRef}>

                            {notripdetailsSeatsAvailable ? (
                                <div className="tripwaitinglistallroundcontainer" ref={tripWaitingListContainerRef}>
                                    <div className="tripwaitinglistcontainer" >
                                        <div className="tripwaitinglistdetailsofmemberscontainer">
                                            <div className="tripwaitinglistmemberheadingcontainer">
                                                <div className="tripwaittinglistheadingtext">
                                                    <h1>Join Waitlist</h1>
                                                </div>
                                                <div className="tripwaitinglistparagraphtexting">
                                                    <p>Please enter your details and we’ll reach out to you first once this trip is active again!</p>
                                                </div>
                                            </div>

                                            <div className="tripseatselectioncontainer">
                                                <div className="seatselecttioninputcontainer">
                                                    <div className="seatselectionheading">
                                                        <h1>Seats Required</h1>
                                                    </div>
                                                    <div className="seatselectinput">
                                                        <button className="minus" aria-label="Decrease quantity" onClick={() => handleSeatChange(-1)}></button>
                                                        <input
                                                            type="text"
                                                            value={seats}
                                                            aria-labelledby="quantity-label"
                                                            readOnly
                                                            className={seats > 5 ? 'input-red' : ''}
                                                        />
                                                        <button className="plus" aria-label="Increase quantity" onClick={() => handleSeatChanges(1)}></button>
                                                    </div>
                                                </div>
                                                {seats > 5 && <div className="error-messageseats">Only 5 seats available at the moment</div>}
                                            </div>

                                            <div className="tripphonenumbermember">
                                                <div className="tripmember1phonenumber">
                                                    <label htmlFor="Phonenumber">Phone number*</label><br />

                                                    <select
                                                        className={selectedCountry === 'IN' || selectedCountry === 'US' ? 'select-borderless' : ''}
                                                        onChange={(e) => handleCountryChangewaitinglist(e)}
                                                        value={selectedCountry}
                                                    >
                                                        {phoneCodes.map(code => (
                                                            <option key={code.iso3} value={code.iso3}>
                                                                {code.iso3} (+{code.phonecode})
                                                            </option>
                                                        ))}
                                                    </select>

                                                    <input
                                                        className="tripmember1phonenumberinput"
                                                        type="text"
                                                        id="phone"
                                                        name="phonenumber"
                                                        placeholder="0000 000 000" // Placeholder for user input
                                                        value={`${phoneCode} ${phoneNumber}`} // Always prepend the phone code to the phone number
                                                        onChange={(e) => handleChangewaitinglist(e)}
                                                        onClick={(e) => {
                                                            // Ensure cursor is placed after phone code
                                                            if (e.target.selectionStart < phoneCode.length) {
                                                                e.target.setSelectionRange(phoneCode.length, phoneCode.length);
                                                            }
                                                        }}
                                                        onKeyDown={(e) => {
                                                            // Prevent deletion of the phone code part
                                                            if (e.target.selectionStart <= phoneCode.length && (e.key === 'Backspace' || e.key === 'Delete')) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </div>




                                                <div className="tripmemberwhatsphonenumber">
                                                    <p>Above number is also on WhatsApp</p>
                                                    <input
                                                        type="checkbox"
                                                        id="whatsapp-toggle"
                                                        checked={isWhatsAppEnabled}
                                                        onChange={handleWhatsAppSliderToggleWaitingList}
                                                        aria-labelledby="whatsapp-toggle-label" // Link the label for accessibility
                                                    />
                                                    <label className="switch" htmlFor="whatsapp-toggle" id="whatsapp-toggle-label">
                                                        <span className="slider"></span>
                                                    </label>

                                                </div>

                                                {!isWhatsAppEnabled && (
                                                    <div className="tripmemberwhatsappcontainer">
                                                        <div className="tripmember1whatsphonenumber">
                                                            <label htmlFor="whatsappnumber">WhatsApp Phone number*</label><br />

                                                            <select
                                                                className={selectedCountry === 'IN' || selectedCountry === 'US' ? 'select-borderless' : ''}
                                                                onChange={handleCountryChangewaitinglist}  // Sync country code with WhatsApp number as well
                                                                value={selectedCountry}
                                                            >
                                                                {phoneCodes.map(code => (
                                                                    <option key={code.iso3} value={code.iso3}>
                                                                        {code.iso3} (+{code.phonecode})
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <input
                                                                className="tripmember1whatsphonenumberinput"
                                                                type="text"
                                                                id="whatsapp"  // Use a unique id for WhatsApp input
                                                                name="whatsappnumber"
                                                                placeholder="0000 000 000" // Dynamic placeholder without phone code
                                                                value={`${phoneCode} ${whatsappNumber}`}  // Display WhatsApp number prefixed with the phone code
                                                                onChange={handleChangewaitinglist}  // Handle WhatsApp number change
                                                                onClick={(e) => {
                                                                    // Ensure cursor is placed after phone code
                                                                    if (e.target.selectionStart < phoneCode.length) {
                                                                        e.target.setSelectionRange(phoneCode.length, phoneCode.length);
                                                                    }
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    // Prevent deletion of the phone code part
                                                                    if (e.target.selectionStart <= phoneCode.length && (e.key === 'Backspace' || e.key === 'Delete')) {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                            />

                                                        </div>
                                                    </div>



                                                )}

                                                <div className="tripmember1email">
                                                    <label htmlFor="email">Email id*</label>
                                                    <div className="tripmemberemailinput">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            placeholder="Enter E-Mail"
                                                            onChange={handleEmailChange}
                                                            value={email}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {message && (
                                            <div className={`feedback-message ${isError ? 'error' : 'success'}`}>
                                                {message}
                                            </div>
                                        )}

                                        <div className={`tripwaitinglistcaontainerindicator ${isWaitingListFloating ? 'floating' : ''}`}>
                                            <div className="tripwaitinglistbuttoncontainer">
                                                <button
                                                    className={`join-waitlist-button ${!isFormwaitinglistValid() ? 'disabled' : ''}`}
                                                    onClick={handleSubmitwaitinglist}
                                                >
                                                    Join Waitlist
                                                </button>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            ) : (
                                <div ref={tripInfoRef}>
                                    <div className="tripinformationrightheighttpudjustmentcontainer" >


                                        <div className="tripinforighttextheading" >
                                            <h1>Let’s Go</h1>
                                        </div>
                                        <div className="tripinforighttextdetail">
                                            <p>Please enter details of the members on this trip. One group can only consist of 5-6 members.</p>
                                        </div>

                                        <div className="tripseatselectioncontainer">
                                            <div className="tripseatselectionhead">
                                                <h1>Seats</h1>
                                            </div>
                                            <div className="seatselecttioninputcontainer">
                                                <div className="seatselectionheading">
                                                    <h1>No of Seats</h1>
                                                </div>
                                                <div className="seatselectinput">
                                                    <button className="minus" aria-label="Decrease quantity" onClick={() => handleSeatChange(-1)}></button>
                                                    <input
                                                        type="text"
                                                        value={seats}
                                                        aria-labelledby="quantity-label"
                                                        readOnly
                                                        className={seats > 5 ? 'input-red' : ''}
                                                    />
                                                    <button className="plus" aria-label="Increase quantity" onClick={() => handleSeatChanges(1)}></button>
                                                </div>
                                            </div>
                                            {seats > availableSeats ? (
                                                <div className="error-messageseats">Seats are unavailable</div>
                                            ) : (
                                                seats > 5 && <div className="error-messageseats">Only 5 seats available at the moment</div>
                                            )}
                                        </div>
                                        <div className="tripinforighttext">


                                            {seats > 0 && (
                                                <div className="membersscrollablecontainer">
                                                    {[...Array(Math.min(parseInt(seats), 5))].map((_, index) => {
                                                        const memberDetailHeight = (index === 0 && ages[index] && !showAdditionalInfo[index]) ? '135vh' : '56vh';
                                                        return (
                                                            <div
                                                                key={index}
                                                                className="memberdetailscontainer"
                                                                style={{ height: memberDetailHeight }}
                                                            >
                                                                <div className="tripmembersdata">

                                                                    <div className="tripmemberdetailsright">
                                                                        <div className="memberheadingcontainer">
                                                                            <h1>Member {index + 1}</h1>
                                                                        </div>
                                                                        {index === 0 && (
                                                                            <div className="tripmemberheading">
                                                                                <p>A YEAH account will be created based on the details provided here. You’ll also be added to our WhatsApp community.</p>
                                                                            </div>
                                                                        )}
                                                                    </div>


                                                                    <div className="tripmember1name">
                                                                        <label htmlFor="name">Full Name*</label>
                                                                        <div className="tripmember1input">
                                                                            <input

                                                                                type="text"
                                                                                placeholder="Enter your name"
                                                                                name="fullname"
                                                                                onChange={(e) => handleChange(index, e)}
                                                                                value={memberdetails.fullname}
                                                                            />
                                                                            {errors.fullname && <span className="error-message">{errors.fullname}</span>}
                                                                        </div>
                                                                    </div>


                                                                    <div className="memberagecontainer">
                                                                        <div className="memberageheading">
                                                                            <div className="memberageinputcontainer">
                                                                                <label htmlFor="age">Age*</label>
                                                                                <div className="tripmember1ageinput">
                                                                                    <input

                                                                                        type="text"
                                                                                        name="age"
                                                                                        placeholder="Enter age"
                                                                                        value={ages[index] || ''}
                                                                                        onChange={(e) => handleAgeChange(e, index)}
                                                                                    />
                                                                                    {errors.age && <span className="error-message">{errors.age}</span>}
                                                                                </div>
                                                                            </div>
                                                                            {showAdditionalInfo[index] && (
                                                                                <div>
                                                                                    <div className="tripmemberagedetail">
                                                                                        <p>Hey, if you are above the age of 30, we have something else in store for you! Check out LG4C!</p>
                                                                                    </div>
                                                                                    <div className="tripageknowmore">
                                                                                        <p>KNOW MORE</p>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>


                                                                    {index === 0 && ages[index] && !showAdditionalInfo[index] && (
                                                                        <div>
                                                                            <div className="tripphonenumbermember">
                                                                                <div className="tripmember1phonenumber">
                                                                                    <label htmlFor="Phonenumber">Phone number*</label><br />
                                                                                    <select
                                                                                        className={selectedCountry === 'IN' || selectedCountry === 'US' ? 'select-borderless' : ''}
                                                                                        onChange={(e) => handleCountryChange(e, index)}
                                                                                        value={selectedCountry}
                                                                                    >
                                                                                        {phoneCodes.map(code => (
                                                                                            <option key={code.iso3} value={code.iso3}>
                                                                                                {code.iso3} (+{code.phonecode})
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>
                                                                                    <input
                                                                                        className="tripmember1phonenumberinput"

                                                                                        type="text"
                                                                                        id={`phone${index}`}
                                                                                        name="phonenumber"
                                                                                        placeholder="+91 0000 000 000"
                                                                                        value={memberdetails[index].phonenumber}
                                                                                        onChange={(e) => handleChange(index, e)}
                                                                                    />
                                                                                    {memberdetails[0] && errors.phonenumber && <span className="error-message">{errors.phonenumber}</span>}
                                                                                </div>



                                                                                <div className="tripmemberwhatsphonenumber">
                                                                                    <p>Above number is also on WhatsApp</p>
                                                                                    <label className="switch" htmlFor={`whatsapp-toggle-${index}`}>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id={`whatsapp-toggle-${index}`} // Unique ID for each toggle switch
                                                                                            checked={isWhatsAppEnabled}
                                                                                            onChange={() => handleWhatsAppsliderToggle(index)}
                                                                                        />
                                                                                        <span className="slider"></span>
                                                                                    </label>

                                                                                </div>
                                                                            </div>

                                                                            {!isWhatsAppEnabled && (
                                                                                <div className="tripmemberwhatsappcontainer">
                                                                                    <div className="tripmember1whatsphonenumber">
                                                                                        <label htmlFor="whatsappnumber">WhatsApp Phone number*</label><br />
                                                                                        <select
                                                                                            className={selectedCountry === 'IN' || selectedCountry === 'US' ? 'select-borderless' : ''}
                                                                                            onChange={(e) => handleCountryChange(e, index)}
                                                                                            value={selectedCountry}
                                                                                        >
                                                                                            {phoneCodes.map((code, index) => (
                                                                                                <option key={`${code.iso3}-${index}`} value={code.iso3}>
                                                                                                    {code.iso3} (+{code.phonecode})
                                                                                                </option>
                                                                                            ))}

                                                                                        </select>
                                                                                        <input

                                                                                            className="tripmember1whatsphonenumberinput"
                                                                                            type="text"
                                                                                            id={`whatsappphone${index}`}
                                                                                            name="whatsappnumber"
                                                                                            value={memberdetails[index].whatsappnumber}
                                                                                            onChange={(e) => handleChange(index, e)}
                                                                                        />
                                                                                        {memberdetails[0] && errors.whatsappnumber && <span className="error-message">{errors.whatsappnumber}</span>}
                                                                                    </div>
                                                                                </div>
                                                                            )}


                                                                            <div className="tripmember1email">
                                                                                <label htmlFor="email">Email id*</label>
                                                                                <div className="tripmemberemailinput">
                                                                                    <input

                                                                                        type="email"
                                                                                        name="email"
                                                                                        id="email"
                                                                                        placeholder="Enter E-Mail"
                                                                                        onChange={(e) => handleChange(index, e)}
                                                                                        value={memberdetails.email}
                                                                                    />
                                                                                    {memberdetails[0] && errors.email && <span className="error-message">{errors.email}</span>}
                                                                                </div>
                                                                            </div>

                                                                            <div>

                                                                                <div key={index} className="tripmember1currentcity"  >
                                                                                    <label htmlFor="country">Country</label>
                                                                                    <div className="tripmmmbercityinput">
                                                                                        <input
                                                                                            type="text"
                                                                                            placeholder="Enter Country"
                                                                                            name="country"
                                                                                            onChange={(e) => handlecountriesChange(index, e)}
                                                                                            value={memberdetails[index].country || 'India'}
                                                                                        />
                                                                                        <div className="custom-select-wrapper">
                                                                                            <select
                                                                                                name="country"
                                                                                                value={memberdetails[index].country || 'India'}
                                                                                                onChange={(e) => handleCountries(index, e)}
                                                                                                className="country-dropdown"
                                                                                            >
                                                                                                <option value="" disabled>Select Country</option>
                                                                                                {countries.map((country, i) => (
                                                                                                    <option key={country} value={country}>{country}</option>
                                                                                                ))}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    {errors?.country && <span className="error-message">{errors.country}</span>}
                                                                                </div>



                                                                            </div>
                                                                            <div>
                                                                                <div key={index} className="tripmember1currentstate">
                                                                                    <label htmlFor="state">State</label>
                                                                                    <div className="tripmemberstateinput">
                                                                                        <div className="custom-select-wrapper">
                                                                                            <input
                                                                                                type="text"
                                                                                                placeholder="State"
                                                                                                value={memberdetails[index]?.state || searchTerm}
                                                                                                onChange={(e) => handleSearchChange(e, index)} // Updated handler to pass index
                                                                                                onClick={() => setShowDropdown(!showDropdown)}
                                                                                                className="state-search"
                                                                                            />
                                                                                            {showDropdown && (
                                                                                                <div className="dropdown-list">
                                                                                                    {filteredStates.length > 0 ? (
                                                                                                        filteredStates.map((state) => (
                                                                                                            <div
                                                                                                                key={state}
                                                                                                                className="dropdown-option"
                                                                                                                onClick={() => handleOptionClick(state, index)}
                                                                                                            >
                                                                                                                {state}
                                                                                                            </div>
                                                                                                        ))
                                                                                                    ) : (
                                                                                                        <div className="dropdown-option">No States Available</div>
                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    {errors.state && !memberdetails[index]?.state && (
                                                                                        <span className="error-message">{errors.state}</span>
                                                                                    )}
                                                                                </div>

                                                                            </div>
                                                                            <div className="citydetailscotainerwhenuseretered">
                                                                                <div key={index} className="tripmember1currentcity">
                                                                                    <label htmlFor="city">City</label>
                                                                                    <div className="tripmmmbercityinput">
                                                                                        <div className="custom-select-wrapper">
                                                                                            <input
                                                                                                type="text"
                                                                                                placeholder="City"
                                                                                                value={memberdetails[index]?.city || searchTermcity}
                                                                                                onChange={(e) => handleSearchChangecity(e, index)}
                                                                                                onClick={() => setShowCityDropdown(!showCityDropdown)}
                                                                                                className="city-search"
                                                                                            />
                                                                                            {showCityDropdown && (
                                                                                                <div className="dropdown-list">
                                                                                                    {filteredCities.length > 0 ? (
                                                                                                        filteredCities.map((city) => (
                                                                                                            <div
                                                                                                                key={city}
                                                                                                                className="dropdown-option"
                                                                                                                onClick={() => handleCityOptionClick(city, index)}
                                                                                                            >
                                                                                                                {city}
                                                                                                            </div>
                                                                                                        ))
                                                                                                    ) : (
                                                                                                        <div className="dropdown-option">No Cities Available</div>
                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    {errors.city && !memberdetails[index]?.city && (
                                                                                        <span className="error-message">{errors.city}</span>
                                                                                    )}
                                                                                </div>

                                                                            </div>



                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </ div>
                                            )}
                                            <div>
                                                <div className="sampletripdetailsheightudjust"></div>
                                                <div className="bookingprizedetails">

                                                    <div className="patymentdetailsmargincontainer">
                                                        <div className="trippaymentdetailsheadingcontainer">
                                                            <div className="paymentdetailsheadingtripdetails">
                                                                <h1>Payment Details</h1>
                                                            </div>
                                                            <div className="paymentdetailsparabottomtripdetails">
                                                                <p>Breakdown of the total trip cost based on details entered.</p>
                                                            </div>
                                                        </div>
                                                        <div className="bookingprizeofperson">
                                                            <div className="bookingcostforperson">
                                                                <div className="bookingcostforperson1">
                                                                    <h1>Package Cost (per person)</h1>
                                                                </div>
                                                                <div className="bookingcostprize">
                                                                    <p>₹ {tripData}</p>
                                                                </div>
                                                            </div>
                                                            <div className="tripbookingperson">
                                                                <div className="bookingcostforperson1">
                                                                    <h1>Total Members</h1>
                                                                </div>
                                                                <div className="bookingcostprize">
                                                                    <p>{seats}</p>
                                                                </div>
                                                            </div>
                                                            <div className="triptotalcost">
                                                                <div className="bookingcostforperson1">
                                                                    <h1>Total Cost ({tripData}*{seats})</h1>
                                                                </div>
                                                                <div className="bookingcostprize">
                                                                    <p>₹ {totalcost}</p>
                                                                </div>
                                                            </div>
                                                            <div className="triptotalgst">
                                                                <div className="bookingcostforperson1">
                                                                    <h1>GST@5%</h1>
                                                                </div>
                                                                <div className="bookingcostprize">
                                                                    <p>₹ {(totalcost) * (0.05)}</p>
                                                                </div>
                                                            </div>
                                                            <div className="triptotaldiscount">
                                                                <div className="bookingcostforperson1">
                                                                    <h1>Discount ({couponcode})</h1>
                                                                </div>
                                                                <div className="bookingcostprize">
                                                                    <p>-₹{discountAmount} </p>
                                                                </div>
                                                            </div>
                                                            <div className="tripfinaltotalcost">
                                                                <div className="bookingcostforperson1">
                                                                    <h1>Final Total</h1>
                                                                </div>
                                                                <div className="bookingcostprize">
                                                                    <p>₹ {totalcost - discountAmount}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div
                                                    className="tripdiscountonclicktexttripdetail"
                                                    onClick={handleClick}

                                                >
                                                    <div className="tripdiscounttextcontainerparagraph" >
                                                        <p>I HAVE A COUPON CODE</p>
                                                    </div>
                                                    <div>
                                                        <img src={arrowright} alt="arrow" />
                                                    </div>
                                                </div>






                                                {seats >= 0 && (
                                                    <div>
                                                        <div className={`tripbookingbuttoncontainer ${isBookingFloating ? 'floating' : ''}`}>
                                                            <div className="tripbookingbuttonamount">
                                                                <div className="tripbookingbuttontotal">
                                                                    <h1>Total Amount</h1>
                                                                </div>
                                                                <div className="tripbookingbuttonamounttototal">
                                                                    <p>₹{totalcost - discountAmount}</p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                {totalcost - discountAmount < 1 ? (
                                                                    <p className="error-message">Minimum amount of ₹1 is required</p>
                                                                ) : (
                                                                    <>
                                                                        <CSSTransition in={isProcessing} timeout={300} classNames="spinner" unmountOnExit>
                                                                            <div className="spinner"></div>
                                                                        </CSSTransition>
                                                                        <CSSTransition in={!isProcessing} timeout={300} classNames="fade" unmountOnExit>
                                                                            <div
                                                                                className="tripbookingbuttonelementcontainer"
                                                                                onClick={handleButtonClick}
                                                                                style={{ transform: 'none' }}
                                                                            >
                                                                                <p>Proceed To Book</p>
                                                                            </div>
                                                                        </CSSTransition>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>

                                        </div>

                                    </div>
                                </div>


                            )}






                        </div>


                    </div>


                    {showdiscountPopup && <Discount onClose={handleClosediscountPopup} totalcost={totalcost} isVisible={showdiscountPopup} onApplyCoupon={handleApplyCoupon}
                    />}

                    {showPopup && <Onboardbooking onClose={handleClosePopup} trip_id={tripId} />}

                    <div className="similartripsdetails">
                        <Similartrips />
                    </div>
                    <div className="importedcontainer">
                        <Setusapart />
                        <Moments />
                        <Getintouch />
                        <Footer />
                    </div>
                </div>


            </div>
        </div >

    );

}



export default Tripdetails;