import React, { useState, useEffect } from "react";
import axios from "axios";
import icon1 from './images/icon1.svg';
import icon2 from './images/icon2.png';
import icon3 from './images/icon3.png';
import zap from './images/zap.svg';
import logo from './images/logo.svg';
import './Courasaul.css';

function Carousel() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [slides, setSlides] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSlides = async () => {
            try {
                const response = await axios.get('https://yeahtrips.in/corosaltesxthomepage');
                if (Array.isArray(response.data)) {
                    setSlides(response.data);
                } else {
                    console.error("Fetched data is not an array:", response.data);
                    setSlides([]);
                }
            } catch (error) {
                console.error('Error fetching slides:', error);
                setError('Failed to load slides');
            } finally {
                setLoading(false);
            }
        };

        fetchSlides();
    }, []);

   

    useEffect(() => {
        if (slides.length > 0) {
            const interval = setInterval(() => {
                setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
            }, 3000);

            return () => clearInterval(interval);
        }
    }, [slides]);

    const handleIndicatorClick = (index) => {
        setCurrentSlide(index);
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="carousel">
            <div className="carousel-container">
                <div className="carousel-content">
                    {slides.length > 0 && (
                        <>
                            <h1 className="carousel-title"
                                dangerouslySetInnerHTML={{ __html: slides[currentSlide].title }}
                            ></h1>
                            <div className="carousel-author-container">
                                <p className="carousel-author" dangerouslySetInnerHTML={{__html:slides[currentSlide].author}}></p>
                            </div>
                        </>
                    )}
                </div>
                <div className="carousel-indicators">
                    {slides.map((slide, index) => (
                        <div
                            key={index}
                            className={`carousel-indicator ${index === currentSlide ? 'carousel-indicator-active' : ''}`}
                            onClick={() => handleIndicatorClick(index)}
                        ></div>
                    ))}
                </div>
            </div>

            <div className="corosaul-container">
                <div className="heightudjustforlandingpageresponsivenesscouroasal">
                    <div className="corosaul-box">
                        <img src={icon2} alt="icon2" className="corosaul-icon" />
                        <div className="corosaul-text-container">
                            <h1 className="corosaul-title">130+</h1>
                            <p className="corosaul-description">Unique Locations</p>
                        </div>
                    </div>
                </div>

                <div className="heightudjustforlandingpageresponsivenesscouroasal">
                    <div className="corosaul-box">
                        <img src={icon1} alt="icon1" className="corosaul-icon" />
                        <div className="corosaul-text-container">
                            <h1 className="corosaul-title">5k+</h1>
                            <p className="corosaul-description">Happy Travelers</p>
                        </div>
                    </div>
                </div>

                <div className="heightudjustforlandingpageresponsivenesscouroasal">
                    <div className="corosaul-box">
                        <img src={icon3} alt="icon3" className="corosaul-icon" />
                        <div className="corosaul-text-container">
                            <h1 className="corosaul-title">350+</h1>
                            <p className="corosaul-description">Personalized Trips</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="elementcorosaul">
                <img src={zap} alt="Thunder icon" className="thundericon" />
                <p>An initiative by experienced folks of </p>
                <img src={logo} alt="Logo camp" className="logocamp" />
            </div>
        </div>
    );
}

export default Carousel;
