import React, { useState, useEffect,useRef } from "react";
import { Link } from "react-router-dom";
import Trip from './images/content.svg';
import Frame from './images/Frame16.svg';
import plane from './images/Plane.svg';
import responsiveimage from './images/responsiveimage.svg';
import './Content.css';

function Content({ futureTripsRef }) {

    useEffect(() => {
        const preloadImage = (src) => {
            const img = new Image();
            img.src = src;
        };

        preloadImage(Trip);
        preloadImage(Frame);
        preloadImage(plane);
        preloadImage(responsiveimage);
    }, []);


    const scrollToFutureTrips = () => {
        if (futureTripsRef.current) {
            futureTripsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const paragraphText =
        "Travel like a local, with a crew as pumped about adventure as you are. Our expert guides will show you the hidden gems, while you connect with a rad group of young explorers (all under 30) for an unforgettable experience.";

    return (
        <div className="flex  max-[600px]:flex-col lg:flex-row p-4 lg:p-[2.78vw] min-w-screen h-auto lg:h-[105vh] mt-4 lg:mt-[1.43vh] mb-4 lg:mb-[2vh] gap-4 lg:gap-[1.39vw] bg-gradient-to-br from-[#ffede8] via-[#FFFFFF] to-[#ffede8]">
            <div className="flex flex-col justify-center items-start gap-4 lg:gap-[2.5vh] w-full lg:w-[33.3333%] lg:ml-[3vw]">
                <div>
                <h1 className="font-bold text-[2.5rem] lg:text-[3.19vw] leading-[3rem] lg:leading-[3.83vw] tracking-tight text-left max-[540px]:text-center" style={{ fontFamily: 'Plus Jakarta Sans' }}>
                    <span className="text-[#F97316]">YEAH-</span>
                    Your <br />
                    Twenties' Ticket <br /> to Adventure 🏝️
                </h1>
                <p className="font-sans text-[1.5vw] font-normal leading-[28px] tracking-[0.002em] text-[#19182580] h-[27vh] text-left max-[540px]:text-center max-[540px]:text-[4vw]" style={{ fontFamily: 'Plus Jakarta Sans' }}>
                    {paragraphText}
                </p>
                </div>
                
                <div className="flex flex-col gap-2 lg:gap-[0.56vw] w-full">
                    
                        <button  onClick={scrollToFutureTrips}  className="bg-[#F97316] text-white font-bold text-base lg:text-[1.11vw] leading-[1.5rem] lg:leading-[1.67vw] rounded-full lg:rounded-[2.22vw] flex items-center justify-center px-4 lg:px-[0.6944vw] py-2 lg:py-[0.6944vw] w-full lg:w-[27vw] h-[7vh] ">
                            Discover Future Escapes
                        </button>
                   
                    <Link to='/community'>
                        <button className="bg-white border-2 border-[#F97316] font-bold text-base lg:text-[1.11vw] leading-[1.5rem] lg:leading-[1.67vw] rounded-full lg:rounded-[2.22vw] flex items-center justify-center px-4 lg:px-[0.6944vw] py-2 lg:py-[0.6944vw] w-full lg:w-[27vw] h-[7vh] ">
                            Join Our Community
                        </button>
                    </Link>
                </div>
            </div>

            <div className="relative w-full lg:w-[54.7667vw] h-auto lg:h-[94.2vh] p-0 lg:p-[1.39vw] mt-4 lg:mt-[1.39vh] lg:ml-[3.39vw]">
                <img src={Trip} alt="Trip" className="trip-image" />
                <img src={Frame} alt="Frame" className="frame-image" />
                <img src={plane} alt="Plane" className="plane-image" />
                <img src={responsiveimage} alt="Responsive Image" className="responsive-image" />
            </div>
        </div>
    );
}

export default Content;
