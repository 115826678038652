import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Signup.css';
import cross from './Images/x-circle.svg';
import Logo from './Images/Logomark.svg';
import { Link, useNavigate } from "react-router-dom";
import Landingpage from '../../homepage/Landinhpage/Landingpage';
import { Helmet } from 'react-helmet';
function Signup() {
  const [phoneNumber, setPhoneNumber] = useState(''); // Phone number entered by user
  const [phoneCodes, setPhoneCodes] = useState([]);
  const [phoneCode, setPhoneCode] = useState('+91'); // Default phone code
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('IN'); // Default selected country code
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [errors, setErrors] = useState({ name: '', phone: '', email: '' });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Button state
  const [dropdownVisible, setDropdownVisible] = useState(false); // Track if dropdown is visible
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  // Effect to toggle no-scroll class on body
  useEffect(() => {
    document.body.classList.toggle('no-scroll', isSidebarVisible);
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isSidebarVisible]);

  // Fetch phone codes and set default phone code
  const fetchPhoneCodes = () => {
    axios.get('https://yeahtrips.in/phonecode')
      .then(response => {
        const data = response.data;
        setPhoneCodes(data);
        const defaultCode = data.find(c => c.iso3 === selectedCountry)?.phonecode || '91';
        setPhoneCode(`+${defaultCode}`);
        setDropdownVisible(true); // Show dropdown after fetching phone codes
      })
      .catch(error => console.error('Error fetching phone codes:', error));
  };

  const handleCountryChange = (e) => {
    const country = e.target.value;
    setSelectedCountry(country);
    const code = phoneCodes.find(c => c.iso3 === country)?.phonecode || '91';
    setPhoneCode(`+${code}`);
    setPhoneNumber('');
  };

  const handleSelectClick = () => {
    if (!dropdownVisible) {
      fetchPhoneCodes();
    }
  };

  const handleCloseClick = () => {
    setSidebarVisible(false);
    navigate('/');
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value.trim();
    
    // Ensure only digits after the + sign are editable
    const digitsOnly = input.slice(phoneCode.length).replace(/\D/g, '');
    setPhoneNumber(digitsOnly);
    
    const newErrors = { ...errors };
    if (digitsOnly.trim() === '') {
      newErrors.phone = 'Phone number is required';
    } else if (!/^\d+$/.test(digitsOnly.trim())) {
      newErrors.phone = 'Phone number must contain only digits';
    } else {
      newErrors.phone = '';
    }
    setErrors(newErrors);
    checkFormValidity(); // Check form validity whenever phone number changes
  };

  const validateName = (value) => {
    const newErrors = { ...errors };

    if (!value.trim()) {
      newErrors.name = 'Name is required';
    } else if (!/^[A-Za-z\s]+$/.test(value)) {
      newErrors.name = 'Name must contain only alphabets and spaces';
    } else {
      newErrors.name = '';
    }

    setErrors(newErrors);
    checkFormValidity(); // Check form validity whenever name changes
  };

  const validateEmail = (value) => {
    const newErrors = { ...errors };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!value.trim()) {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(value)) {
      newErrors.email = 'Invalid email address';
    } else {
      newErrors.email = '';
    }

    setErrors(newErrors);
    checkFormValidity(); // Check form validity whenever email changes
  };

  const checkFormValidity = () => {
    const isFormValid = !errors.name && !errors.phone && !errors.email && name && phoneNumber && email;
    setIsButtonDisabled(!isFormValid);
  };
  const handleInputBlur = (e) => {
    const { name, value } = e.target;
    if (name === 'name') {
      validateName(value);
    }
    if (name === 'phone') {
      handlePhoneChange(e);
    }
    if (name === 'email') {
      validateEmail(value);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'name') {
      setName(value);
      validateName(value);
    }
    if (name === 'phone') {
      handlePhoneChange(e);
    }
    if (name === 'email') {
      setEmail(value);
      validateEmail(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    checkFormValidity(); // Ensure validity is checked before submission
    if (isButtonDisabled) return; // Prevent submission if button is disabled

    try {
      const response = await axios.post('https://yeahtrips.in/signup', {
        phonenumber: phoneCode + phoneNumber, // Combine code and number for submission
        name,
        email,
      });

      const { success, message } = response.data;

      if (success) {
        setMessage(message || 'Sign up successful!');
        setEmail("");
        setPhoneNumber("");
        setName("");
        navigate('/login');
      } else {
        setMessage(message || 'Unexpected response from the server.');      }
    } catch (error) {
      if (error.response && error.response.data) {
        setMessage(` ${error.response.data.message || 'An unexpected error occurred.'}`);
      } else {
        setMessage('An unexpected error occurred. Please try again.');
      }
    }
  };
  useEffect(() => {
    checkFormValidity(); 
  }, []); 
  
  return (
    <div>
      <Helmet>
         <title>Sign Up | YEAH - Join Our Adventure Community</title>
         <meta name='description' content='Create your YEAH account to access exclusive travel deals, manage bookings, and explore the best adventure trips for young adults.' />
         <meta name='keywords' content='YEAH sign up, create account, adventure trips, join travel community, exclusive travel deals, trip bookings, YEAH portal, member registration' />
         <meta property='og:title' content='Sign Up | YEAH - Your Ticket to Adventure' />
         <meta property='og:description' content='Join YEAH to discover adventure trips, get access to member-only offers, and start planning your next travel experience.' />
         <meta property='og:type' content='website' />
         <meta property='og:url' content='https://yeahtrips.in/signup' />
      </Helmet>

      <div className='landingpagebottomofsignup'>
        <Landingpage />
      </div>

      <div className='overlay' style={{ display: isSidebarVisible ? 'block' : 'none' }}></div>
      <div className={isSidebarVisible ? 'sidebar' : 'sidebar hidden'}>
        <div className='topbar'>
          <div className='topbar_contents'>
            <h2>Sign Up To Yeah</h2>
            <img src={cross} alt="Close" style={{ cursor: 'pointer' }} className='close' onClick={handleCloseClick} />
          </div>
        </div>
        <div className='body'>
          <img src={Logo} alt="Logo" />
          <div className='group1'>
            <h2>Create an Account</h2>
            <div className='tripsignupcontainertextss'>
              Travel like a local, with a crew as pumped about<br /> adventure as you are.
            </div>
          </div>
          <div className='group2'>
            <div className='inp-container'>
              <label htmlFor='Fullname'>Full Name</label><br />
              <input
                className='inputsignup'
                type='text'
                id='Fullname'
                name='name'
                placeholder='Enter your name'
                value={name}
                onBlur={handleInputBlur}
                onChange={handleInputChange}
              />
              {errors.name && <div className='error'>{errors.name}</div>}
            </div>
            <div className='inp-container'>
              <label htmlFor='Phonenumber'>Phone number</label><br />
              <div className="phone-input">
                <select
                  className={selectedCountry === 'IN' || selectedCountry === 'US' ? 'select-borderlesssignup' : ''}
                  onChange={handleCountryChange}
                  value={selectedCountry}
                  onClick={handleSelectClick}
                >
                  {phoneCodes.length > 0 ? (
                    phoneCodes.map((code) => (
                      <option key={code.iso3} value={code.iso3}>
                        {code.iso3} (+{code.phonecode})
                      </option>
                    ))
                  ) : (
                    <option value="IN">IN (+91)</option>
                  )}
                </select>
                <input
                  className='inputsignup'
                  type="tel"
                  id="phone"
                  name="phone"
                  value={phoneCode + phoneNumber} 
                  onBlur={handleInputBlur}
                  onChange={handlePhoneChange}
                />
              </div>
              {errors.phone && <div className='error'>{errors.phone}</div>}
            </div>
            <div className='inp-container'>
              <label htmlFor='Email'>Email</label><br />
              <input
                className='inputsignup'
                type='email'
                id='Email'
                name='email'
                placeholder='Enter your email'
                value={email}
                onBlur={handleInputBlur}
                onChange={handleInputChange}
              />
              {errors.email && <div className='error'>{errors.email}</div>}
            </div>
            {message && <div className={`message ${message.startsWith('Error') ? 'error' : 'success'}`}>{message}</div>}

            <div className='group3'>
              <button
                className={`signup ${isButtonDisabled ? 'disabled' : ''}`}
                onClick={handleSubmit}
                disabled={isButtonDisabled}
              >
                Sign Up
              </button>
              <div className='final-contents'>
                <span style={{ color: 'rgba(71, 84, 103, 1)' }}>Already have an account?</span>
                <Link to='/login' className='gradientcolorlogin'>Login</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
