import React, { useState, useEffect } from 'react';
import leftArrow from './images/arrowleft.svg';
import rightArrow from './images/arrowright.svg';
import closeIcon from './images/xclose.svg';
import heartIcon from './images/heart.svg';
import axios from 'axios';
import { FaHeart } from 'react-icons/fa';
import './courosaltrip.css';
import Onboardliketripdetailpoperror from '../liketripsdetails/Liketripdetailssave';

const ImageCarousel = ({ trip_id }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 376);
    const [likedItems, setLikedItems] = useState({});
    const [showPopuplike, setShowPopuplike] = useState(false);
    const baseUrl = 'https://admin.yeahtrips.in';

    // Fetch images from the API
    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get(`https://yeahtrips.in/corosauselstext?trip_id=${trip_id}`);
                const fetchedImages = response.data.images;
    
                // Combine both additionalImage and filePath into one array
                const formattedImages = fetchedImages.flatMap(imageObj => {
                    const imagesArray = [];
                    if (imageObj.filePath) {
                        imagesArray.push(`${baseUrl}${imageObj.filePath.replace(/\\/g, '/')}`);
                    }if (imageObj.additionalImage) {
                        imagesArray.push(`${baseUrl}${imageObj.additionalImage.replace(/\\/g, '/')}`);
                    }
                    return imagesArray;
                });
    
                setImages(formattedImages);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };
    
        if (trip_id) {
            fetchImages();
        }
    }, [trip_id]);
    
    
    useEffect(() => {
        const preloadImage = (url) => {
            const link = document.createElement('link');
            link.rel = 'preload';
            link.as = 'image';
            link.href = url;
            document.head.appendChild(link);
        };

        if (images[0]) {
            preloadImage(images[0]);
        }
    }, [images]);

    // Ensure there are at least 3 images by repeating the existing images if necessary
    const extendedImages = images.length >= 3 ? images : [...images, ...images.slice(0, 3 - images.length)];

    const openModal = (index) => {
        setCurrentImageIndex(index);
        setIsModalOpen(true);
        document.body.classList.add('no-scroll'); // Prevent body scrolling when modal opens
    };

    const closeModal = () => {
        setIsModalOpen(false);
        document.body.classList.remove('no-scroll'); // Re-enable body scrolling when modal closes
    };

    const showNextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % extendedImages.length);
    };

    const showPreviousImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + extendedImages.length) % extendedImages.length);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowRight') {
            showNextImage();
        } else if (e.key === 'ArrowLeft') {
            showPreviousImage();
        } else if (e.key === 'Escape') {
            closeModal();
        }
    };

    // Handle screen resize to check if it's a small screen
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 500);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Check initial size

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // Load initial liked items from localStorage
        const savedTrips = JSON.parse(localStorage.getItem('likedTripIds')) || [];
        const likedItemsState = savedTrips.reduce((acc, id) => {
            acc[id] = true;
            return acc;
        }, {});
        setLikedItems(likedItemsState);
    }, []);

    const handleLikeToggle = async (trip_id, e) => {
        if (e) {
            e.preventDefault();
        }

        const token = localStorage.getItem('accessToken');
        if (!token) {
            setShowPopuplike(true);
            return;
        }
        if (token) {
            setLikedItems(prev => {
                const newLikedItems = {
                    ...prev,
                    [trip_id]: !prev[trip_id],
                };

                const updatedLikedTripIds = Object.keys(newLikedItems).filter(id => newLikedItems[id]);
                localStorage.setItem('likedTripIds', JSON.stringify(updatedLikedTripIds));

                return newLikedItems;
            });

            try {
                const { userId } = JSON.parse(atob(token.split('.')[1]));

                const apiPayload = {
                    trip_id,
                    userId,
                };


                if (likedItems[trip_id]) {
                    await axios.post('https://yeahtrips.in/api/removeTrip', apiPayload);
                } else {
                    await axios.post('https://yeahtrips.in/api/saveTrip', apiPayload);
                }
            } catch (error) {
                console.error('Error saving or removing liked trip:', error);
            }
        }

    };
    const handleClosePopupdetailtrip = () => {
        setShowPopuplike(false);
        window.location.reload();

    };

    return (
        <div>
            <div className="tripcourasul">
                {extendedImages.length > 0 ? (
                    <>

                        {isSmallScreen && (
                            <div className='arrowleftresponsivescreen' onClick={showPreviousImage}>
                                <img src={leftArrow} className="arrow-button left-arrow" alt="Previous" />
                            </div>
                        )}

                        {/* Carousel Images */}
                        <div>
                            {extendedImages[currentImageIndex] && (
                                <img
                                    src={extendedImages[currentImageIndex]}  // Current image is displayed based on currentImageIndex
                                    alt={`image${currentImageIndex + 1}`}
                                    className='tripdetailscorausalimage0'
                                    onClick={() => openModal(currentImageIndex)}
                                />
                            )}
                        </div>
                        <div className="tripcourasulright">

                            <div>
                                {extendedImages[(currentImageIndex + 1) % extendedImages.length] && (
                                    <img
                                        src={extendedImages[(currentImageIndex + 1) % extendedImages.length]}  // Next image
                                        alt={`image${(currentImageIndex + 2) % extendedImages.length}`}
                                        className="tripdetailscourasulrightimg1"
                                        onClick={() => openModal((currentImageIndex + 1) % extendedImages.length)}
                                    />
                                )}
                            </div>
                            <div>
                                {extendedImages[(currentImageIndex + 2) % extendedImages.length] && (
                                    <img
                                        src={extendedImages[(currentImageIndex + 2) % extendedImages.length]}  // Third image
                                        alt={`image${(currentImageIndex + 3) % extendedImages.length}`}
                                        className="tripcourasulrightimg2"
                                        onClick={() => openModal((currentImageIndex + 2) % extendedImages.length)}
                                    />
                                )}
                            </div>
                        </div>

                        {/* Right Arrow Button for Mobile */}
                        {isSmallScreen && (
                            <div className='arrowrightresponsovescreen' onClick={showNextImage}>
                                <img src={rightArrow} className="arrow-button right-arrow" alt="Next" />
                            </div>
                        )}

                        {/* Indicators for Small Screens */}
                        {isSmallScreen && (
                            <div className="carouseldetail-indicators">
                                {extendedImages.map((_, index) => (
                                    <span
                                        key={index}
                                        className={`indicatordetail ${index === currentImageIndex ? 'active' : ''}`}
                                        onClick={() => setCurrentImageIndex(index)}  // Update the currentImageIndex when clicking an indicator
                                    />
                                ))}
                            </div>
                        )}
                    </>
                ) : (
                    <p>No images</p>
                )}

            </div>

            {/* Modal for Image View */}
            {isModalOpen && (
                <div className="modal" tabIndex={0} onKeyDown={handleKeyDown} onClick={closeModal}>
                    <div className='corosalcontainertrip'>
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <div className="modal-navbar">
                                <button className="close-button" onClick={closeModal}>
                                    <img src={closeIcon} alt="Close" />
                                </button>
                                <span className="image-number">{`${currentImageIndex + 1} of ${extendedImages.length}`}</span>
                                <div className="save-container">
                                    <FaHeart size={24} color={likedItems[trip_id] ? 'red' : 'gray'} className="faheartsaveicon" onClick={(e) => handleLikeToggle(trip_id, e)} />
                                    <span>Save</span>
                                </div>
                            </div>
                            <img src={leftArrow} className="arrow-button left-arrowmodal" onClick={showPreviousImage} alt="Previous" />
                            <img src={extendedImages[currentImageIndex]} alt={`image${currentImageIndex + 1}`} />  {/* Image changes as per currentImageIndex */}
                            <img src={rightArrow} className="arrow-button right-arrowmodal" onClick={showNextImage} alt="Next" />
                        </div>
                    </div>
                </div>


            )}

            {showPopuplike && (
                <Onboardliketripdetailpoperror
                    isVisible={showPopuplike}
                    onClose={handleClosePopupdetailtrip}
                />
            )}
        </div>
    );
};

export default ImageCarousel;
