import React, { useState, useEffect } from 'react';
import { NavLink, Link, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import logo from './images/logo.svg';
import search from './images/search.svg';
import menuIcon from './images/menu.svg';
import arrowright from './images/chevron-right.svg';
import './Navbar.css';

const Navbar = ({ setKeyword }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchKeyword, setSearchKeyword] = useState(searchParams.get('search') || '');
  const [menuOpen, setMenuOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      const { userId } = JSON.parse(atob(token.split('.')[1]));
      fetchUsername(userId);
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    if (setKeyword) {
      setKeyword(searchKeyword);
    }
  }, [searchKeyword, setKeyword]);

  // Disable scroll when the menu is open
  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    // Clean up on unmount
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [menuOpen]);

  const fetchUsername = async (userId) => {
    try {
      const response = await axios.get(`https://yeahtrips.in/getusername/${userId}`);
      if (response.data && response.data.username) {
        setUsername(response.data.username);
      }
    } catch (error) {
      console.error("Error fetching username:", error);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      navigate('/logincomponent');
    } else {
      navigate('/login');
    }
    setMenuOpen(false); // Close the menu after navigation
  };

  const handleSearch = (e) => {
    const keyword = e.target.value;
    setSearchKeyword(keyword);
    setSearchParams({ search: keyword });
  };

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleSearchToggle = () => {
    setShowSearch(!showSearch);
  };

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('likedTripIds');
    localStorage.removeItem('rzp_checkout_anon_id');
    localStorage.removeItem('rzp_device_id');
    localStorage.removeItem('_grecaptcha')
    setIsLoggedIn(false);
    setUsername('');
    navigate('/');

    window.location.reload();
  };

  const handleNavLinkClick = () => {
    setMenuOpen(false); // Close the menu after clicking a NavLink
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={logo} alt="Logo" onClick={() => { navigate('/'); setMenuOpen(false); }} />
      </div>
      <div className={`navbar-links ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li>
            <NavLink
              to="/futureescapes"
              className={({ isActive }) => (isActive ? "navbar-link active-link" : "navbar-link")}
              onClick={handleNavLinkClick} // Close the menu when a NavLink is clicked
            >
              Future Escapes
              <span className='arrowrightspannavbar'><img src={arrowright} alt="" /></span>
            </NavLink>
          </li>
          <li>
            <Link
              to="#"
              className="navbar-link"
              onClick={(e) => { handleClick(e); handleNavLinkClick(); }}
            >
              My Trips
              <span className='arrowrightspannavbar'><img src={arrowright} alt="" /></span>
            </Link>
          </li>
          <li>
            <NavLink
              to="/community"
              className={({ isActive }) => (isActive ? "navbar-link active-link" : "navbar-link")}
              onClick={handleNavLinkClick} // Close the menu when a NavLink is clicked
            >
              Join our Community
              <span className='arrowrightspannavbar'><img src={arrowright} alt="" /></span>
            </NavLink>
          </li>
        </ul>
        <div className={`search-icon ${menuOpen ? 'open' : ''}`} onClick={handleSearchToggle}>
          <img src={search} alt="search" className="search-navbar" />
        </div>
        {showSearch && (
          <div className="search-input">
            <input
              type="text"
              value={searchKeyword}
              placeholder="Search..."
              onChange={handleSearch}
            />
          </div>
        )}
        <div className="nav-button">
          {isLoggedIn ? (
            <div className="flex items-center space-x-4">
              <span className="font-mulish text-sm font-semibold">Hello! {username}</span>
              <button className="navbar-button" onClick={handleLogout}>
                <span className="font-mulish text-sm font-semibold">Log Out</span>
              </button>
            </div>
          ) : (
            <Link to="/login" onClick={() => setMenuOpen(false)}>
            <button className="navbar-button">
              <span className="font-mulish text-sm font-semibold">Log In</span>
            </button>
          </Link>
          )}
        </div>
      </div>

      <div className='responsivenavbarsearchiconsmovenemt'>
        
      </div>
      <div className={`search-iconresponsive ${menuOpen ? 'open' : ''}`} onClick={handleSearchToggle}>
        <img src={search} alt="search" className="search-navbarresponsivescrren" />
      </div>
      {showSearch && (
        <div className="search-inputresponsivescreen">
          <input
            type="text"
            value={searchKeyword}
            placeholder="Search..."
            onChange={handleSearch}
            className='inputnavbarwidthresponsivescreens'
          />
        </div>
      )}
      <div className="menu-icon" onClick={handleMenuToggle}>
        <img src={menuIcon} alt="Menu" />
      </div>
    </nav>
  );
};

export default Navbar;
