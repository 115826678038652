import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import thundericon from './images/zap.svg';
import logocamp from './images/logocamp.svg';
import checkicon from './images/checkicon.svg';
import usernameicon from './images/usernameicon.svg';
import userageicon from './images/userageicon.svg';
import whatsappicon from './images/whatsapp.svg';
import Navbar from "../homepage/Navbar/Navbar";
import Footer from "../homepage/Footer/Footer";
import Getintouch from "../homepage/getintouch/Getintouch";
import HappyTravellers from "../homepage/happytraveller/Happytravellers";
import Moments from "../homepage/moments/Moments";
import './Community.css';
import { Helmet } from "react-helmet";
function Community() {
    const [selectedCountry, setSelectedCountry] = useState('IND');
    const [formData, setFormData] = useState({ name: '', age: '', phoneNumber: '' });
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [phoneCodes, setPhoneCodes] = useState([]);
    const [phoneCode, setPhoneCode] = useState('+91');
    const [phoneNumber, setPhoneNumber] = useState('');
    const navigate = useNavigate();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [communityData, setCommunityData] = useState([]);

    useEffect(() => {
        const fetchCommunityData = async () => {
            try {
                const response = await axios.get('https://yeahtrips.in/communitylink');
                if (response.data.length > 0) {
                    setCommunityData(response.data);
                }
            } catch (error) {
                console.error('Error fetching community link:', error);
            }
        };

        fetchCommunityData();
    }, []);


    useEffect(() => {
        fetchPhoneCodes();
    }, []);

    useEffect(() => {
        // Clear errors on formData change
        setErrors({});
    }, [formData]);

    const fetchPhoneCodes = () => {
        axios.get('https://yeahtrips.in/phonecode')
            .then(response => {
                const data = response.data;
                setPhoneCodes(data);
                const defaultCode = data.find(c => c.iso3 === selectedCountry)?.phonecode || '91';
                setPhoneCode(`+${defaultCode}`);
            })
            .catch(error => console.error('Error fetching phone codes:', error));
    };

    const handleCountryChange = (e) => {
        const country = e.target.value;
        setSelectedCountry(country);
        const code = phoneCodes.find(c => c.iso3 === country)?.phonecode || '91';
        setPhoneCode(`+${code}`);
        setPhoneNumber(''); // Reset phone number when country changes
    };

    const validateForm = () => {
        const errors = {};
        const { name, age, phoneNumber } = formData;

        if (!name) {
            errors.name = 'Please enter your name to proceed';
        } else if (!/^[a-zA-Z\s]+$/.test(name)) {
            errors.name = 'Must be alphabets';
        } else if (name.replace(/\s+/g, '').length < 2) {
            errors.name = 'Name must have at least 2 letters';
        }

        if (!age) {
            errors.age = 'Please enter your age to proceed';
        } else if (!Number.isInteger(Number(age)) || Number(age) <= 0) {
            errors.age = 'Age must be a positive integer';
        }

        if (age) {
            if (!phoneNumber) {
                errors.phoneNumber = 'Phone Number is required';
            } else if (!/^\+?[0-9]+$/.test(phoneNumber)) {
                errors.phoneNumber = 'Phone Number must be digits only';
            }
        }

        return errors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            const dataToSend = {
                name: formData.name,
                age: formData.age,
                phoneNumber: `${phoneCode}${phoneNumber}`,
            };

            axios.post('https://yeahtrips.in/communitymembers', dataToSend)
                .then(response => {
                    const isOver30 = Number(formData.age) > 30;
                    const linkToOpen = isOver30
                        ? communityData.find(link => link.name === "letsGoForACamp")?.link
                        : communityData.find(link => link.name === "yeahCommunity")?.link;

                    if (linkToOpen) {
                        window.open(linkToOpen, '_blank');
                    } else {
                        console.error('WhatsApp link not available.');
                    }

                    window.location.reload();
                })
                .catch(error => {

                    console.error('Error submitting data:', error);

                });
        } else {
            setErrors(formErrors);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
        if (submitted) {
            const formErrors = validateForm();
            setErrors(formErrors);
        }
    };

    const handleBlur = (e) => {
        const { name } = e.target;
        setTouched(prevTouched => ({
            ...prevTouched,
            [name]: true
        }));
    };

    const hasErrors = Object.keys(errors).length > 0;

    const handlePhoneChange = (e) => {
        const input = e.target.value.trim();
        const digitsOnly = input.slice(phoneCode.length).replace(/\D/g, '');

        setFormData(prevData => ({
            ...prevData,
            phoneNumber: `${phoneCode}${digitsOnly}`
        }));

        setPhoneNumber(digitsOnly);

        if (errors.phoneNumber) {
            setErrors(prevErrors => ({
                ...prevErrors,
                phoneNumber: ''
            }));
        }

        if (submitted) {
            const formErrors = validateForm();
            setErrors(formErrors);
        }
    };


    return (
        <div className="joincommunity">
            <Helmet>
                <title>Join YEAH Community | WhatsApp Group for Travel Enthusiasts</title>
                <meta name="description" content="Join the YEAH Community on WhatsApp and connect with fellow travel enthusiasts. Stay updated on upcoming trips, share travel tips, and be part of exclusive travel discussions." />
                <meta name="keywords" content="YEAH community, WhatsApp travel group, join WhatsApp group, travel enthusiasts, travel discussions, upcoming trips, YEAH community WhatsApp, travel tips, exclusive travel updates" />
                <meta property="og:title" content="Join YEAH Community | WhatsApp Group for Travel Enthusiasts" />
                <meta property="og:description" content="Become a part of the YEAH WhatsApp community to connect with other travel lovers, get updates on future trips, and join exclusive discussions about travel experiences." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://yeahtrips.in/community" />
            </Helmet>

            <Navbar />
            <div className="community-container">

                <div className="communitytextcontainer">
                    <div className="community-elementcommunity">
                        <img src={thundericon} alt="Thunder icon" />
                        <p>An initiative by experienced folks of </p>
                        <img src={logocamp} alt="Logo camp" />
                    </div>
                    <div className="community-textsection">

                        <div className="community-text">

                            <div className="community-heading">
                                <h1>Get <span>Insider Access</span> to Epic Trips (and New Friends!)</h1>
                            </div>
                        </div>
                        <div className="community-list">
                            {[
                                'Exclusive trip announcements.',
                                'Insider trip updates.',
                                'Know your travel buddies.',
                                'Sweet member-only deals.'
                            ].map((text, index) => (
                                <div className={`community-list${index + 1}`} key={index}>
                                    <div className="communitylist1-img">
                                        <img src={checkicon} alt="Check icon" />
                                    </div>
                                    <div className="communitylist1-text">
                                        <p>{text}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>


                <form className={`community-form ${formData.age ? 'expanded' : ''}`} onSubmit={handleSubmit}>
                    <div className="community-forminput">
                        <div className="community-name">
                            <div className="input-container">
                                <label htmlFor="name">Full Name</label>
                                <img src={usernameicon} alt="Username Icon" />
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="Enter your name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className={touched.name && errors.name ? 'error-border' : ''}
                                    onBlur={handleBlur}
                                />
                                {errors.name && <div className="error-message">{errors.name}</div>}
                            </div>
                        </div>


                        <div className="community-age">
                            <div className="input-container">
                                <label htmlFor="age">Age</label>
                                <img src={userageicon} alt="Age Icon" />
                                <input
                                    type="text"
                                    id="age"
                                    name="age"
                                    placeholder="Age"
                                    value={formData.age}
                                    onChange={handleChange}
                                    className={`no-arrow ${touched.age && errors.age ? 'error-border' : ''}`}
                                    onBlur={handleBlur}
                                />
                                {errors.age && <div className="error-message">{errors.age}</div>}
                            </div>
                        </div>

                        {formData.age && (
                            <div className="community-phonenumber">
                                <div className="input-container">
                                    <label htmlFor="phoneNumber">Phone Number</label>
                                    <select
                                        className={selectedCountry === 'IND' ? 'selectcommunity-borderlesscommmunity' : ''}
                                        onChange={handleCountryChange}
                                        value={selectedCountry}
                                        onClick={() => setDropdownVisible(true)}
                                    >
                                        {phoneCodes.length > 0 ? (
                                            phoneCodes.map((code) => (
                                                <option key={code.iso3} value={code.iso3}>
                                                    {code.iso3} (+{code.phonecode})
                                                </option>
                                            ))
                                        ) : (
                                            <option value="IND">IND (+91)</option>
                                        )}
                                    </select>
                                    <input
                                        type="tel"
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        placeholder={`${phoneCode} 0000 000 000`}
                                        value={`${phoneCode}${phoneNumber}`}
                                        onChange={handlePhoneChange}
                                        className={touched.phoneNumber && errors.phoneNumber ? 'error-border' : ''}
                                        onBlur={handleBlur}
                                    />
                                    {errors.phoneNumber && <div className="error-message">{errors.phoneNumber}</div>}
                                    {!errors.phoneNumber && (
                                        <p className="inputmessageforphone">Your privacy is our priority. We never share your number.</p>
                                    )}
                                </div>
                            </div>
                        )}



                        {formData.age && Number(formData.age) > 30 && (
                            <div className="age-messagecommunity">
                                <p>
                                    YEAH keeps those adventures young and wild (18-30 only!).
                                    But don't worry, we have awesome travel options for everyone –
                                    check them  out here!
                                </p>

                                <a href="https://www.letsgoforacamp.com/" target="_blank" rel="noopener noreferrer">
                                    <h1>Check Out Let’s Go For A Camp</h1>
                                </a>

                            </div>
                        )}


                        <div className={`community-button ${hasErrors ? 'graybutton' : ''}`}>
                            <button type="submit">
                                <img src={whatsappicon} alt="WhatsApp icon" />
                                {formData.age > 30 ? "Join Let's Go for Camp Community" : "Join WhatsApp Community"}
                            </button>
                        </div>

                    </div>
                </form>
            </div>

            <Moments />
            <Getintouch />
            <HappyTravellers />
            <Footer />
        </div>
    );
}

export default Community;
