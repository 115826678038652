import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import axios from 'axios';
import { format } from 'date-fns';
import './Future.css';
import Heart from './Images/heart.svg';
import Calendar from './Images/calendar-date.svg';
import Arrow from './Images/arrowrighthomepage.svg';
import Location from './Images/Icon.svg';
import ArrowRight from './Images/arrow-right.svg';
import Onboardlikepoperror from '../popuperror/likepopuperror';

const parseCustomDate = (dateString) => {
  try {
    const regex = /^(\d{1,2})[a-z]{2}\s(\w+)\s(\d{4})$/;
    const match = dateString.match(regex);
    if (match) {
      const [, day, month, year] = match;
      const monthIndex = new Date(Date.parse(month + " 1, 2024")).getMonth();
      return new Date(year, monthIndex, day);
    } else {
      throw new Error('Invalid date format');
    }
  } catch (error) {
    console.error('Date parsing error:', error);
    return null;
  }
};

const formatCustomDate = (date) => {
  try {
    if (date && !isNaN(date.getTime())) {
      return format(date, 'dd MMM yyyy');
    } else {
      throw new Error('Invalid date object');
    }
  } catch (error) {
    console.error('Date formatting error:', error);
    return 'Invalid date';
  }
};

const getCurrentMonthYear = () => {
  const currentDate = new Date();
  return format(currentDate, 'MMM yyyy'); // Abbreviated month
};

const getNextMonths = (count) => {
  const months = [];
  const currentDate = new Date();
  for (let i = 0; i < count; i++) {
    const nextDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + i);
    months.push(format(nextDate, 'MMM yyyy')); // 'MMM' gives the abbreviated month name
  }
  return months;
};

function FutureTrips() {
  const [trips, setTrips] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonthYear());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [likedItems, setLikedItems] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [noTripsMessage, setNoTripsMessage] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');

  const location = useLocation();
  const monthsToShow = getNextMonths(3);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://yeahtrips.in/tripdetails');
        const today = new Date(); // Current date

        const publishedTrips = response.data.filter(trip => {
          // Remove ordinal suffixes and parse the trip_start_date
          const cleanedDateString = trip.trip_start_date.replace(/(\d+)(st|nd|rd|th)/, '$1');
          const tripStartDate = new Date(cleanedDateString); // Create a Date object

          // Check if the trip is published and the start date is today or in the past
          return trip.status === 'published' && tripStartDate >= today;
        });

        setTrips(publishedTrips);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.response ? error.response.data : error.message || 'Error fetching data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);



  useEffect(() => {
    const loadLikedItems = () => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        const likedTripIds = JSON.parse(localStorage.getItem('likedTripIds')) || [];
        const likedItemsMap = likedTripIds.reduce((acc, trip_id) => {
          acc[trip_id] = true;
          return acc;
        }, {});
        setLikedItems(likedItemsMap);
      }
    };

    loadLikedItems();
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const keyword = query.get('search') || '';
    setSearchKeyword(keyword);
  }, [location.search]);

  const filterTripsByMonth = (trips, monthYear) => {
    if (monthYear === 'Trips After') {
      const lastMonth = monthsToShow[monthsToShow.length - 1];
      const lastMonthDate = new Date(Date.parse(lastMonth + " 1, " + new Date().getFullYear()));
      return trips.filter(trip => {
        const tripStartDate = parseCustomDate(trip.trip_start_date);
        return tripStartDate && tripStartDate > lastMonthDate;
      });
    } else {
      return trips.filter(trip => {
        const tripStartDate = parseCustomDate(trip.trip_start_date);
        if (tripStartDate) {
          const tripMonthYear = format(tripStartDate, 'MMM yyyy'); // Abbreviated month
          return tripMonthYear === monthYear;
        } else {
          return false;
        }
      });
    }
  };


  const filterTripsByKeyword = (trips, keyword) => {
    return trips.filter(trip => {
      const tripName = trip.trip_name.toLowerCase();
      return tripName.includes(keyword.toLowerCase());
    });
  };

  const filteredTripsByMonth = filterTripsByMonth(trips, selectedMonth);
  const filteredTrips = filterTripsByKeyword(filteredTripsByMonth, searchKeyword);

  const handleMonthClick = (month) => {
    if (month === 'Trips After') {
      const futureTrips = trips.filter(trip => {
        const tripStartDate = parseCustomDate(trip.trip_start_date);
        return tripStartDate && tripStartDate > new Date();
      });

      if (futureTrips.length > 0) {
        setSelectedMonth(month);
        setNoTripsMessage('');
      } else {
        setSelectedMonth(month);
        setNoTripsMessage('No trips planned after this month.');
      }
    } else {
      setSelectedMonth(month);
      setNoTripsMessage('');
    }
  };

  const handleLikeToggle = async (trip_id, e) => {
    if (e) {
      e.preventDefault();
    }

    // Optimistic UI update: temporarily updating state before API confirmation
    setLikedItems(prev => {
      const newLikedItems = {
        ...prev,
        [trip_id]: !prev[trip_id],
      };
      return newLikedItems;
    });

    try {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        setShowPopup(true);
        return;
      }

      const { userId } = JSON.parse(atob(token.split('.')[1]));

      // Determine the current like state
      const currentState = { ...likedItems, [trip_id]: !likedItems[trip_id] };

      // Perform API call based on the like state
      if (currentState[trip_id]) {
        await axios.post('https://yeahtrips.in/api/saveTrip', {
          trip_id,
          userId,
        });
      } else {
        await axios.post('https://yeahtrips.in/api/removeTrip', {
          trip_id,
          userId,
        });
      }

      // Only update localStorage after successful API call
      setLikedItems(prev => {
        const updatedLikedTripIds = Object.keys(prev).filter(id => prev[id]);
        localStorage.setItem('likedTripIds', JSON.stringify(updatedLikedTripIds));
        return prev;
      });

    } catch (error) {
      console.error('Error saving or removing liked trip:', error);
    }
  };



  const handleClosePopup = () => {
    setShowPopup(false);
    window.location.reload();
  };
  const getCurrentMonthTripsCount = () => {
    const now = new Date();
    const currentMonth = now.getMonth(); // 0-based index (0 for January, 11 for December)
    const currentYear = now.getFullYear();

    // Calculate the start of the current month
    const startOfCurrentMonth = new Date(currentYear, currentMonth, 1);


    const filteredTrips = trips.filter(trip => {
      const tripDate = parseCustomDate(trip.trip_start_date);

      if (!tripDate) return false;


      // Include trips starting from the beginning of the current month onwards
      return tripDate >= startOfCurrentMonth;
    });

    ;

    return filteredTrips.length;
  };


  const upcomingTripsCount = getCurrentMonthTripsCount();


  return (
    <div className='background_futurelandingpage'>
      <div className='futuretripheadingcontainer'>
        <div className='futuretripheaddingsection'>
          <h1>Explore Future Escapes 🚗</h1>
        </div>
        <div className='tripviewmorecontainer'>
          <div className='tripviewmoresection'>
            <Link to='/futureescapes'><p>View All Trips ({upcomingTripsCount})</p></Link>
          </div>
          <div>
            <img src={ArrowRight} alt="Arrow Right" />
          </div>
        </div>
      </div>

      <div className='datesfilteermonth'>
        {monthsToShow.map(month => (
          <span
            key={month}
            style={{ cursor: 'pointer' }}
            onClick={() => handleMonthClick(month)}
            className={`monthsoftripselection ${selectedMonth === month ? 'selected' : ''}`}
          >
            {month}
          </span>
        ))}
        <span
          key='Trips After'
          style={{ cursor: 'pointer' }}
          onClick={() => handleMonthClick('Trips After')}
          className={`monthsoftripselection ${selectedMonth === 'Trips After' ? 'selected' : ''}`}
        >
          Trips After {monthsToShow[monthsToShow.length - 1]}
        </span>
      </div>

      <div className='outline_homepage'>
        <div className='future_imagecont' style={{ border: 'none' }}>
          {filteredTrips.length === 0  ? (
            <div className="no-trips-message">
              <h1>No trips available for the selected month.</h1>
            </div>
          ) : (
            <>
              {filteredTrips.slice(0, 5).map((item) => (
                <img
                  src={`https://admin.yeahtrips.in${item.image_url}`}
                  alt="Trip"
                  className='tripimagesfuture'
                  key={item.trip_id}
                />
              ))}

              {/* Check if the 6th item exists before trying to render its image */}
              {filteredTrips.length > 5 && (
                <img
                  src={`https://admin.yeahtrips.in${filteredTrips[5].image_url}`}
                  alt="Trip"
                  className='tripimagesfuture'
                  key={filteredTrips[5].trip_id}
                />
              )}
            </>

          )}
        </div>

        {filteredTrips.slice(0, 5).map((item) => {
          const tripLink = `/${item.slug}`;
          return (
            <Link
              to={tripLink}
              className='future-contents'
              key={item.trip_id}
            >
              <div className='future-entire'>
                <div className='content-group1'>
                  <div className='future-seats'>Only {(item.totalseats) - (item.seats)} seats left</div>
                  <div className='heart-container'>
                    <i
                      className={`fa-heart ${likedItems[item.trip_id] ? 'fas red' : 'far'}`}
                      onClick={(e) => handleLikeToggle(item.trip_id, e)}
                    ></i>
                  </div>
                </div>
                <div className='content-group2'>
                  <div className='futuretripnamehomepage'>{item.trip_name}</div>
                  <div className='content-group3'>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                      <img src={Calendar} alt="Calendar Icon" />
                      <div className='stardatehomepagecontainers'>{formatCustomDate(parseCustomDate(item.trip_start_date))}</div>
                    </div>
                    <img src={Arrow} alt="Arrow Icon" />
                    <div>{formatCustomDate(parseCustomDate(item.end_date))}</div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                    <img src={Location} alt="Location Icon" />
                    <span className='triplocationstart'>Starts from {item.trip_start_point}</span>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
        {filteredTrips.length > 5 && (

          <>


            <div className='future-contents'>
              <div className='viewmoretripscontainer'>
                <div className='texttripmonth'>
                  <h1>View more trips planned for <br /> {selectedMonth}!</h1>
                </div>
                <div className='viermoretripbuttoncontainer'>
                  <div className='viewmorebuttontextsection'>
                    <Link to='/futureescapes'><h1>Discover Future Escapes</h1></Link>
                  </div>
                </div>
              </div>
            </div>
          </>

        )}
        {showPopup && <div className="overlaypopuphomepage"></div>}
        {showPopup && <Onboardlikepoperror onClose={handleClosePopup} isVisible={showPopup} />}
      </div>


    </div>
  );
}

export default FutureTrips;
