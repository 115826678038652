import React, { useEffect } from "react";
import alerticon from './images/onboarderror.svg';
import { Link } from "react-router-dom";
import cross from './images/cross.svg';
import './Onboarderror.css';

function Onboarderror({ onClose }) {
    useEffect(() => {
        // Prevent scrolling when the popup is displayed
        document.body.style.overflow = 'hidden';

        // Clean up and re-enable scrolling when the popup is closed
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <>
            <div className="overlay" onClick={onClose}></div>
            <div className="savetripcontainerpopup">
                <div className="onboarderror-container">
                    <div className="onboarderrorcross">
                        <img src={cross} alt="close icon" onClick={onClose} />
                    </div>
                    <div className="onerrorcontainer">
                        <div className="boarderrortext">
                            <div className="boarderrorimage">
                                <img src={alerticon} alt="error alert icon" />
                            </div>
                            <div className="onboarderrorText">
                                <h6>Like the trip? Please log in to save it!</h6>
                                <p>Let'sGoForACamp is where amazing family <br />
                                    memories are made.</p>
                            </div>
                            <div className="onboarderrorbutton">
                                <div className="onboarderrorbutton1">
                                    <Link to='/login'><button>Log In To YEAH</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Onboarderror;
