import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import './moments.css';
import imagearrowleft from './images/momentsarrowleft.svg';
import imagearrowright from './images/momentsarrowright.svg';

function Moments() {
    const [hoveredImage, setHoveredImage] = useState(null);
    const [momentsImages, setMomentsImages] = useState([]);
    const containerRef = useRef(null);

    // Function to fetch images from the backend
    const fetchMomentsImages = async () => {
        try {
            const response = await axios.get('https://yeahtrips.in/getthepictureperfectmoments'); 
            setMomentsImages(response.data); 
        } catch (error) {
            console.error("Error fetching the moments images:", error);
        }
    };

    useEffect(() => {
        fetchMomentsImages(); // Fetch images when the component mounts
    }, []);

    const scrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                left: -containerRef.current.offsetWidth / 2, // Scroll by half the container width
                behavior: 'smooth'
            });
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                left: containerRef.current.offsetWidth / 2, // Scroll by half the container width
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className="perfect-moment">
            <div className="moment-element">
                <h1>Picture Perfect Moments 📸</h1>
            </div>

            <div className="momentscontainer" ref={containerRef}>
                {momentsImages.map((image) => (
                    <img
                        key={image.id}
                        src={`https://admin.yeahtrips.in${image.image}`}  // Corrected URL
                        alt="#"
                        className={hoveredImage === image.image ? "hovered" : "shrink"}
                        onMouseEnter={() => setHoveredImage(image.image)}
                        onMouseLeave={() => setHoveredImage(null)}
                    />
                ))}
            </div>
            <div className="momentsarrows">
                <div className="arrowleft" onClick={scrollLeft}>
                    <img src={imagearrowright} alt="moveleft" />
                </div>
                <div className="arrowright" onClick={scrollRight}>
                    <img src={imagearrowleft} alt="moveright" />
                </div>
            </div>
        </div>
    );
}

export default Moments;
