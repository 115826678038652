import React, { useState, useRef,useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Navbar from "../Navbar/Navbar";
import Content from "../content/Content";
import Courosal from "../Courosaul/Courasaul";
import Setusapart from "../setusapart/Setusapart";
import Team from "../meetgang/Team";
import Happytravellers from "../happytraveller/Happytravellers";
import Moments from "../moments/Moments";
import Getintouch from "../getintouch/Getintouch";
import Footer from "../Footer/Footer";
import Future from "../../future escapes/Future";
import FutureTrips from "../futuretrips/Future";
import './Landingpage.css'

function Landingpage() {
  const [searchKeyword, setSearchKeyword] = useState('');
  const futureTripsRef = useRef(null);
  const getintouchRef = useRef(null);
  const footerRef = useRef(null);
  const location = useLocation();


  const scrollToGetintouch = () => {
    if (getintouchRef.current) {
      getintouchRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToFutureTrips = () => {
    if (futureTripsRef.current) {
      futureTripsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  
  useEffect(() => {
    if (location.state?.scrollToFooter) {
      scrollToGetintouch();
    }
  }, [location]);


  return (
    <div className="landingpageoverallcontainerformobilescreens" >
      <div  >
        <Navbar />
        <Content futureTripsRef={futureTripsRef} />
        <Courosal />
        <div ref={futureTripsRef}>
          <FutureTrips />
        </div>        <Setusapart />
        <Team />
        <Happytravellers />
        <Moments />
        <div ref={getintouchRef}>
          <Getintouch  ref={footerRef}/>
        </div>
        <Footer onContactUsClick={scrollToGetintouch} />
      </div>

    </div>
  );
}

export default Landingpage;













