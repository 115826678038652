import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import cross from './Images/x.svg';
import axios from 'axios';
import './Otp.css';
import Login from "../Login/Login";
import Loading from '../Loading/Loading';

function Otp() {
  const location = useLocation();
  const navigate = useNavigate();

  const { email = '', phonenumber = '' } = location.state || {};

  const [otp, setOtp] = useState(["", "", "", ""]);
  const [loading, setLoading] = useState(true);
  const [seconds, setSeconds] = useState(30);
  const [showResendLink, setShowResendLink] = useState(false);

  // Handles OTP verification submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://yeahtrips.in/verify', {
        otp: otp.join("")
      });
      setOtp(["", "", "", ""]); // Clear the OTP input fields
      if (response.data.success) {
        const accessToken = response.data.token;
        const tripIds = response.data.trip_ids;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('likedTripIds', JSON.stringify(tripIds));
        navigate('/logincomponent');
      } else {
        console.error("OTP verification failed:", response.data.message);
      }
    } catch (error) {
      console.error("Verification error:", error);
    }
  };

  // Sets loading state with a 2-second delay
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  // Manages countdown for the OTP resend link
  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setShowResendLink(true);
    }
  }, [seconds]);

  // WebOTP API for autofilling OTP from SMS
  useEffect(() => {
    if ('OTPCredential' in window) {
      const ac = new AbortController();
      navigator.credentials.get({
        otp: { transport: ['sms'] },
        signal: ac.signal,
      }).then((otpCredential) => {
        const otpCode = otpCredential.code.split('');
        setOtp(otpCode);
        otpCode.forEach((digit, index) => {
          const inputField = document.getElementById(`otp-input-${index}`);
          if (inputField) {
            inputField.value = digit;
          }
        });
      }).catch((err) => {
        console.error("Error retrieving OTP via WebOTP API:", err);
      });

      return () => ac.abort();
    }
  }, []);

  // Handles OTP input changes
  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value)) {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = value;
        return newOtp;
      });
      const nextIndex = index + 1;
      if (nextIndex < otp.length) {
        document.getElementById(`otp-input-${nextIndex}`).focus();
      }
    } else if (value === '') {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = '';
        return newOtp;
      });
      const prevIndex = index - 1;
      if (prevIndex >= 0) {
        document.getElementById(`otp-input-${prevIndex}`).focus();
      }
    }
  };

  // Close button functionality
  const handleCloseClick = () => {
    navigate('/login');
  };

  // Resend OTP functionality
  const handleResendOtp = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://yeahtrips.in/login', {
        email: email,
        phonenumber: phonenumber
      });
      if (response.data.success) {
        alert(`Your OTP is: ${response.data.otp}`);
        setSeconds(30); // Reset the countdown for resend link
        setShowResendLink(false);
      } else {
        console.error("Failed to resend OTP:", response.data.message);
      }
    } catch (error) {
      console.error("Resend OTP error:", error);
    }
  };

  // Render loading spinner or OTP form
  if (!loading) {
    return (
      <div>
        <Login />
        <div className='overlayotp'></div>
        <div className='otp'>
          <div className='group1otp'>
            <div className='text-cross'>
              <div className="enterotptextincomponent"><b>Enter  OTP</b></div>
              <img src={cross} alt="close" style={{ cursor: 'pointer' }} className='close' onClick={handleCloseClick} />
            </div>
            <div className='text'>Please enter OTP sent to your email and phone number</div>
          </div>
          <div className='group2otp'>
            <div className='set1'>
              <div>Enter code</div>
              <div className='inpcont'>
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`}
                    className='inputotp'
                    type='text'
                    placeholder='0'
                    value={digit}
                    onChange={(e) => handleChange(e, index)}
                    maxLength={1}
                    inputMode='numeric' // Use numeric input mode
                    autoComplete='one-time-code' // Ensure correct autocomplete attribute
                  />
                ))}
              </div>
            </div>
            {showResendLink ? (
              <span className="otptextforresendotp">
                Didn't receive the OTP?
                <span className='grad' onClick={handleResendOtp}> Resend Code</span>
              </span>
            ) : (
              <span className="otptextduringreceivingotp">
                Verification link sent! Still didn't receive it?
                <span className="otptimerduringotpentering"> Retry in {seconds}s</span>
              </span>
            )}
          </div>
          <button onClick={handleSubmit} className='loginyeah'>Log In to Yeah</button>
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
}

export default Otp;
