import React, { forwardRef } from "react"; // Import forwardRef
import icon from './images/icon.svg';
import icon2 from './images/icon2.svg';
import sticker from './images/sticker.svg';
import './Getintouch.css';

const Getintouch = forwardRef((props, ref) => { // Use forwardRef
    return (
        <div className="getintouch_container" ref={ref}> {/* Attach ref here */}
            <div className="getintouch_element">
                <div className="getintouch_details">
                    <div className="getintouch_text">
                        <div className="getintouch_text1">
                            <p>Wanna know more what <span>YEAH</span> is all about? 🤙🏻</p>
                        </div>
                        <div className="getintouch_text2">
                            <p>Drop us a line, our friendly team is always here to chat.</p>
                        </div>
                    </div>
                    <div className="getintouch_contact">
                        <div className="getintouch_phone">
                            <img src={icon2} alt="phone" />
                            <div className="phonenumbers">
                                <div className="phoneheading">
                                    <p>Phone</p>
                                </div>
                                <div className="phonenumber1">
                                    <a href="tel:+918050725190">
                                        <p>+91-8050725190</p>
                                    </a>
                                </div>

                                <div className="phonenumber2">
                                    <a href="tel:+917829230956">
                                        <p>+91-7829230956</p>
                                    </a>

                                </div>
                            </div>
                        </div>
                        <div className="getintouch_email">
                            <div className="email_image">
                                <img src={icon} alt="email" />
                            </div>
                            <div className="email_text">
                                <div className="email_heading">
                                    <p>Email</p>
                                </div>
                                <div className="email1">
                                    <a href="mailto:letsgoforacamp@gmail.com">
                                        <p>letsgoforacamp@gmail.com</p>
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="getintouch_sticker">
                            <img src={sticker} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Getintouch;
