import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../homepage/Navbar/Navbar';
import { Helmet } from 'react-helmet';

const BlogDetails = () => {
    const { slug } = useParams();
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await axios.get(`https://yeahtrips.in/getindividualblogs?slug=${slug}`);
                if (response.data.length > 0) {
                    setBlog(response.data[0]);
                } else {
                    setError('Blog not found');
                }
            } catch (err) {
                setError('Error fetching blog details');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchBlog();
    }, [slug]);

    return (
        <div className="min-h-screen bg-gray-50">
            {/* SEO Metadata */}
            <Helmet>
                <title>YEAH - Blogs - {slug}</title>
                <meta name="description" content={`Read the latest blog on ${slug} at YEAHtrips. Discover travel tips, adventure stories, and more.`} />
                <meta name="keywords" content={`YEAHtrips, blog, travel blog, ${slug}, adventure, travel tips, vacation stories`} />
                <meta property="og:title" content={`YEAH - Blogs - ${slug}`} />
                <meta property="og:description" content={`Explore our blog on ${slug} at YEAHtrips. Get inspired with travel stories, tips, and insights.`} />
                <meta property="og:type" content="article" />
                <meta property="og:url" content={`https://yeahtrips.in/blogs/${slug}`} />
            </Helmet>

            {/* Navbar */}
            <Navbar />

            {/* Loading and Error Messages */}
            {loading ? (
                <div className="text-center mt-10 text-lg font-medium text-gray-600">Loading...</div>
            ) : error ? (
                <div className="text-center mt-10 text-red-500 text-xl">{error}</div>
            ) : (
                <div className="container mx-auto p-4 md:p-8">
                    <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
                        {/* Blog Image */}
                        <img
                            src={`https://admin.yeahtrips.in${blog.image}`}
                            alt={blog.title.replace(/<[^>]+>/g, '')}
                            className="w-full h-72 md:h-96 object-cover"
                        />


                        <div className="p-6 md:p-10">

                            <h1
                                className="text-2xl font-bold text-gray-900 mb-6"
                                dangerouslySetInnerHTML={{
                                    __html: blog.title.replace(/\\n/g, '<br/>')
                                }}
                            ></h1>



                            <div
                                className="blog-content text-lg text-gray-700 leading-relaxed space-y-6"
                                dangerouslySetInnerHTML={{
                                    __html: blog.content
                                        .replace(/\\+/g, '')
                                        .replace(
                                            /<strong>(\d+)\s([A-Za-z]+,\s[A-Za-z]+)<\/strong>/g,
                                            '<strong class="font-bold text-gray-900">$1. $2</strong>'
                                        )
                                        .replace(/<li>(\s*|<br\s*\/?>)*<\/li>/g, '<li class="mb-4"></li>')
                                        .replace(/<img/g, '<img class="w-full sm:w-[70vw] md:w-[60vw] lg:w-[50vw] h-auto max-h-[50vh] rounded-lg shadow-md my-8 mx-auto block"')
                                        .replace(/<ul>/g, '<ul class="list-disc pl-5">')
                                        .replace(/<li>/g, '<li class="mb-4">')
                                        .replace(/<a /g, '<a class="text-blue-600 underline hover:text-blue-700" ')
                                        .replace(/<figure>/g, '<figure class="my-6 text-center">')
                                        .replace(/<figcaption>/g, '<figcaption class="text-sm text-gray-500 mt-2">')
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BlogDetails;
