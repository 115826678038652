import React, { useEffect } from 'react';
import Navbar from '../homepage/Navbar/Navbar';
import { Helmet } from 'react-helmet';
const PrivacyPolicy = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>YEAH - Privacy Policy</title>
        <meta name="description" content="Read the YEAHtrips Privacy Policy to understand how we collect, use, and protect your personal data when you use our services and participate in trips." />
        <meta name="keywords" content="Privacy Policy, YEAHtrips, data protection, personal information, user privacy, travel privacy, data security, privacy terms, GDPR" />
        <meta property="og:title" content="YEAH - Privacy Policy" />
        <meta property="og:description" content="Learn how YEAHtrips collects, uses, and protects your personal data. Read our Privacy Policy to understand your rights and responsibilities." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://yeahtrips.in/privacy-policies" />
      </Helmet>

      <div>
        <Navbar />
      </div>
      <div className="bg-gray-50 py-10 px-4 sm:px-6 lg:px-8">

        <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-8 md:p-12">
          <h1 className="text-4xl font-bold text-center text-blue-600 mb-6">Privacy Policy</h1>
          <p className="text-gray-500 text-center mb-6">Effective Date: 4-Oct-2024</p>

          <p className="text-gray-700 mb-6 text-lg">
            Welcome to <strong>YeahTrips.in</strong>, an initiative by <strong>LetsGoForACamp Travel and Innovation LLP</strong>. At YeahTrips.in, we prioritize the privacy of our users and are committed to protecting the personal information you share with us. This Privacy Policy outlines how we collect, use, and safeguard your data when you visit our website or use our services.
          </p>

          <h2 className="text-2xl font-semibold text-blue-600 mb-4">1. Information We Collect</h2>
          <p className="text-gray-700 mb-4 text-lg">We may collect the following types of information:</p>
          <ul className="list-disc list-inside mb-6 text-gray-700 text-lg">
            <li><strong>Personal Information:</strong> Name, email, phone number, billing address, payment details.</li>
            <li><strong>Travel Preferences:</strong> Information regarding trips, destinations, and preferences.</li>
            <li><strong>Device Information:</strong> IP address, browser type, and operating system details.</li>
            <li><strong>Cookies:</strong> We use cookies to enhance your experience and gather data on behavior.</li>
          </ul>

          <h2 className="text-2xl font-semibold text-blue-600 mb-4">2. How We Use Your Information</h2>
          <p className="text-gray-700 mb-6 text-lg">We use your information to:</p>
          <ul className="list-disc list-inside mb-6 text-gray-700 text-lg">
            <li>Process and manage your trip bookings.</li>
            <li>Communicate regarding bookings, payments, and trip details.</li>
            <li>Provide customer support and respond to inquiries.</li>
            <li>Improve website functionality and user experience.</li>
            <li>Send promotional emails or offers if opted in.</li>
          </ul>

          <h2 className="text-2xl font-semibold text-blue-600 mb-4">3. Sharing of Information</h2>
          <p className="text-gray-700 mb-6 text-lg">We respect your privacy and do not sell your data. However, we may share information with:</p>
          <ul className="list-disc list-inside mb-6 text-gray-700 text-lg">
            <li><strong>Service Providers:</strong> For payment processing, booking management, and support.</li>
            <li><strong>Partners:</strong> Third-party tour operators or service providers involved in delivering trips.</li>
            <li><strong>Legal Requirements:</strong> In response to legal orders or to comply with laws.</li>
          </ul>

          <h2 className="text-2xl font-semibold text-blue-600 mb-4">4. Data Security</h2>
          <p className="text-gray-700 mb-6 text-lg">
            We take your data security seriously, implementing industry-standard measures like encryption and secure servers to safeguard your information.
          </p>

          <h2 className="text-2xl font-semibold text-blue-600 mb-4">5. Your Choices</h2>
          <p className="text-gray-700 mb-4 text-lg">
            <strong>Access & Correction:</strong> You can access and update your personal information at any time.
          </p>
          <p className="text-gray-700 mb-6 text-lg">
            <strong>Marketing Emails:</strong> To opt-out of marketing emails, click the "unsubscribe" link in any promotional email.
          </p>

          <h2 className="text-2xl font-semibold text-blue-600 mb-4">6. Cookies</h2>
          <p className="text-gray-700 mb-6 text-lg">
            We use cookies to improve your experience. You can accept or decline cookies through your browser settings, though disabling them may affect site functionality.
          </p>

          <h2 className="text-2xl font-semibold text-blue-600 mb-4">7. Third-Party Links</h2>
          <p className="text-gray-700 mb-6 text-lg">
            Our website may contain links to third-party sites. We are not responsible for their privacy practices, and we encourage you to review their policies before interacting with those sites.
          </p>

          <h2 className="text-2xl font-semibold text-blue-600 mb-4">8. Changes to this Privacy Policy</h2>
          <p className="text-gray-700 mb-6 text-lg">
            We may update this policy from time to time. Any changes will be reflected on this page with an updated "Effective Date."
          </p>

          <h2 className="text-2xl font-semibold text-blue-600 mb-4">9. Contact Us</h2>
          <p className="text-gray-700 mb-6 text-lg">
            If you have any questions about this Privacy Policy or how your data is handled, please contact us at:
          </p>
          <address className="text-gray-700 text-lg mb-6">
            <strong>LetsGoForACamp Travel and Innovation LLP</strong><br />
            Chitunnill (H), Muppathadam PO, Aluva - 683110<br />
            Email: <a href="mailto:letsgoforacamp@gmail.com" className="text-blue-500">letsgoforacamp@gmail.com</a><br />
            Phone: +91 7829230956
          </address>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
