import React, { useState, useEffect } from 'react';
import cross from './Images/x-circle.svg';
import './Saved.css';
import Landingpage from '../homepage/Landinhpage/Landingpage';
import Logout from './Images/log-out-03.svg';
import axios from 'axios';
import arrowdown from './Images/chevron-down.svg';
import profileicon from './Images/profileicon.svg';
import ageicon from './Images/ageicon.svg';
import phoneicon from './Images/phoneicon.svg';
import mailicon from './Images/mailicon.svg';
import cityicon from './Images/cityicon.svg';
import stateicon from './Images/stateicon.svg';
import { Link, useNavigate } from "react-router-dom";
import Cancel from '../Cancel/Cancel';
import exclamatoryicon from './Images/exclamatoryicon.svg';
import hearticon from './Images/heart.svg';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Navbar from '../homepage/Navbar/Navbar';
import { Helmet } from 'react-helmet';
function Saved() {
  const [selectedNav, setSelectedNav] = useState('Upcoming');
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [likedTrips, setLikedTrips] = useState([]);
  const [upcomingTrips, setUpcomingTrips] = useState([]);
  const [completedTrips, setCompletedTrips] = useState([]);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [detailspaymentVisible, setpaymentDetailsVisible] = useState(false)
  const [memberDetails, setMemberDetails] = useState([]);
  const [memberDetailsVisible, setMemberDetailsVisible] = useState(false);
  const [detailscancellationnVisible, setcancellationDetailsVisible] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [cancellationPolicies, setCancellationPolicies] = useState([]);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [showCancellationPopup, setShowCancellationPopup] = useState(false);
  const [selectedTripName, setSelectedTripName] = useState('');
  const [selectedSeats, setSelectedSeats] = useState('');
  const [selectedbookingId, setselectedbookingId] = useState('');
  const [selectedtripId, setselectedTripId] = useState('')
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const handleCancelTrip = (tripName, seats, booking_id, trip_id) => {
    setSelectedTripName(tripName);
    setSelectedSeats(seats);
    setselectedbookingId(booking_id);
    setselectedTripId(trip_id);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedTripName('');
    setSelectedSeats('')
  };

  const navigate = useNavigate();
  const handleNavClick = (navItem) => {
    setSelectedNav(navItem);
  };

  const handleCloseClick = () => {
    setSidebarVisible(false);
    navigate('/')
  };
  const token = localStorage.getItem('accessToken');
  const { userId } = JSON.parse(atob(token.split('.')[1]));

  const handleViewDetails = async (trip) => {
    setSelectedTrip(trip);

    setDetailsVisible({
      [trip.booking_id]: true, // Set the new booking_id to visible
    });


    try {
      const token = localStorage.getItem('accessToken');
      const { userId } = JSON.parse(atob(token.split('.')[1]));
      const response = await axios.get('https://yeahtrips.in/bookedmembersdetails', {
        params: { userid: userId, tripid: trip.trip_id,booking_id:trip.booking_id }

      });
      if (response.data) {
        setMemberDetails(response.data.memberDetails);
        setDiscountAmount(response.data.discountAmount || 0);
      }
      const cancellationResponse = await axios.get('https://yeahtrips.in/getcancellationforbookedpolicies', {
        params: { tripid: trip.trip_id }
      });
      if (cancellationResponse.data && Array.isArray(cancellationResponse.data.data)) {
        setCancellationPolicies(cancellationResponse.data.data);
      } else {
        console.warn('Unexpected response format:', cancellationResponse.data);
        setCancellationPolicies([]);
      }


    } catch (error) {
      console.error('Error fetching member details:', error);
    }
  };
  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('likedTripIds');
    setIsLoggedIn(false);
    navigate('/');
  };

  const handleContactClick = () => {
    navigate('/', { state: { scrollToFooter: true } });
};

  const handleCancelClick = async (trip_id) => {
    const token = localStorage.getItem('accessToken');
    const { userId } = JSON.parse(atob(token.split('.')[1]));

    try {
      // Make the API request to remove the trip
      await axios.post('https://yeahtrips.in/api/removeTrip', {
        trip_id,
        userId,
      });

      // Remove the trip from the savedTrips state after a successful request
      setLikedTrips(likedTrips.filter(trip => trip.trip_id !== trip_id));

      // Retrieve and update the likedTripIds in localStorage
      const likedTripIds = JSON.parse(localStorage.getItem('likedTripIds')) || [];

      // Ensure trip_id is in string format if it was stored as a string
      const tripIdString = trip_id.toString();
      const updatedLikedTripIds = likedTripIds.filter(id => id !== tripIdString);


      // Save updated likedTripIds back to localStorage
      localStorage.setItem('likedTripIds', JSON.stringify(updatedLikedTripIds));

    } catch (error) {
      console.error('Error removing trip:', error);
    }
  };

  const handleViewDetailsClick = (slug) => {
    navigate(`/${slug}`);
  };
  useEffect(() => {
    const fetchTrips = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const { userId } = JSON.parse(atob(token.split('.')[1]));

        const likedResponse = await axios.get('https://yeahtrips.in/getlikedtrips', {
          params: { id: userId }
        });

        const upcomingResponse = await axios.get('https://yeahtrips.in/upcomingtrips', {
          params: { id: userId }
        });

        if (likedResponse.data.success) {
          setLikedTrips(likedResponse.data.likedTrips);
        }

        if (upcomingResponse.data.success) {
          setUpcomingTrips(upcomingResponse.data.upcomingTrips);
          setCompletedTrips(upcomingResponse.data.completedTrips);
        }
      } catch (error) {
        console.error('Error fetching trips:', error);
      }
    };

    fetchTrips();
  }, []);
  useEffect(() => {
    document.body.classList.toggle('no-scroll', isSidebarVisible);
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isSidebarVisible]);

  const downloadInvoice = async (trip_id) => {
    // Fetch trip details using trip_id
    const trip = completedTrips.find(t => t.trip_id === trip_id);

    if (!trip) {
      setMessage("Trip not found!");
      setIsError(true);
      return;
    }

    // Get the invoice element from the DOM
    const invoiceElement = document.getElementById(`invoice-${trip_id}`);
    if (!invoiceElement) {
      setMessage("Invoice element not found!");
      setIsError(true);
      return;
    }

    try {
      // Temporarily show the hidden invoice element
      invoiceElement.style.display = 'block';

      // Generate canvas from the invoice element
      const canvas = await html2canvas(invoiceElement, {
        scale: 2,
        useCORS: true,
        logging: true
      });

      // Convert the canvas to an image (JPEG format)
      const imgData = canvas.toDataURL("image/jpeg", 0.8);

      // Initialize jsPDF (A4 size: 210mm width, 297mm height)
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      // Add the image to the PDF (adjust the width and height)
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

      // Save the PDF with a filename that includes the trip name
      pdf.save(`Invoice_${trip.trip_name}.pdf`);

    } catch (error) {
      console.error("Error generating PDF:", error);
      setMessage("Failed to generate the invoice PDF.");
      setIsError(true);
    } finally {
      // Hide the invoice element again after PDF generation
      invoiceElement.style.display = 'none';
    }
  };

  const downloadInvoiceupcoming = async (trip_id) => {
    // Fetch trip details using trip_id
    const trip = upcomingTrips.find(t => t.trip_id === trip_id);

    if (!trip) {
      setMessage("Trip not found!");
      setIsError(true);
      return;
    }

    // Get the invoice element from the DOM
    const invoiceElement = document.getElementById(`invoice-${trip_id}`);
    if (!invoiceElement) {
      setMessage("Invoice element not found!");
      setIsError(true);
      return;
    }

    try {
      // Temporarily show the hidden invoice element
      invoiceElement.style.display = 'block';

      // Generate canvas from the invoice element
      const canvas = await html2canvas(invoiceElement, {
        scale: 2,
        useCORS: true,
        logging: true
      });

      // Convert the canvas to an image (JPEG format)
      const imgData = canvas.toDataURL("image/jpeg", 0.8);

      // Initialize jsPDF (A4 size: 210mm width, 297mm height)
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      // Add the image to the PDF (adjust the width and height)
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

      // Save the PDF with a filename that includes the trip name
      pdf.save(`Invoice_${trip.trip_name}.pdf`);

    } catch (error) {
      console.error("Error generating PDF:", error);
      setMessage("Failed to generate the invoice PDF.");
      setIsError(true);
    } finally {
      // Hide the invoice element again after PDF generation
      invoiceElement.style.display = 'none';
    }
  };

  return (
    <div>
      <Helmet>
        <title>YEAH - {selectedNav} Trips</title>
        <meta name="description" content={`Explore your ${selectedNav} trips with YEAHtrips. Find, save, and manage your travel plans easily.`} />
        <meta name="keywords" content={`YEAHtrips, ${selectedNav} trips, upcoming trips, saved trips, completed trips`} />
      </Helmet>

      <div className='landingpageimportedinsavedcontainer'>
        <Landingpage />

      </div>
      {isSidebarVisible && <div className='save-overlay'></div>}
      {isSidebarVisible && (


        <div className='save-sidebar'>
          <div className='triploggedinsavedsidebarnavbarmobile'>
            <Navbar />
          </div>
          <div className='save-topbar'>

            <div className='save-topbar_contents'>
              <h2>My Trips</h2>
              <div className='save-topbar-group'>
                <img src={Logout} alt="Logout" onClick={handleLogout} />
                <img src={cross} alt="Close" style={{ cursor: 'pointer' }} className='close' onClick={handleCloseClick} />
              </div>
            </div>
            <div className='tripbookeddetailsonlyforsmallscreens'>
              <h1>Checkout the trips you’ve booked so far.</h1>
            </div>
          </div>
          <div className='saved-sidebar-navigation'>
            <div className='saved-sidebar-navigation-group1'>
              <div
                className={`nav-font ${selectedNav === 'Upcoming' ? 'nav-font1' : ''}`}
                onClick={() => handleNavClick('Upcoming')}
              >
                <span className="nav-text">Upcoming</span>
              </div>
              <div
                className={`nav-font ${selectedNav === 'Saved' ? 'nav-font1' : ''}`}
                onClick={() => handleNavClick('Saved')}
              >
                <span className="nav-text">Saved</span>
              </div>
              <div
                className={`nav-font ${selectedNav === 'Completed' ? 'nav-font1' : ''}`}
                onClick={() => handleNavClick('Completed')}
              >
                <span className="nav-text">Completed</span>
              </div>
            </div>
            {selectedNav === 'Saved' && (
              <div className='saved-sidebar-navigation-group2-container'>
                {likedTrips.length === 0 ? (
                  <div className='no-saved-trips-message'>
                    <div className='heightadjustmentfornosaveiconlogincomponent'></div>
                    <div className='nosavediconsontainerlogincomponent'>
                      <img src={exclamatoryicon} alt="nothingsaved" />
                    </div>

                    <div className='nosaveditemmessagelogoncomponentcontainer'>
                      <div className='nosaveditenheadingcontainerlogoncomponent'>No Saved Trips!</div>
                      <div className='nosavetextdetailscontainerlogincomponents'>Explore our well-planned and curated trips and experience hidden gems and amazing friendships!</div>
                    </div>
                    <Link to='/futureescapes' className='nosaavedcontainerbuttonlogincomponent'>
                      <div>Explore Upcoming Trips</div>
                    </Link>
                  </div>
                ) : (
                  likedTrips.map((trip) => (
                    <div className='saved-sidebar-navigation-group2' key={trip.trip_id}>
                      <div className='saved-sidebar-navigation-group2-contents-container'>
                        <div className='saved-sidebar-group2-contents-image'>
                          <img src={`https://admin.yeahtrips.in${trip.file_path}`} alt="image" className="savedtrip-image" />
                        </div>
                        <div className='save-headinglikedtrips-container'>
                          <div className='save-heading'>
                            {trip.trip_name}
                          </div>
                          <span>
                            <img src={hearticon} alt="hearticon" className='hearticoninsaveditem' />
                          </span>
                        </div>                        <div className='save-subheading-container'>
                          <div className='head-sub'>
                            <div className='small'>Start Date</div>
                            <div className='large'>{trip.trip_start_date}</div>
                          </div>
                          <div className='head-sub'>
                            <div className='small'>End Date</div>
                            <div className='large'>{trip.end_date}</div>
                          </div>
                          <div className='head-sub'>
                            <div className='small'>Number of Seats</div>
                            <div className='large'>{trip.seats}</div>
                          </div>
                          <div className='head-sub'>
                            <div className='small'>Start Location</div>
                            <div className='large'>{trip.trip_start_point}</div>
                          </div>
                        </div>
                        <div className='saved-line'></div>
                        <div className='save-button-container'>
                          <button className='saved-cancel-button' onClick={() => handleCancelClick(trip.trip_id)}>Cancel</button>
                          <button className='saved-view-button' onClick={() => handleViewDetailsClick(trip.slug)} >View Details</button>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
            {selectedNav === 'Upcoming' && (
              <div className='saved-sidebar-navigation-group2-container'>
                {upcomingTrips.length === 0 ? (
                  <div className='no-saved-trips-message'>
                    <div className='heightadjustmentfornosaveiconlogincomponent'></div>
                    <div className='nosavediconsontainerlogincomponent'>
                      <img src={exclamatoryicon} alt="nothingsaved" />
                    </div>
                    <div className='nosaveditemmessagelogoncomponentcontainer'>
                      <div className='nosaveditenheadingcontainerlogoncomponent'>No Upcoming Trips!</div>
                      <div className='nosavetextdetailscontainerlogincomponents'>
                        Explore our well-planned and curated trips and experience hidden gems and amazing friendships!
                      </div>
                    </div>
                    <Link to='/futureescapes' className='nosaavedcontainerbuttonlogincomponent'>
                      <div>Explore Upcoming Trips</div>
                    </Link>
                  </div>
                ) : (
                  <div>
                    {upcomingTrips.map((trip) => (
                      <div className='saved-sidebar-navigation-group2' key={trip.trip_id}>
                        <div className='saved-sidebar-navigation-group2-contents-container'>
                          <div className='saved-sidebar-group2-contents-image'>
                            <img src={`https://admin.yeahtrips.in${trip.file_path}`} alt="image" className="savedtrip-image" />
                          </div>
                          <div className='save-heading'>{trip.trip_name}</div>
                          {trip.cancellation_status === 'pending' && (
                            <div className='cancellation-status  text-red-700  p-3 rounded-md mt-2 max-w-xs text-center'>
                              <span>Cancellation-Status: Pending</span>
                            </div>
                          )}

                          <div className='save-subheading-container'>
                            <div className='head-sub'>
                              <div className='small'>Start Date</div>
                              <div className='large'>{trip.trip_start_date}</div>
                            </div>
                            <div className='head-sub'>
                              <div className='small'>End Date</div>
                              <div className='large'>{trip.end_date}</div>
                            </div>
                            <div className='head-sub'>
                              <div className='small'>Number of Seats</div>
                              <div className='large'>{trip.seats}</div>
                            </div>
                            <div className='head-sub'>
                              <div className='small'>Start Location</div>
                              <div className='large'>{trip.trip_start_point}</div>
                            </div>
                          </div>
                          <div className='saved-line'></div>
                          {!detailsVisible[trip.booking_id] && (
                            <div className='save-button-container'>
                              <button className='upcoming-cancel-button' onClick={() => handleCancelTrip(trip.trip_name, trip.seats, trip.booking_id, trip.trip_id)}>Cancel</button>
                              <button className='upcoming-view-button' onClick={() => handleViewDetails(trip)}>View Details</button>
                            </div>
                          )}
                        </div>
                        {selectedTrip  && selectedTrip.booking_id === trip.booking_id && (
                          <div>
                            <div className='trip-details-section'>
                              <div className='tripdetailsloginbookingcontainers'>
                                <button className='trip-detailslogin-button' onClick={() => setDetailsVisible(!detailsVisible)}>
                                  <span className='textdetaillogin'>Trip Details</span>
                                  <img src={arrowdown} alt="Arrow" className={`arrowdetailogin-icon ${detailsVisible ? 'arrow-up' : ''}`} />
                                </button>
                              </div>
                              {detailsVisible && (
                                <div className='trip-details-content'>
                                  <div className='tripbookeddetailsmemberloginheading'>
                                    <h1>Member Details</h1>
                                  </div>
                                  {memberDetails.length > 0 ? (
                                    <div>
                                      {memberDetails.map((member, index) => (
                                        <div key={index}>
                                          <div>
                                            {index === 0 && <div>Member 1</div>}
                                            {index === 1 && <div>Member 2</div>}
                                            {index > 1 && <div>Member {index + 1}</div>}
                                          </div>
                                          <div className='tripbookeddetailslogoncontainer'>
                                            <div className='memberdetailsnamecontainerlogin'>
                                              <div className='tripbookeddetailsiconss'>
                                                <img src={profileicon} alt="Profile" />
                                              </div>
                                              <div>
                                                <div>
                                                  <p><strong>Full Name</strong></p>
                                                </div>
                                                <div>
                                                  <p className='tripbookednamememberslogin'>{member.fullname || 'N/A'}</p>
                                                </div>
                                              </div>
                                            </div>
                                            <div className='memberdetailsagecontainerlogin'>
                                              <div className='tripbookeddetailsiconss'>
                                                <img src={ageicon} alt="" />
                                              </div>
                                              <div>
                                                <div>
                                                  <p><strong>Age</strong></p>
                                                </div>
                                                <div>
                                                  <p>{member.age || 'N/A'} yrs</p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className='memberbookeddetailsphonenumberandmailcontainer'>
                                            <div>
                                              {member.phonenumber && (
                                                <div className='memberdetailsnamecontainerlogin'>
                                                  <div className='tripbookeddetailsiconss'>
                                                    <img src={phoneicon} alt="Phone" />
                                                  </div>
                                                  <div>
                                                    <div>
                                                      <p><strong>Phone Number</strong></p>
                                                    </div>
                                                    <div>
                                                      <p>{member.phonenumber}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                            <div >
                                              {member.email && (
                                                <div className='memberdetailsnamecontainerlogin'>
                                                  <div className='tripbookeddetailsiconss'>
                                                    <img src={mailicon} alt="Mail" />
                                                  </div>
                                                  <div className='tripmemberemailinfocontainerslogin'>
                                                    <div>
                                                      <p><strong>Mail</strong></p>
                                                    </div>
                                                    <div className='memberemailbookedcontainer'>
                                                      <p>{member.email}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          <div >
                                            <div className='tripmemberemailinfocontainerslogin'>
                                              {member.city && (
                                                <div className='memberdetailsnamecontainerlogin'>
                                                  <div className='tripbookeddetailsiconss'>
                                                    <img src={cityicon} alt="City" />
                                                  </div>
                                                  <div className='tripmemberemailinfocontainerslogin'>
                                                    <div>
                                                      <p><strong>Current City</strong></p>
                                                    </div>
                                                    <div className='memberemailbookedcontainer'>
                                                      <p>{member.city}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                            <div  >
                                              {member.member_state && (
                                                <div className='memberdetailsnamecontainerlogin'>
                                                  <div className='tripbookeddetailsiconss'>
                                                    <img src={stateicon} alt="State" />
                                                  </div>
                                                  <div className='tripmemberemailinfocontainerslogin'>
                                                    <div>
                                                      <p><strong>State</strong></p>
                                                    </div>
                                                    <div className='memberemailbookedcontainer'>
                                                      <p>{member.member_state}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  ) : (
                                    <p>No member details available.</p>
                                  )}
                                </div>
                              )}
                            </div>
                            <div className='trip-details-section'>
                              <div className='tripdetailsloginbookingcontainers'>
                                <button className='trip-detailslogin-button' onClick={() => setpaymentDetailsVisible(!detailspaymentVisible)}>
                                  <span className='textdetaillogin'>Payment Details</span>
                                  <img src={arrowdown} alt="Arrow" className={`arrowdetailogin-icon ${detailspaymentVisible ? 'arrow-up' : ''}`} />
                                </button>
                              </div>
                              {detailspaymentVisible && (
                                <div>
                                  <div className='trip-detailspayment-content'>
                                    <div className='trippaymentdivisionlogin'>
                                      <div className='trippaymentcostdivisionlogin'>
                                        <div className='tripdetailparagraghloginbooked'>
                                          <p>Package Cost (per person)</p>
                                        </div>
                                        <div className='tripdetailcosttextdivisiontextlogincontainer'>
                                          <p className='tripdetailcosttextdivisiontextlogin'>₹ {selectedTrip.cost}</p>
                                        </div>
                                      </div>
                                      <div className='trippaymentcostdivisionlogin'>
                                        <div className='tripdetailparagraghloginbooked'>
                                          <p>Total Members</p>
                                        </div>
                                        <div className='tripdetailcosttextdivisiontextlogincontainer'>
                                          <p className='tripdetailcosttextdivisiontextlogin'>{selectedTrip.seats}</p>
                                        </div>
                                      </div>
                                      <div className='trippaymentcostdivisionlogin'>
                                        <div className='tripdetailparagraghloginbooked'>
                                          <p>Total Cost</p>
                                        </div>
                                        <div className='tripdetailcosttextdivisiontextlogincontainer'>
                                          <p className='tripdetailcosttextdivisiontextlogin'>₹ {selectedTrip.cost * selectedTrip.seats}</p>
                                        </div>
                                      </div>
                                      <div className='trippaymentcostdivisionlogin'>
                                        <div className='tripdetailparagraghloginbooked'>
                                          <p>GST @5%</p>
                                        </div>
                                        <div className='tripdetailcosttextdivisiontextlogincontainer'>
                                          <p className='tripdetailcosttextdivisiontextlogin'>₹ {((selectedTrip.cost) * 5) / 100}</p>
                                        </div>
                                      </div>
                                      <div className='trippaymentcostdivisionlogin'>
                                        <div className='tripdetailparagraghloginbooked'>
                                          <p>Discount</p>
                                        </div>
                                        <div className='tripdetailcosttextdivisiontextlogincontainer'>
                                          <p className='tripdetailcosttextdivisiontextlogin'>
                                            - ₹ {discountAmount}
                                          </p>
                                        </div>
                                      </div>


                                      <div className='trippaymentcostdivisionlogin'>
                                        <div className='tripdetailparagraghloginbooked'>
                                          <p>Total  Amount Payable</p>
                                        </div>
                                        <div className='tripdetailcosttextdivisiontextlogincontainer'>
                                          <p className='tripdetailcosttextdivisiontextlogin'>  ₹ {selectedTrip.cost - discountAmount}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              )}
                            </div>
                            <div className='trip-details-section'>
                              <div className='tripdetailsloginbookingcontainers'>
                                <button
                                  className='trip-detailslogin-button'
                                  onClick={() => setcancellationDetailsVisible(!detailscancellationnVisible)}
                                >
                                  <span className='textdetaillogin'>Cancellation Policy</span>
                                  <img
                                    src={arrowdown}
                                    alt="Arrow"
                                    className={`arrowdetailogin-icon ${detailscancellationnVisible ? 'arrow-up' : ''}`}
                                  />
                                </button>
                              </div>
                              {detailscancellationnVisible && (
                                <div className='tripcancelationlogincontainerinfo'>
                                  <div className='tripcancelationlogintextsectioncontainer'>
                                    <div className='tripcancelationlogintextcontainerdetailsinformation'>
                                      <p>A participant will be refunded his/her paid fees after deducting the following cancellation fees.</p>
                                    </div>

                                    <div className='refundpolicttextsectiondetailedcontainer'>
                                      <div className='refundpolicttextheadingsectiondetailedcontainer'>
                                        <div className='refundupcomingtextheading'>
                                          <p>Name</p>
                                        </div>
                                        <div className='refundupcomingtripstextamountheading'>
                                          <p>Cancellation Fees</p>
                                        </div>
                                      </div>

                                      {/* Mapping cancellation policies */}
                                      {cancellationPolicies.map((policy, index) => (
                                        <div key={index} className='refundpolicttextheadingdetailedsectiondetailedcontainer'>
                                          <div className='refundupcomingdetailedtextheading'>
                                            {policy.dateRanges.map((item, dateIndex) => (
                                              <div className={`upcomingtripsrefundpolicylogintexts${dateIndex === policy.dateRanges.length - 1 ? ' last' : ''}`} key={item.date_range_id}>
                                                <p>
                                                  {item.end_date === 0
                                                    ? `Within ${item.start_date} days of camp start`
                                                    : `Before ${item.end_date} days of camp start`}
                                                </p>
                                              </div>
                                            ))}
                                          </div>
                                          <div className='refundupcomingtripstextdetailesamountheading'>
                                            {policy.dateRanges.map((item, dateIndex) => (
                                              <div className={`refundupcomingtripsdetailslogin${index === cancellationPolicies.length - 1 ? 'last' : ''}`} key={item.date_range_id}>
                                                <p>
                                                  {policy.feeType === 'percentage'
                                                    ? `₹${((selectedTrip.cost * parseFloat(item.fee)) / 100).toFixed(2)} (${item.fee}%)`
                                                    : `₹${item.fee}`}
                                                </p>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      ))}
                                    </div>

                                    <div className='sampletoudjustheightofupcomingtripsrefund'></div>

                                    <div className='tripupcomingrefundbulletedpoints'>
                                      <div className='triprefunddetailssamplepointstonoteslogin'>
                                        <div className='tripupcomingwidthudjustmentlogins'></div>
                                        <div className='tripupcomingbulletedpointts1login'>
                                          <div className='tripsampledotcontainerloginupcomingtripss'>.</div>
                                          <div className='tripupcomingtexttonotefoerefundpolicyinformation'>
                                            <p>Alternatively, a registered participant may transfer his/her registration to a friend without any cancellation fee.</p>
                                          </div>
                                        </div>
                                      </div>

                                      <div className='triprefunddetailssamplepointstonoteslogin'>
                                        <div className='tripupcomingwidthudjustmentlogins'></div>
                                        <div className='tripupcomingbulletedpointts1login'>
                                          <div className='tripsampledotcontainerloginupcomingtripss'>.</div>
                                          <div className='tripupcomingtexttonotefoerefundpolicyinformation'>
                                            <p>In case of cancellation of the program due to any government restrictions due to COVID/Weather, an alternate date will be provided for the same trip and no refund for that.</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>



                                  <div className='tripupcomingdetailsrefundbuttoncontainer'>
                                    {/* Render the Cancel Trip and Contact Us sections only once */}
                                    {upcomingTrips.length > 0 && (
                                      <div className='upcomingtripsheightudjustcancelationdetails'>
                                        <div
                                          className='tripupcomingcancelatindetailsbutton1container'
                                          onClick={() => handleCancelTrip(upcomingTrips[0].trip_name, upcomingTrips[0].seats, upcomingTrips[0].booking_id, upcomingTrips[0].trip_id)}
                                        >
                                          Cancel Trip
                                        </div>
                                        <div className='heightbetweenbuttonsadjustlogincomponentbuttons'></div>
                                        <div className='upcomingtripsheightudjustcancelationdetails'>
                                          <div className='tripupcomingcancelatindetailsbutton1container'  onClick={handleContactClick}>
                                            Contact Us
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>




                                  {upcomingTrips.length > 0 && (
                                    <div key={trip.trip_id}>
                                      <div className='complete-buttonupcomingtrips-container'>
                                        <button
                                          className='completedupcomingtrips-button'
                                          onClick={() => downloadInvoiceupcoming(upcomingTrips[0].trip_id)}
                                        >
                                          Download Invoice
                                        </button>
                                      </div>

                                      <div id={`invoice-${trip.trip_id}`} style={{
                                        display: 'none',
                                        padding: '20px',
                                        fontFamily: 'Arial, sans-serif',
                                        lineHeight: '1.5',
                                        border: '2px solid #3498db',
                                        borderRadius: '10px',
                                        background: 'linear-gradient(135deg, #f0f4f8 0%, #ffffff 100%)',
                                        width: '210mm',
                                        maxWidth: '100%',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                                      }}>
                                        <h1 style={{
                                          textAlign: 'center',
                                          fontSize: '26px',
                                          marginBottom: '20px',
                                          backgroundColor: '#3498db',
                                          padding: '10px',
                                          borderRadius: '8px',
                                          color: '#fff',
                                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
                                        }}>
                                          Trip Invoice
                                        </h1>

                                        <div style={{
                                          marginBottom: '10px',
                                          fontSize: '16px',
                                          color: '#2c3e50',
                                          padding: '8px',
                                          borderRadius: '5px',
                                          backgroundColor: '#ecf0f1',
                                          boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)'
                                        }}>
                                          <strong>Trip Name:</strong> {trip.trip_name}
                                        </div>

                                        <div style={{
                                          marginBottom: '10px',
                                          fontSize: '16px',
                                          color: '#2c3e50',
                                          padding: '8px',
                                          borderRadius: '5px',
                                          backgroundColor: '#ecf0f1',
                                          boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)'
                                        }}>
                                          <strong>Start Date:</strong> {trip.trip_start_date}
                                        </div>

                                        <div style={{
                                          marginBottom: '10px',
                                          fontSize: '16px',
                                          color: '#2c3e50',
                                          padding: '8px',
                                          borderRadius: '5px',
                                          backgroundColor: '#ecf0f1',
                                          boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)'
                                        }}>
                                          <strong>End Date:</strong> {trip.end_date}
                                        </div>

                                        <div style={{
                                          marginBottom: '10px',
                                          fontSize: '16px',
                                          color: '#2c3e50',
                                          padding: '8px',
                                          borderRadius: '5px',
                                          backgroundColor: '#ecf0f1',
                                          boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)'
                                        }}>
                                          <strong>Number of Seats:</strong> {trip.seats}
                                        </div>

                                        <div style={{
                                          marginBottom: '10px',
                                          fontSize: '16px',
                                          color: '#2c3e50',
                                          padding: '8px',
                                          borderRadius: '5px',
                                          backgroundColor: '#ecf0f1',
                                          boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)'
                                        }}>
                                          <strong>Start Location:</strong> {trip.trip_start_point}
                                        </div>

                                        <div style={{
                                          marginBottom: '10px',
                                          fontSize: '16px',
                                          color: '#2c3e50',
                                          padding: '8px',
                                          borderRadius: '5px',
                                          backgroundColor: '#ecf0f1',
                                          boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)'
                                        }}>
                                          <strong>Cost:</strong> {trip.cost}
                                        </div>

                                        <hr style={{
                                          border: 'none',
                                          borderTop: '2px solid #3498db',
                                          margin: '20px 0'
                                        }} />

                                        <h2 style={{
                                          textAlign: 'center',
                                          fontSize: '18px',
                                          marginBottom: '20px',
                                          color: '#3498db'
                                        }}>
                                          Payment Summary
                                        </h2>

                                        <div style={{
                                          marginBottom: '10px',
                                          fontSize: '16px',
                                          color: '#e74c3c'  // Red color for payment details
                                        }}>
                                          <strong>Paid:</strong> {(trip.cost) * (trip.seats)}
                                        </div>

                                        <hr style={{
                                          border: 'none',
                                          borderTop: '2px solid #3498db',
                                          margin: '20px 0'
                                        }} />

                                        <p style={{
                                          textAlign: 'center',
                                          fontSize: '12px',
                                          color: '#7f8c8d'  // Grey color for footer text
                                        }}>
                                          Thank you for traveling with us! If you have any questions, please contact support at letsgoforacamp@gmail.com.
                                        </p>
                                      </div>
                                    </div>

                                  )}






                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}

                  </div>
                )}
              </div>
            )}



            {selectedNav === 'Completed' && (
              <div className='saved-sidebar-navigation-group2-container'>
                {completedTrips.length === 0 ? (
                  <div className='no-saved-trips-message'>
                    <div className='heightadjustmentfornosaveiconlogincomponent'></div>
                    <div className='nosavediconsontainerlogincomponent'>
                      <img src={exclamatoryicon} alt="nothingsaved" />
                    </div>

                    <div className='nosaveditemmessagelogoncomponentcontainer'>
                      <div className='nosaveditenheadingcontainerlogoncomponent'>No Completed  Trips!</div>
                      <div className='nosavetextdetailscontainerlogincomponents'>Explore our well-planned and curated trips and experience hidden gems and amazing friendships!</div>
                    </div>
                    <Link to='/futureescapes' className='nosaavedcontainerbuttonlogincomponent'>
                      <div>Explore Upcoming Trips</div>
                    </Link>
                  </div>
                ) : (
                  completedTrips.map((trip) => (
                    <div className='saved-sidebar-navigation-group2' key={trip.trip_id}>
                      <div className='saved-sidebar-navigation-group2-contents-container'>
                        <div className='saved-sidebar-group2-contents-image'>
                          <img src={`https://admin.yeahtrips.in${trip.file_path}`} alt="image" className="savedtrip-image" />
                        </div>
                        <div className='save-heading'>{trip.trip_name}</div>
                        <div className='save-subheading-container'>
                          <div className='head-sub'>
                            <div className='small'>Start Date</div>
                            <div className='large'>{trip.trip_start_date}</div>
                          </div>
                          <div className='head-sub'>
                            <div className='small'>End Date</div>
                            <div className='large'>{trip.end_date}</div>
                          </div>
                          <div className='head-sub'>
                            <div className='small'>Number of Seats</div>
                            <div className='large'>{trip.seats}</div>
                          </div>
                          <div className='head-sub'>
                            <div className='small'>Start Location</div>
                            <div className='large'>{trip.trip_start_point}</div>
                          </div>
                        </div>
                        <div className='saved-line'></div>
                        <div className='complete-button-container'>
                          <button className='completed-button' onClick={() => downloadInvoice(trip.trip_id)}>Download Invoice</button>
                        </div>
                        <div id={`invoice-${trip.trip_id}`} style={{
                          display: 'none',
                          padding: '20px',
                          fontFamily: 'Arial, sans-serif',
                          lineHeight: '1.5',
                          border: '2px solid #3498db',
                          borderRadius: '10px',
                          background: 'linear-gradient(135deg, #f0f4f8 0%, #ffffff 100%)',
                          width: '210mm',
                          maxWidth: '100%',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                        }}>
                          <h1 style={{
                            textAlign: 'center',
                            fontSize: '26px',
                            marginBottom: '20px',
                            backgroundColor: '#3498db',
                            padding: '10px',
                            borderRadius: '8px',
                            color: '#fff',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
                          }}>
                            Trip Invoice
                          </h1>

                          <div style={{
                            marginBottom: '10px',
                            fontSize: '16px',
                            color: '#2c3e50',
                            padding: '8px',
                            borderRadius: '5px',
                            backgroundColor: '#ecf0f1',
                            boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)'
                          }}>
                            <strong>Trip Name:</strong> {trip.trip_name}
                          </div>

                          <div style={{
                            marginBottom: '10px',
                            fontSize: '16px',
                            color: '#2c3e50',
                            padding: '8px',
                            borderRadius: '5px',
                            backgroundColor: '#ecf0f1',
                            boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)'
                          }}>
                            <strong>Start Date:</strong> {trip.trip_start_date}
                          </div>

                          <div style={{
                            marginBottom: '10px',
                            fontSize: '16px',
                            color: '#2c3e50',
                            padding: '8px',
                            borderRadius: '5px',
                            backgroundColor: '#ecf0f1',
                            boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)'
                          }}>
                            <strong>End Date:</strong> {trip.end_date}
                          </div>

                          <div style={{
                            marginBottom: '10px',
                            fontSize: '16px',
                            color: '#2c3e50',
                            padding: '8px',
                            borderRadius: '5px',
                            backgroundColor: '#ecf0f1',
                            boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)'
                          }}>
                            <strong>Number of Seats:</strong> {trip.seats}
                          </div>

                          <div style={{
                            marginBottom: '10px',
                            fontSize: '16px',
                            color: '#2c3e50',
                            padding: '8px',
                            borderRadius: '5px',
                            backgroundColor: '#ecf0f1',
                            boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)'
                          }}>
                            <strong>Start Location:</strong> {trip.trip_start_point}
                          </div>

                          <div style={{
                            marginBottom: '10px',
                            fontSize: '16px',
                            color: '#2c3e50',
                            padding: '8px',
                            borderRadius: '5px',
                            backgroundColor: '#ecf0f1',
                            boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)'
                          }}>
                            <strong>Cost:</strong> {trip.cost}
                          </div>

                          <hr style={{
                            border: 'none',
                            borderTop: '2px solid #3498db',
                            margin: '20px 0'
                          }} />

                          <h2 style={{
                            textAlign: 'center',
                            fontSize: '18px',
                            marginBottom: '20px',
                            color: '#3498db'
                          }}>
                            Payment Summary
                          </h2>

                          <div style={{
                            marginBottom: '10px',
                            fontSize: '16px',
                            color: '#e74c3c'  // Red color for payment details
                          }}>
                            <strong>Paid:</strong> {(trip.cost) * (trip.seats)}
                          </div>

                          <hr style={{
                            border: 'none',
                            borderTop: '2px solid #3498db',
                            margin: '20px 0'
                          }} />

                          <p style={{
                            textAlign: 'center',
                            fontSize: '12px',
                            color: '#7f8c8d'  // Grey color for footer text
                          }}>
                            Thank you for traveling with us! If you have any questions, please contact support at letsgoforacamp@gmail.com.
                          </p>
                        </div>


                      </div>
                    </div>
                  ))
                )}
              </div>
            )}

          </div>
        </div>

      )
      }
      {showPopup && <div className="overlay"></div>}
      {showPopup &&
        <Cancel
          tripName={selectedTripName}
          seats={selectedSeats}
          userId={userId}
          bookingId={selectedbookingId}
          tripId={selectedtripId}
          onClose={handleClosePopup}
        />
      }
    </div >
  );
}

export default Saved;
