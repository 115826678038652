import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom"; import './Cancel.css'
import Cross2 from './Images/x-circle.svg'
import Alert from './Images/Modal Alert Icons.svg'
import Saved from '../Saved-Upcoming-Completed/Saved';
import Landingpage from '../homepage/Landinhpage/Landingpage';
import axios from 'axios';


function Cancel({ tripName, onClose, seats, userId, tripId,bookingId }) {
  const [reason, setReason] = useState('');
  const [message, setMessage] = useState('');  
  const [isError, setIsError] = useState(false);
  useEffect(() => {
  },)

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleCancel = () => {
    setReason('');
    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = async () => {
    if (!reason.trim()) {
      setMessage('Please provide a reason for cancellation.');
      setIsError(true);
       return;
    }

    try {
      const response = await axios.post('https://yeahtrips.in/cancellations', {
        trip_id: tripId,
        user_id: userId,
        booking_id:bookingId,
        reason: reason
      });

      setMessage('Cancellation request submitted successfully.');
      setIsError(false);
      setReason('');
      if (onClose) {
        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error submitting cancellation request:', error);
      setMessage('Failed to submit cancellation request. Please try again.');
      setIsError(true);    }
  };
  return (
    <div>
      <div className='cancel'>
        <div className='tripcancelcrossimagecontainer'><img src={Cross2} onClick={handleCancel} /></div>
        <img src={Alert} />
        <h1>Cancel Trip?</h1>
        <div className='tripnameinthecancellationcontainer'>Want to {tripName} you booked for {seats} people?<br /> Our team will reach out to you for further cancellation.</div>
        <div>
          <div className='reasonforcancelationcontainer'>Reason for cancellation</div>
          <input type="text" placeholder='Please enter the reason for cancellation' className='input2' onChange={handleReasonChange}/>
        </div>

        {message && (
          <div className={`message ${isError ? 'error' : 'success'}`}>
            {message}
          </div>
        )}


        <div className='buttons'>
          <button className='button1' onClick={handleCancel}>Cancel</button>
          <button className='button2' onClick={handleSubmit}>Submit Cancellation Request</button>
        </div>
      </div>
    </div>
  )
}

export default Cancel
