import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../homepage/Navbar/Navbar';
const TermsAndConditions = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>YEAH - Terms & Conditions</title>
        <meta name="description" content="Read the Terms & Conditions for using YEAHtrips services. Understand the rules, responsibilities, and agreements for participating in trips, bookings, and community activities." />
        <meta name="keywords" content="Terms and Conditions, YEAHtrips, travel terms, user agreement, trip policies, travel bookings, community rules, YEAHtrips services" />
        <meta property="og:title" content="YEAH - Terms & Conditions" />
        <meta property="og:description" content="Learn about the Terms & Conditions for using YEAHtrips services, including trip bookings, community guidelines, and user responsibilities." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://yeahtrips.in/termsandconditions" />
      </Helmet>

      <div>
        <Navbar />
      </div>
      <div className="container mx-auto p-8 max-w-3xl bg-white shadow-lg rounded-lg">


        <h1 className="text-4xl font-bold mb-4 text-center text-blue-600">Terms & Conditions</h1>
        <p className="mb-4 text-gray-600 text-center">Effective Date: 4-Oct-2024</p>
        <p className="mb-4 text-gray-800">
          Welcome to <strong>YeahTrips.in</strong>, an online platform provided by LetsGoForACamp Travel and Innovation LLP (referred to as "we," "us," or "our"). By accessing or using our website, you agree to comply with and be bound by these Terms & Conditions. Please read the following carefully, as they govern your use of YeahTrips.in and the services provided.
        </p>

        <h2 className="text-3xl font-semibold mt-6 mb-2 text-blue-500">1. Acceptance of Terms</h2>
        <p className="mb-4 text-gray-800">
          By using YeahTrips.in, you acknowledge that you have read, understood, and agree to be bound by these Terms & Conditions, as well as any additional terms and policies referenced herein. If you do not agree, please do not use our website or services.
        </p>

        <h2 className="text-3xl font-semibold mt-6 mb-2 text-blue-500">2. Services Provided</h2>
        <p className="mb-4 text-gray-800">
          YeahTrips.in is an online platform that facilitates the booking of trips, tours, and travel-related services. All bookings are subject to availability and confirmation from our third-party service providers (e.g., tour operators, accommodation providers).
        </p>

        <h2 className="text-3xl font-semibold mt-6 mb-2 text-blue-500">3. User Responsibilities</h2>
        <p className="mb-4 text-gray-800">When using our website, you agree to:</p>
        <ul className="list-disc list-inside mb-4 text-gray-800">
          <li>Provide accurate and up-to-date personal information.</li>
          <li>Use the platform for lawful purposes and not engage in any fraudulent or malicious activities.</li>
          <li>Not attempt to access restricted areas of the website or disrupt the website's functionality.</li>
          <li>You are responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account.</li>
        </ul>

        <h2 className="text-3xl font-semibold mt-6 mb-2 text-blue-500">4. Trip Bookings</h2>
        <p className="mb-4 text-gray-800">
          <strong>Booking Confirmation:</strong> All bookings made through YeahTrips.in are subject to confirmation by the relevant service providers. We will notify you via email once your booking is confirmed.
        </p>
        <p className="mb-4 text-gray-800">
          <strong>Payment:</strong> Payments must be made in full at the time of booking unless otherwise stated. Failure to pay may result in the cancellation of your booking.
        </p>
        <p className="mb-4 text-gray-800">
          <strong>Cancellation & Refunds:</strong> Cancellation policies vary by trip and are outlined at the time of booking. Please review the specific terms for each trip before making a reservation. Refunds are subject to the cancellation policy of the respective trip.
        </p>

        <h2 className="text-3xl font-semibold mt-6 mb-2 text-blue-500">5. Pricing</h2>
        <p className="mb-4 text-gray-800">
          All prices listed on YeahTrips.in are in Indian Rupee and are subject to change without notice. We make every effort to ensure that the prices listed are accurate; however, in the event of an error, we reserve the right to correct any inaccuracies and notify you of the revised pricing.
        </p>

        <h2 className="text-3xl font-semibold mt-6 mb-2 text-blue-500">6. Travel Requirements</h2>
        <p className="mb-4 text-gray-800">
          You are responsible for ensuring that you meet all travel requirements, including but not limited to valid identification, visas, vaccinations, and insurance. YeahTrips.in and LetsGoForACamp Travel and Innovation LLP are not liable for any costs incurred due to failure to meet such requirements.
        </p>

        <h2 className="text-3xl font-semibold mt-6 mb-2 text-blue-500">7. Intellectual Property</h2>
        <p className="mb-4 text-gray-800">
          All content on YeahTrips.in, including but not limited to text, images, graphics, logos, and design, is the intellectual property of LetsGoForACamp Travel and Innovation LLP. You may not copy, distribute, or reproduce any content from this website without our express written permission.
        </p>

        <h2 className="text-3xl font-semibold mt-6 mb-2 text-blue-500">8. Limitation of Liability</h2>
        <p className="mb-4 text-gray-800">
          We strive to provide accurate information on YeahTrips.in; however, we do not guarantee that the website will be error-free or uninterrupted. To the fullest extent permitted by law, LetsGoForACamp Travel and Innovation LLP shall not be liable for any direct, indirect, incidental, or consequential damages arising out of your use of our website or services.
        </p>

        <h2 className="text-3xl font-semibold mt-6 mb-2 text-blue-500">9. Third-Party Links</h2>
        <p className="mb-4 text-gray-800">
          YeahTrips.in may contain links to third-party websites or services. We are not responsible for the content, privacy policies, or practices of any third-party websites. Accessing these links is at your own risk, and we encourage you to review their terms and conditions.
        </p>

        <h2 className="text-3xl font-semibold mt-6 mb-2 text-blue-500">10. Termination</h2>
        <p className="mb-4 text-gray-800">
          We reserve the right to suspend or terminate your access to YeahTrips.in at any time, without notice, if you violate these Terms & Conditions or engage in unlawful activity.
        </p>

        <h2 className="text-3xl font-semibold mt-6 mb-2 text-blue-500">11. Modifications to Terms</h2>
        <p className="mb-4 text-gray-800">
          We may revise these Terms & Conditions from time to time by updating this page. Your continued use of the website after any changes are made constitutes your acceptance of the updated Terms & Conditions.
        </p>

        <h2 className="text-3xl font-semibold mt-6 mb-2 text-blue-500">12. Governing Law</h2>
        <p className="mb-4 text-gray-800">
          These Terms & Conditions are governed by and construed in accordance with the laws of the Republic of India, without regard to its conflict of law principles. Any disputes arising out of or relating to these Terms shall be resolved exclusively in the courts of Ernakulam, Kerala.
        </p>

        <h2 className="text-3xl font-semibold mt-6 mb-2 text-blue-500">13. Contact Information</h2>
        <p className="mb-4 text-gray-800">
          If you have any questions or concerns about these Terms & Conditions, please contact us at:
        </p>
        <p className="mb-4 text-gray-800">
          <strong>LetsGoForACamp Travel and Innovation LLP</strong><br />
          Chitunnill (H), Muppathadam PO, Aluva - 683110<br />
          Email: <a href="mailto:letsgoforacamp@gmail.com" className="text-blue-500 underline">letsgoforacamp@gmail.com</a><br />
          Phone: <span className="text-blue-500">+91 7829230956</span>
        </p>
      </div>
    </div>

  );
};

export default TermsAndConditions;
