import React, { useState, useEffect } from "react";
import axios from 'axios';
import cross from './images/cross.svg';
import background1 from './images/background.svg';
import background2 from './images/backgroundonright.svg';
import './Discount.css';

function Discount({ totalcost, isVisible, onClose, onApplyCoupon }) {
    const [couponCode, setCouponCode] = useState("");
    const [errorMessage, setErrorMessage] = useState(""); // State for error messages
    const [successMessage, setSuccessMessage] = useState(""); // State for success messages
 
    useEffect(() => {
        document.body.classList.toggle('no-scroll', isVisible);
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [isVisible]);

    if (!isVisible) {
        return null;
    }

    const handleCouponApply = async () => {
        setErrorMessage(""); // Clear previous error messages
        setSuccessMessage(""); // Clear previous success messages
        
        try {
            const response = await axios.get(`https://yeahtrips.in/coupons/${couponCode}`);
            const coupon = response.data.coupon;
            const max_amount=coupon.max_amount;
            const min_amount=coupon.min_amount;
            if (totalcost < min_amount || totalcost > max_amount) {
                setErrorMessage(`Coupon can only be applied for amounts between ₹${min_amount} and ₹${max_amount}.`);
                return; 
            }
            onApplyCoupon(coupon);
            onClose();
        } catch (error) {
            console.error("Error applying coupon:", error);
            setErrorMessage("Failed to apply coupon. Please check the code."); // Display error message
        }
    };

    return (
        <div>
            <div className="overlay"></div>
            <div className="discountpopupcontainer">
                <div className="onboardcross" onClick={onClose}>
                    <img src={cross} alt="close icon" />
                </div>
                <div className="oncontainerdiscount">
                    <div className="boardtextdiscount">
                        <div className="onboardTextdiscount">
                            <h6>Apply Coupon</h6>
                            <p>Your Total Amount: ₹ {totalcost}</p>
                        </div>

                        <div className="tripdiscountcoupondetailscontainer">
                            <div className="tripdiscountcontenttext">
                                <p>Coupon Code</p>
                            </div>
                            <div className="discountcomponentinputtextcontainer">
                                <input
                                    type="text"
                                    className="discountcouponcodetextinput"
                                    placeholder="Enter Coupon code"
                                    value={couponCode}
                                    onChange={(e) => setCouponCode(e.target.value)}
                                />
                            </div>
                            <div className="tripdiscountinputundertextcontainer">
                                <p>Only one promo code can be applied per trip. If multiple codes are added, only the last one will apply.</p>
                            </div>
                            {errorMessage && <p className="error-message">{errorMessage}</p>} 
                            {successMessage && <p className="success-message">{successMessage}</p>} 
                        </div>

                        <div className="onboardbuttondiscount"  onClick={handleCouponApply}>
                            <button style={{ color: 'white' }} >
                                <span>Apply Coupon Code</span>
                            </button>
                        </div>
                    </div>
                    <div className="popupdiscountbackgroundcontainer">
                        <img src={background2} alt="" />
                    </div>
                    <div className="popupdiscountbackgroundcontainer2">
                        <img src={background1} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Discount;
