import React, { useEffect } from "react";
import alerticon from './images/onboarderror.svg';
import { Link } from "react-router-dom";
import cross from './images/cross.svg';
import './likepopuperror.css';

function Onboardlikepoperror({ isVisible, onClose }) {

    useEffect(() => {
        if (isVisible) {
            document.body.style.overflow = 'hidden'; // Prevent scrolling when the popup is visible
        } else {
            document.body.style.overflow = 'auto'; // Enable scrolling when the popup is not visible
        }

        return () => {
            document.body.style.overflow = 'auto'; // Clean up when the component is unmounted
        };
    }, [isVisible]);

    if (!isVisible) {
        return null; // Return null to not render the component when it's not visible
    }

    return (
        <div>
            <div className="overlaylike"></div>
            <div className="savetripcontainerpopupdetailtrip">
                <div className="onboarderrorhomepage-containerdetailtrip">
                    <div className="onboarderrorcrossdetailtrip">
                        <img src={cross} alt="closeicon" onClick={onClose} />
                    </div>
                    <div className="onerrorcontainerdetailtrip">
                        <div className="boarderrortextdetailtrip">
                            <div className="boarderrorimagedetailtrip">
                                <img src={alerticon} alt="successalert icon" className="alerticonforlikepopup" />
                            </div>
                            <div className="onboarderrorTextdetailtrip">
                                <h6>Like the trip? Please log In save it!</h6>
                                <p>Let'sGoForACamp is where amazing family <br />
                                    memories are made.</p>
                            </div>
                            <div className="onboarderrorbuttondetailtrip">
                                <div className="onboarderrorbutton1detailtrip">
                                    <Link to='/login'><button>Log In To YEAH</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Onboardlikepoperror;
