import React from "react";
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import yeahicon from './images/yeahicon.svg';
import thunder from './images/zap.png';
import logocamp from './images/logocamp.png';
import instagram from './images/instagram.png';
import linkedin from './images/linkedin.png';
import snapchat from './images/snapchat.png';
import x from './images/x.png';
import './Footer.css';




function Footer({ onContactUsClick }) {
    const navigate = useNavigate();


    const handleMyTripsClick = () => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            navigate('/logincomponent');
        } else {
            navigate('/login');
        }
    };
    const handleClickState = () => {
        return { filterss: 'filterss' };
    };

    return (
        <div className="Footer">
            <div className="footer-container">
                <div className="element">
                    <img src={thunder} alt="Thunder icon" className="footerthundericon" />
                    <p>An initiative by experienced folks of </p>
                    <img src={logocamp} alt="Logo camp" className="goforacamplogofooter" />
                </div>
                <div className="footer-section">
                    <img src={yeahicon} alt="Yeah icon" />
                    <div className="footer-list">
                        <ul>
                            <li><Link to="/futureescapes">Future Escapes</Link></li>

                            <li>
                                <Link to="/futureescapes" state={handleClickState()}>
                                    Destinations
                                </Link>
                            </li>
                            <li> <Link to='/blogs'>Blogs</Link> </li>
                            <li><Link to="/login">Sign up/Log In</Link></li>
                            <li><button onClick={handleMyTripsClick}>My Trips</button></li>
                            <li><button onClick={onContactUsClick}>Contact Us</button></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="linefooterhomepage"></div>
            <div className="footer-social">
                <div className="footer-text">
                    <p>© 2024 YEAH. All rights reserved.</p>
                </div>
                <div className="footer_images">
                    <a href=" https://www.instagram.com/yeah__trips/" target="_blank" rel="noopener noreferrer">
                        <img src={instagram} alt="Instagram" />
                    </a>
                    <a href="https://www.snapchat.com/add/yeahtrips.in" target="_blank" rel="noopener noreferrer">
                        <img src={snapchat} alt="Snapchat" />
                    </a>
                    <a href=" https://x.com/LetsGoForACamp/" target="_blank" rel="noopener noreferrer">
                        <img src={x} alt="Twitter" />
                    </a>
                    <a href=" https://in.linkedin.com/company/lets-go-for-a-camp" target="_blank" rel="noopener noreferrer">
                        <img src={linkedin} alt="LinkedIn" />
                    </a>
                </div>
                <div className="footer_policy">
                    <p> <Link to='/privacy-policies'>Privacy Policy</Link> </p>
                    <p>|</p>
                    <p> <Link to='/termsandconditions'>Terms & Conditions</Link></p>
                </div>

            </div>
        </div>
    );
}

export default Footer;
