import React from 'react'
import './Loading.css'
import loading from './Images/loading-02.svg'
import Login from '../Login/Login'
function Loading() {
  return (
    <div>
      <div className='sidebarloadingotpscreen'>
      <Login/>

      </div>
      <div className='overlayloadingcomponent'></div>
      <div className='loading'>
        <div className='image-container'>
          <img src={loading} alt="loadingimage" />

        </div>
        <div className='text-container'>
          <div  className='verifyingemailtextloading'><b>Verifying Email ID and Phone No</b></div>
          <div  className='donotclosetextloadingcomponent'>Dont close the window while we verify your<br />details!</div>
        </div>
      </div>
    </div>
  )
}

export default Loading
