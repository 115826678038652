import React from "react";
import './Setusapart.css'
import transperency from './images/transperancyimage.png'
import safetyicon from './images/apart.svg';
import secimg from './images/secimag.png';
import aparticon from './images/card.svg';
import aparticon1 from './images/card1.svg';
import icon1 from './images/icon.svg';
import sticker1 from './images/sticker1.svg'
import sticker2 from './images/sticker2.svg';
import star from './images/Star.svg';
import newimage from './images/newicon.png'
import backgroundframe from './images/backgrounfframe.png';
import icon2 from './images/icon2.png';
import cotravellersbackground from './images/cotravellersbackground.svg';
import cotravellersbackground3 from './images/setusapartbackground3.svg';
import stargreen from './images/Stargreen.svg';
import heart from './images/heart.svg';
function Setusapart() {
  return (
    <div className="setusapart-container">
      <div className="setusaparttextheihtadjust"></div>
      <div className="setusapart_text">
        <h1>What Sets Us Apart? 🎯</h1>
      </div>

      <div className="setusapart_element">

        <div className="setusapartelemetrow1">
          <div className="setusapart_element1">
            <div className="setusapart_element1text">
              <div className="setusapart_element1heading">
                <h1>Safety Ensured</h1>
              </div>
              <div className="setusapart_element1detail">
                <p>Completely safe and secure locations along with our expert tour guides, both men and women to ensure ease of comfort!</p>
              </div>
               <div className="setusapartelement1img">
              <img src={backgroundframe} alt="background" className="bottomimage" />
              <img src={safetyicon} alt="safety" className="topimage" />
            </div>
            </div>
           
          </div>

          <div className="setusapart_element2">
            <div className="setusapart_element1text">
              <div className="setusapart_element2heading">
                <h1>No Third-Party Interference</h1>
              </div>
              <div className="setusapart_element2detail">
                <p>100% in house operations for all trips! No 3rd party involved so no fishy claims!</p>
              </div>
            </div>

            <div className="hpmepageapartheightusjusrapart">
              <div className="setusapartelement2img">
                <div className="sampleinhomepageapart">
                  <div className="setusapartelement2img1">
                    <div className="setusimg">
                      <img src={icon1} alt="" />
                    </div>
                    <div className="setusimgtext">
                      <div className="setusimgtextheading">
                        <p>5K+</p>
                      </div>
                      <div className="setusimgtextdetail">
                        <p>Happy Travellers</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="starriconhomepageapart">
                  <img src={star} alt="star" className="setusapartstar" />
                </div>

              </div>
            </div>



            <div className="setusapartelement2img2">

              <div className="starriconhomepageapart2" >
                <img src={star} alt="star" className="setusapartstar2" />
              </div>

              <div className="sampleinhomepageapart">
                <div className="setusapartelement3">
                  <div className="setusimg">
                    <img src={newimage} alt="" />
                  </div>
                  <div className="setusimgtext">
                    <div className="setusimgtextheading">
                      <p>350+</p>
                    </div>
                    <div className="setusimgtextdetail">
                      <p>Personalised Trips</p>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>

          <div className="setusapartelement4">
            <div className="setusapart_element1text">
              <div className="setusapart_element2heading">
                <h1>Transparency and Security</h1>
              </div>
              <div className="setusapart_element2detail">
                <p>Real time monitoring of all trips by ground team! All routes and weather conditions are accurately updated!</p>
              </div>
              <div className="transperancyimgcontainer">
                <img src={transperency} alt="#" />
              </div>
            </div>
          </div>
        </div>
        <div className="setusapartelementrow2">
          <div className="setusapartelement5">
            <div className="setusapart_element5text">
              <div className="setusapart_element5heading">
                <h1>One Stop Trip Booking Experience</h1>
              </div>
              <div className="setusapart_element5detail">
                <p>Comfortable stays, trained drivers, hospitable staff and friendly trip leaders but together that 1 memorable trip for you!</p>
              </div>

            </div>
            <div className="setusapartimagess">
              <div className="setusapartelemeent5coloums">
                <div className="samplesetusapartimagewidthudjustcontainer">
                  <div className="setusapartelement5_image1">
                    <img src={aparticon} alt="icon1" />
                  </div>
                  <div className="setusapart5element5_text">
                    <div className="setusapartgapfirtextinelement5"></div>
                    <p>9 Days Leh Ladakh to Srinagar Road  Trip With Kargil  💖</p>
                  </div>
                </div>

                <div className="tripseatleftsandhearticoncontainersetusapartlandingpage">
                  <div className="seatsleftinsetusapartlandingpage">
                    <p>Only 11 Seats Left</p>
                  </div>
                  <div  className="setusapartwidthuajustforseatsleftandheart"></div>
                  <div className="heartcontainersetusapartlandingpade">
                        <img src={heart} alt="hearticons" />
                  </div>
                </div>

              </div>

              <div>
                <div className="setupapartlocationheightudjust">
                  <div className="setusaparthomepagesampletomovelocation"></div>

                  <div className="setusapartelement4smalllocation">
                    <div className="setusaprtimagewidthudjusthomepage">
                      <div className="setusaparthomepagebeachicon">
                        <img src={icon2} alt="" />
                      </div>
                    </div>

                    <div className="setusaprtlocationstextbeach">
                      <p>Unique Locations</p>
                    </div>
                  </div>
                </div>

                <div className="setusapartelement5_image2">

                  <div>

                  <div className="setusaprtelemt5image2coloums">
                    <img src={aparticon1} alt="#" />
                  </div>
                  <div className="setusapart5element5_text1">
                    <p>Valley of Flowers Trek- Uttarakhand</p>
                  </div>

                  </div>
                 

                  <div className="tripseatleftsandhearticoncontainersetusapartelement5landingpage">
                  <div className="seatsleftinsetusapartlandingpage">
                    <p>Only 11 Seats Left</p>
                  </div>
                  <div  className="setusapartwidthuajustforseatsleftandheart"></div>
                  <div className="heartcontainersetusapartlandingpade">
                        <img src={heart} alt="hearticons" />
                  </div>
                </div>
                </div>
              </div>



            </div>



          </div>

          <div className="setusapartelement6container">
            <div className="setusapart_element6text">
              <div className="setusapart_element6heading">
                <h1>Co-Travellers Screening</h1>
              </div>
              <div className="setusapart_element6detail">
                <p>Multi-step filtering to bring only like-minded people together! That’s our key to have fuss-free trips!.</p>
              </div>
            </div>
            <div className="setusapartstargreen2">
              <img src={stargreen} alt="" />

            </div>
            <div className="image-containersetusapart">
              <div className="main-imagessetusapart">
                <img src={cotravellersbackground} alt="" className="setusapartimage2cotravellers1" />
                <img src={cotravellersbackground} alt="" className="setusapartimage2cotravellers2" />
                <img src={cotravellersbackground3} alt="" className="setusapartimage2cotravellers3" />
                <img src={sticker1} alt="sticker1" className="overlay-imagessetusapart1" />
                <img src={sticker2} alt="sticker2"  className="overlay-imagessetusapart2"/>
                <img src={stargreen} alt="" className="setusapartstargreen" />

              </div>
              
            </div>

            <div>

            </div>

          </div>
        </div>




      </div>

    </div>

  );
}

export default Setusapart;
