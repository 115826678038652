import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from '../homepage/Navbar/Navbar';
import { Helmet } from 'react-helmet';

const Blogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get('https://yeahtrips.in/getblogs');
                setBlogs(response.data);
            } catch (err) {
                setError('Error fetching blogs');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    if (loading) {
        return <div className="text-center mt-4">Loading...</div>;
    }

    if (error) {
        return <div className="text-red-500 text-center mt-4">{error}</div>;
    }

    return (
        <div>
            <Helmet>
                <title>YEAH - Blogs</title>
                <meta name="description" content="Explore the latest blogs from YEAHtrips. Get insights, tips, and travel stories to inspire your next adventure." />
                <meta name="keywords" content="YEAHtrips, blogs, travel blogs, travel tips, adventure stories, vacation inspiration, travel guides, YEAH blogs" />
                <meta property="og:title" content="YEAH - Blogs" />
                <meta property="og:description" content="Discover inspiring travel stories, guides, and tips on YEAHtrips' blog. Stay updated with the latest travel insights." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://yeahtrips.in/blogs" />
            </Helmet>

            <div>
                <Navbar />
            </div>
            <div className="container mx-auto p-4">
                <h2 className="text-3xl font-bold text-center mb-8">Blogs</h2>

                {blogs.length === 0 ? (
                    <div className="text-center text-gray-500">No blogs to display</div>
                ) : (
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
                        {blogs.map(blog => (
                            <div key={blog.id} className="bg-white shadow-md rounded-lg p-4">
                                <img
                                    src={`https://admin.yeahtrips.in${blog.image}`}
                                    alt={blog.title.replace(/<[^>]+>/g, '')}
                                    className="w-full h-48 object-cover rounded-md mb-4"
                                />
                                <h3 className="text-xl font-semibold mb-2" dangerouslySetInnerHTML={{ __html: blog.title }}></h3>
                                <a
                                    href={`/blogs/${blog.slug}`}
                                    className="text-blue-500 hover:underline"
                                >
                                    Read more
                                </a>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Blogs;
