import React from "react";
import alerticon from './images/onboarderror.svg';
import { Link } from "react-router-dom";
import cross from './images/cross.svg';
import './liketripdetailssave.css';

function Onboardliketripdetailpoperror({ isVisible, onClose }) {
    if (!isVisible) {
        return null; // Return null to not render the component when it's not visible
    }

    return (
        <div>
            <div className="overlay" />
            <div className="savetripcontainerpopupdetailtrip">
                <div className="onboarderror-containerdetailtrip">
                    <div className="onboarderrorcrossdetailtrip">
                        <img src={cross} alt="close icon" onClick={onClose} />
                    </div>
                    <div className="onerrorcontainerdetailtrip">
                        <div className="boarderrortextdetailtrip">
                            <div className="boarderrorimagedetailtrip">
                                <img src={alerticon} alt="error icon" className="alerticonforlikepopup" />
                            </div>
                            <div className="onboarderrorTextdetailtrip">
                                <h6>Like the trip? Please log in to save it!</h6>
                                <p>Let's Go For A Camp is where amazing family memories are made.</p>
                            </div>
                            <div className="onboarderrorbuttondetailtrip">
                                <div className="onboarderrorbutton1detailtrip">
                                    <Link to='/login'>
                                        <button>Log In To YEAH</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Onboardliketripdetailpoperror;
