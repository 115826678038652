import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import Funnel from './Images/filter-funnel-02.svg';
import './Future.css';
import Calendar from './Images/calendar-date.svg';
import Arrow from './Images/arrow-right.svg';
import Location from './Images/Icon.svg';
import X from './Images/x-close.svg';
import Dropdown from '../dropdown/Dropdown';
import Arrowleft from './Images/arrow-left.svg';
import Arrowright from './Images/rightarrow.svg';
import Navbar from '../homepage/Navbar/Navbar';
import Getintouch from '../homepage/getintouch/Getintouch';
import Footer from '../homepage/Footer/Footer';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { format, startOfMonth, endOfYear, isAfter, isBefore } from 'date-fns';
import thundericon from './Images/zap.svg';
import logocamp from './Images/logocamp.svg';
import Onboarderror from '../community/onboard/Onboarderror';
import search from './Images/Icon.svg'
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
function Future() {
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDestinations, setSelectedDestinations] = useState([]);
  const [selectedTripTypes, setSelectedTripTypes] = useState([]);
  const [selectedSortBy, setSelectedSortBy] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [visible, setVisible] = useState(false);
  const [dropdownData, setDropdownData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const [likedItems, setLikedItems] = useState({});
  const [isDateVisible, setIsDateVisible] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const navigate = useNavigate();
  const [likedTripIds, setLikedTripIds] = useState([]);
  const uniqueDestinations = [...new Set(datas.map(item => item.destination))];
  const uniqueTripTypes = [...new Set(datas.map(item => item.trip_type))];
  const uniqueSortBy = [...new Set(datas.map(item => item.seat_type))];
  const [showPopup, setShowPopup] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [originalData, setOriginalData] = useState([]);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const location = useLocation();
  const filterss = location.state?.filterss;
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const response = await axios.get('https://yeahtrips.in/future/escapes');
        const today = new Date(); // Current date
  
        const filteredTrips = response.data.filter((trip) => {
          // Clean the trip_start_date by removing ordinal suffixes
          const cleanedDateString = trip.trip_start_date.replace(/(\d+)(st|nd|rd|th)/g, '$1');
          const tripStartDate = new Date(cleanedDateString); // Create a Date object
  
          // Check if the trip is published and the start date is today or in the past
          return trip.status === 'published' && tripStartDate && tripStartDate >= today;
        });
  
        setDatas(response.data); // Assuming this sets the original fetched data
        setOriginalData(filteredTrips); // Sets the filtered trips
        setFilteredData(filteredTrips); // Sets the filtered trips for display
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.response ? error.response.data : error.message || 'Error fetching data');
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  

  useEffect(() => {
    if (filterss) {
      // Move the onClick outside or ensure it's not causing multiple renders
      onClick();  // Consider whether this needs to be here or move to an event handler

      // Avoid recalculating `uniqueDestinations` if datas hasn't changed
      // const uniqueDestinations = [...new Set(datas.map(item => item.destination))];

      // if (uniqueDestinations.length > 0) {
      //     const dataToPass = uniqueDestinations.map(option => ({ group: "Destination", option }));

      //     setDropdownData(dataToPass);
      //     setActiveDropdown("destination");  
      // }
    }
  }, [filterss]);  // Depend only on filterss and datas




  // useEffect(() => {
  //   const uniqueDestinations = [...new Set(datas.map(item => item.destination))];

  //   if (uniqueDestinations.length > 0) {
  //       const dataToPass = uniqueDestinations.map(option => ({ group: "Destination", option }));
  //       setDropdownData(dataToPass);
  //       setActiveDropdown("destination"); 
  //   }
  // }, [datas]);


  useEffect(() => {
    const loadLikedItems = () => {
      const token = localStorage.getItem('accessToken');
      if (token) {
        const likedTripIds = JSON.parse(localStorage.getItem('likedTripIds')) || [];
        const likedItemsMap = likedTripIds.reduce((acc, trip_id) => {
          acc[trip_id] = true;
          return acc;
        }, {});
        setLikedItems(likedItemsMap);
      }
    };

    loadLikedItems();
  }, []);

  const parseCustomDate = (dateString) => {
    try {
      const regex = /^(\d{1,2})[a-z]{2}\s(\w+)\s(\d{4})$/;
      const match = dateString.match(regex);
      if (match) {
        const [, day, month, year] = match;
        const monthIndex = new Date(Date.parse(month + " 1, 2024")).getMonth();
        return new Date(year, monthIndex, day);
      } else {
        throw new Error('Invalid date format');
      }
    } catch (error) {
      console.error('Date parsing error:', error);
      return null;
    }
  };

  const formatCustomDate = (date) => {
    try {
      return date && !isNaN(date.getTime()) ? format(date, 'dd MMM yyyy') : 'Invalid date';
    } catch (error) {
      console.error('Date formatting error:', error);
      return 'Invalid date';
    }
  };

  const toggleDropdown = (event, identifier) => {
    if (event) event.stopPropagation();

    let dataToPass;
    if (identifier === 'destination') {
      dataToPass = uniqueDestinations.map(option => ({ group: "Destination", option }));
    } else if (identifier === 'tripType') {
      dataToPass = uniqueTripTypes.map(option => ({ group: "Trip Types", option }));
    } else if (identifier === 'SortBy') {
      dataToPass = uniqueSortBy.map(option => ({ group: "Sort By", option }));
    }

    // Toggle dropdown
    setActiveDropdown(activeDropdown === identifier ? null : identifier);
    setDropdownData(dataToPass);
  };

  const onClick = () => {
    setVisible(!visible);
    document.body.classList.toggle('no-scroll', !visible);
  };

  const clearAllFilters = () => {
    // Clear all filter selections
    setSelectedDestinations([]);
    setSelectedTripTypes([]);
    setSelectedSortBy([]);
    setStartDate('');
    setEndDate('');
    setIsDateVisible(false);

    // Reset filtersApplied state to false
    setFiltersApplied(false);

    // Get the current date and calculate the first day of the current month
    const currentDate = new Date();
    const firstDayOfCurrentMonth = startOfMonth(currentDate);

    // Filter trips based on the current month
    const filteredTrips = originalData.filter((trip) => {
      const tripStartDate = parseCustomDate(trip.trip_start_date);
      return tripStartDate && (isAfter(tripStartDate, firstDayOfCurrentMonth) || tripStartDate.getTime() === firstDayOfCurrentMonth.getTime());
    });

    // Update filtered data
    setFilteredData(filteredTrips);
    setVisible(false);
  };



  const removeSelectedOption = (option, setSelectedFunction, filterType) => {
    setSelectedFunction(prevSelected => {
      const updatedSelected = prevSelected.filter(item => item !== option);

      // Check if there are no filters left
      if (updatedSelected.length === 0) {
        // Activate clearAllFilters if no filters are left
        clearAllFilters();
      } else {
        // Apply filters and update the selected options
        applyFilters(updatedSelected, filterType);
      }

      // Return the updated selected options
      return updatedSelected;
    });
  };


  const parseDateString = (dateString) => {
    const [day, monthName, year] = dateString.split(' ');
    const month = new Date(Date.parse(monthName + " 1, 2020")).getMonth();
    return new Date(year, month, parseInt(day, 10));
  };

  const applyFilters = (updatedSelections = null, filterType = null) => {
    let filtered = [...filteredData]; // Use a shallow copy to avoid mutating the original data

    const destinations = filterType === 'destination' ? updatedSelections : selectedDestinations;
    const tripTypes = filterType === 'tripType' ? updatedSelections : selectedTripTypes;
    const sortBy = filterType === 'SortBy' ? updatedSelections : selectedSortBy;

    // Filter by date range
    if (startDate && endDate) {
      const selectedStartDate = new Date(startDate);
      const selectedEndDate = new Date(endDate);

      filtered = filtered.filter(item => {
        const tripStartDate = parseDateString(item.trip_start_date);
        const tripEndDate = parseDateString(item.end_date);

        return (tripStartDate >= selectedStartDate && tripStartDate <= selectedEndDate) ||
          (tripEndDate >= selectedStartDate && tripEndDate <= selectedEndDate) ||
          (tripStartDate <= selectedStartDate && tripEndDate >= selectedEndDate);
      });
    }

    // Filter by destination
    if (destinations.length > 0) {
      filtered = filtered.filter(item => destinations.includes(item.destination));
    }

    // Filter by trip type
    if (tripTypes.length > 0) {
      filtered = filtered.filter(item => tripTypes.includes(item.trip_type));
    }

    // Filter by sort by
    if (sortBy.length > 0) {
      filtered = filtered.filter(item => sortBy.includes(item.seat_type));
    }

    // Update state with filtered data and set filtersApplied to true
    setFilteredData(filtered);
    setFiltersApplied(true); // Mark filters as applied
    setVisible(false);
    document.body.classList.remove('no-scroll');
  };



  const toggleDateVisibility = () => {
    setIsDateVisible(prev => !prev);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    window.location.reload();

  };

  const selectedFiltersCount = selectedDestinations.length + selectedTripTypes.length + selectedSortBy.length;
  useEffect(() => {
    if (keyword.trim() === '') {
      setFilteredData(originalData);
    } else {
      const lowercasedKeyword = keyword.toLowerCase();
      const filtered = originalData.filter(trip => {
        const tripName = trip.trip_name.toLowerCase();
        return tripName.includes(lowercasedKeyword);
      });
      setFilteredData(filtered);
    }
  }, [keyword, originalData]);

  const handleNextPage = () => {
    if (currentPage * itemsPerPage < filteredData.length) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const currentItems = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleLikeToggle = async (trip_id, e) => {
    if (e) {
      e.preventDefault();
    }

    setLikedItems((prev) => {
      const newLikedItems = {
        ...prev,
        [trip_id]: !prev[trip_id],
      };

      return newLikedItems;
    });

    try {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        setShowPopup(true);
        return;
      }

      const { userId } = JSON.parse(atob(token.split('.')[1]));

      if (likedItems[trip_id]) {
        await axios.post('https://yeahtrips.in/api/removeTrip', {
          trip_id,
          userId,
        });
      } else {
        await axios.post('https://yeahtrips.in/api/saveTrip', {
          trip_id,
          userId,
        });
      }
      const handleDateSelection = (e) => {
        const { name, value } = e.target;
        if (name === 'startDate') {
          setStartDate(value);
        } else if (name === 'endDate') {
          setEndDate(value);
        }
      };

      // Update localStorage only if the token is present
      const updatedLikedTripIds = Object.keys(likedItems).filter(trip_id => likedItems[trip_id]);
      localStorage.setItem('likedTripIds', JSON.stringify(updatedLikedTripIds));
    } catch (error) {
      console.error('Error saving or removing liked trip:', error);
    }
  };


  const handleDateSelection = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate') {
      setStartDate(value);
    } else if (name === 'endDate') {
      setEndDate(value);
    }
  };

  const handleButtonClick = () => {
    setIsDateVisible(prevState => !prevState);
  };
  const handleCloseSelectedDates = () => {
    setStartDate('');
    setEndDate('');
    setIsDateVisible(false);
  };

  return (
    < div className='outercontaineroffuturreescape'>
      <Helmet>
        <title>Future Escapes | YEAH - Discover Upcoming Adventure Trips</title>
        <meta name='description' content='Explore upcoming adventure trips with YEAH. Plan your next getaway with exclusive travel deals and curated experiences for young adults.' />
        <meta name='keywords' content='future trips, adventure travel, upcoming trips, YEAH Future Escapes, explore destinations, curated trips, travel deals, future adventures, book trips, getaway planning' />
        <meta property='og:title' content='Future Escapes | YEAH - Upcoming Adventure Trips' />
        <meta property='og:description' content='Discover exciting upcoming trips with YEAH. Explore new destinations, book curated experiences, and plan your future adventures today.' />
        <meta property='og:type' content='website' />
        <meta property='og:url' content='https://yeahtrips.in/futureescapes' />
      </Helmet>

      <Navbar setKeyword={setKeyword} />
      <div className='futureescapesheadercontainer'>
        <div className='futrureescapestotalcontainer'>
          <div className="elementcorosaul">
            <img src={thundericon} alt="Thunder icon" />
            <p>An initiative by experienced folks of </p>
            <img src={logocamp} alt="Logo camp" />
          </div>

          <div className='futureescapeheading'>
            <h1>Explore <span className='gradientcolor'> Exciting </span> Trips For Your Next <span className='gradientcolor'>Adventure</span>  🏕️</h1>
          </div>
        </div>
      </div>

      <div className='background_future'>
        <div className='futuregroup-1'>
          {filtersApplied && (selectedDestinations.length > 0 || selectedTripTypes.length > 0 || selectedSortBy.length > 0 || (startDate && endDate)) ? (
            <div className='clear-container'>
              <button onClick={clearAllFilters} className='clear-all-filters-button'>Clear All Filters</button>
              <div className='selected-filters'>
                {selectedDestinations.map(option => (
                  <span className='selected-filter' key={option}>
                    {option}
                    <img src={X} alt="Remove" style={{ height: '3vh' }} onClick={() => removeSelectedOption(option, setSelectedDestinations, 'destination')} />
                  </span>
                ))}
                {selectedTripTypes.map(option => (
                  <span className='selected-filter' key={option}>
                    {option}
                    <img src={X} alt="Remove" style={{ height: '3vh' }} onClick={() => removeSelectedOption(option, setSelectedTripTypes, 'tripType')} />
                  </span>
                ))}
                {startDate && endDate && (
                  <div className='future-selected-dates'>
                    {startDate} <img src={Arrowright} /> {endDate}
                    <img src={X} onClick={handleCloseSelectedDates} style={{ 'width': '1.2vw' }} />
                  </div>
                )}
                {selectedSortBy.map(option => (
                  <span className='selected-filter' key={option}>
                    {option}
                    <img src={X} alt="Remove" style={{ height: '3vh' }} onClick={() => removeSelectedOption(option, setSelectedSortBy, 'SortBy')} />
                  </span>
                ))}
              </div>

            </div>
          ) : (
            <div className='futureescapestripavaliabletext'>{filteredData.length} Amazing Trips Available 🏖️</div>
          )}
          <div className='filtergroup' onClick={onClick}>
            <div className='futuregrad'>Apply Filters {selectedFiltersCount > 0 && `(${selectedFiltersCount})`}</div>
            <div className='futureescapesfunnelimage'><img src={Funnel} alt="Filter Funnel" /></div>
          </div>
        </div>

        <div className='future_outline'>
          <div className='future_imagecontainer'>
            {currentItems.map((item) => (
              <img src={`https://admin.yeahtrips.in${item.file_path}`} alt="images" key={item.trip_id} />
            ))}
          </div>

          {currentItems.map((item) => (
            <Link to={`/${item.slug}`} className='future-contents' key={item.trip_id}>
              <div className='future-entire'>
                <div className='content-group1'>
                  <div className='future-seats'>Only {(item.totalseats) - (item.seats)} seats left</div>
                  <div className='heart-container'>
                    <i
                      className={`fa-heart ${likedItems[item.trip_id] ? 'fas red' : 'far'}`}
                      onClick={(e) => handleLikeToggle(item.trip_id, e)}
                    ></i>
                  </div>

                </div>
                <div className='content-group2'>
                  <div className='futuretripnamehomepage'>{item.trip_name}</div>
                  <div className='content-group3'>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                      <img src={Calendar} alt="Calendar Icon" />
                      <span>{formatCustomDate(parseCustomDate(item.trip_start_date))}</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                      <img src={Arrow} alt="Arrow Icon" />
                      <span>{formatCustomDate(parseCustomDate(item.end_date))}</span>
                    </div>

                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                    <img src={Location} alt="Location Icon" />
                    <span>Starts from {item.trip_start_point}</span>
                  </div>
                </div>



              </div>


            </Link>


          ))}
          {showPopup && <div className="overlaypopup"></div>}
          {showPopup && <Onboarderror onClose={handleClosePopup} />}

        </div>

        <div className='pagination'>
          <div className='page-text'>Page {currentPage} of {Math.ceil(filteredData.length / itemsPerPage)}</div>
          <div className='page-button-container'>
            <button onClick={handlePrevPage} className='page-button' disabled={currentPage === 1}>
              <img src={Arrowleft} className='pageimage' alt="Previous" />
              Previous
            </button>
            <button onClick={handleNextPage} className='page-button' disabled={currentPage * itemsPerPage >= filteredData.length}>
              Next
              <img src={Arrowright} className='pageimage' alt="Next" />
            </button>
          </div>

        </div>

      </div>

      {visible && (
        <>
          <div className="future-overlay"></div>
          <div className="future-sidebar">
            <div className="topbar-button">
              <div className="future-topbar-container">
                <div className="future-topbar">
                  <div className="future-topbar_contents">
                    <h2>Filters</h2>
                    <div className="future-filter-group1">
                      <div className="clearallgrad" onClick={clearAllFilters}>
                        Clear All
                      </div>
                      <img src={X} alt="Close" className="future-close" onClick={onClick} />
                    </div>
                  </div>
                </div>
                <div className="future-button-container">
                  <button onClick={(e) => toggleDropdown(e, "destination")} className="future-button">
                    Destination
                    {selectedDestinations.length > 0 && (
                      <div className="selected-options">
                        {selectedDestinations.map((option) => (
                          <div className="selected-option" key={option}>
                            {option}
                            <img
                              src={X}
                              alt="Remove"
                              style={{ height: "3vh" }}
                              onClick={() => removeSelectedOption(option, setSelectedDestinations)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    {activeDropdown === "destination" && (
                      <Dropdown
                        data={dropdownData}
                        selectedOptions={selectedDestinations}
                        setSelectedOptions={setSelectedDestinations}
                      />
                    )}
                  </button>

                  <button onClick={(e) => toggleDropdown(e, "tripType")} className="future-button">
                    Trip Type
                    {selectedTripTypes.length > 0 && (
                      <div className="selected-options">
                        {selectedTripTypes.map((option) => (
                          <div className="selected-option" key={option}>
                            {option}
                            <img
                              src={X}
                              alt="Remove"
                              style={{ height: "3vh" }}
                              onClick={() => removeSelectedOption(option, setSelectedTripTypes)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    {activeDropdown === "tripType" && (
                      <Dropdown
                        data={dropdownData}
                        selectedOptions={selectedTripTypes}
                        setSelectedOptions={setSelectedTripTypes}
                      />
                    )}
                  </button>

                  <button className="future-button" onClick={toggleDateVisibility}>
                    Date
                    {isDateVisible && (
                      <div className="date-inputs" onClick={handleButtonClick}>
                        <label htmlFor="startDate" style={{ color: "#344054", fontSize: "1.1vw", fontWeight: "normal" }}>
                          Start Date
                        </label>
                        <input
                          className="future-datefilter"
                          placeholder="Select Date"
                          type="date"
                          name="startDate"
                          value={startDate}
                          onChange={handleDateSelection}
                        />
                        <label htmlFor="endDate" style={{ color: "#344054", fontSize: "1.1vw", fontWeight: "normal" }}>
                          End Date
                        </label>
                        <input
                          className="future-datefilter"
                          placeholder="Select Date"
                          type="date"
                          name="endDate"
                          value={endDate}
                          onChange={handleDateSelection}
                        />
                      </div>
                    )}
                    {startDate && endDate && !isDateVisible && (
                      <div className="future-selected-dates">
                        {startDate} <img src={Arrowright} alt="Arrow" /> {endDate}
                        <img src={X} onClick={handleCloseSelectedDates} style={{ width: "1.2vw" }} alt="Remove" />
                      </div>
                    )}
                  </button>

                  <button onClick={(e) => toggleDropdown(e, "SortBy")} className="future-button">
                    Sort By
                    {selectedSortBy.length > 0 && (
                      <div className="selected-options">
                        {selectedSortBy.map((option) => (
                          <div className="selected-option" key={option}>
                            {option}
                            <img
                              src={X}
                              alt="Remove"
                              style={{ height: "3vh" }}
                              onClick={() => removeSelectedOption(option, setSelectedSortBy)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    {activeDropdown === "SortBy" && (
                      <Dropdown
                        data={dropdownData}
                        selectedOptions={selectedSortBy}
                        setSelectedOptions={setSelectedSortBy}
                      />
                    )}
                  </button>
                </div>
              </div>
              <div className="future-downbar">
                <div className="future-downbar_contents">
                  <div className="clearallgrad123" onClick={clearAllFilters}>
                    Clear All
                  </div>
                  <button className="future-downbar-button" onClick={applyFilters}>
                    Apply
                  </button>
                </div>
              </div>
            </div>
            <div className="filterdisplayesresponsivescreens">
              <div className="futuretopbarfilter">
                <div className="futuretopbarfiltertext">
                  <h2>Filter</h2>
                </div>
                <img src={X} alt="Close" onClick={onClick} />
              </div>

              <div className="filtercomponentssectionresponsive">
                <div className="future-button-container-responsive">
                  <button
                    className={`future-button-responsive ${activeDropdown === "destination" ? "active" : ""}`}
                    data-text="Destination" // Add data attribute for text content
                    onClick={(e) => toggleDropdown(e, "destination")}
                  >
                    Destination
                    {selectedDestinations.length > 0 && (
                      <div className="selected-options">
                        {selectedDestinations.map((option) => (
                          <div className="selected-option" key={option}>
                            {option}
                            <img
                              src={X}
                              alt="Remove"
                              onClick={() =>
                                setSelectedDestinations((prev) => prev.filter((o) => o !== option))
                              }
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </button>

                  <button
                    className={`future-button-responsive ${activeDropdown === "tripType" ? "active" : ""}`}
                    data-text="Trip Type"
                    onClick={(e) => toggleDropdown(e, "tripType")}
                  >
                    Trip Type
                    {selectedTripTypes.length > 0 && (
                      <div className="selected-options">
                        {selectedTripTypes.map((option) => (
                          <div className="selected-option" key={option}>
                            {option}
                            <img
                              src={X}
                              alt="Remove"
                              onClick={() =>
                                setSelectedTripTypes((prev) => prev.filter((o) => o !== option))
                              }
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </button>

                  <button
                    className={`future-button-responsive ${activeDropdown === "date" ? "active" : ""}`}
                    data-text="Date"
                    onClick={() => toggleDropdown(null, "date")}
                  >
                    Date
                    {startDate && endDate && !isDateVisible && (
                      <div className="future-selected-dates">
                        {startDate} <img src={Arrowright} alt="Arrow" /> {endDate}
                        <img src={X} alt="Remove" onClick={handleCloseSelectedDates} />
                      </div>
                    )}
                  </button>

                  <button
                    className={`future-button-responsive ${activeDropdown === "SortBy" ? "active" : ""}`}
                    data-text="Sort By"
                    onClick={(e) => toggleDropdown(e, "SortBy")}
                  >
                    Sort By
                    {selectedSortBy.length > 0 && (
                      <div className="selected-options">
                        {selectedSortBy.map((option) => (
                          <div className="selected-option" key={option}>
                            {option}
                            <img
                              src={X}
                              alt="Remove"
                              onClick={() => setSelectedSortBy((prev) => prev.filter((o) => o !== option))}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </button>
                </div>


                {/* Dropdown will be rendered here based on the active selection */}
                <div className="dropdownfilterresponsive">
                  {activeDropdown === "destination" && (
                    <div>

                      <Dropdown
                        data={dropdownData}
                        selectedOptions={selectedDestinations}
                        setSelectedOptions={setSelectedDestinations}
                      />
                    </div>
                  )}

                  {activeDropdown === "tripType" && (
                    <div>
                      <Dropdown
                        data={dropdownData}
                        selectedOptions={selectedTripTypes}
                        setSelectedOptions={setSelectedTripTypes}
                      />
                    </div>
                  )}

                  {activeDropdown === "date" && (
                    <div className="date-inputs" onClick={(e) => e.stopPropagation()}>
                      <label htmlFor="startDate">Start Date</label>
                      <input
                        type="date"
                        name="startDate"
                        value={startDate}
                        onChange={handleDateSelection}
                      />
                      <label htmlFor="endDate">End Date</label>
                      <input
                        type="date"
                        name="endDate"
                        value={endDate}
                        onChange={handleDateSelection}
                      />
                    </div>
                  )}

                  {activeDropdown === "SortBy" && (
                    <div>
                      <Dropdown
                        data={dropdownData}
                        selectedOptions={selectedSortBy}
                        setSelectedOptions={setSelectedSortBy}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="future-downbar">
                <div className="future-downbar_contents">
                  <div className="clearallgrad123" onClick={clearAllFilters}>
                    Clear All
                  </div>
                  <button className="future-downbar-button" onClick={applyFilters}>
                    Apply
                  </button>
                </div>
              </div>
            </div>

          </div>


        </>
      )}

      <div>
        <Getintouch />
        <Footer />
      </div>

    </div>
  );
}

export default Future;
