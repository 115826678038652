import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Login.css';
import cross from './Images/x-circle.svg';
import Logo from './Images/Logomark.svg';
import { Link, useNavigate } from "react-router-dom";
import Landingpage from '../../homepage/Landinhpage/Landingpage';
import { Helmet } from 'react-helmet';

function Login() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneCodes, setPhoneCodes] = useState([]);
  const [phoneCode, setPhoneCode] = useState('+91');
  const [email, setEmail] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('IN');
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [errors, setErrors] = useState({ phone: '', email: '' });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.toggle('no-scroll', isSidebarVisible);
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isSidebarVisible]);

  useEffect(() => {
    const isValidPhoneNumber = phoneNumber.length >= 10 && /^\d+$/.test(phoneNumber);
    const isFormValid = (isValidPhoneNumber || email) && (!errors.phone && !errors.email);
    setIsButtonDisabled(!isFormValid);
  }, [phoneNumber, email, errors]);

  const fetchPhoneCodes = () => {
    axios.get('https://yeahtrips.in/phonecode')
      .then(response => {
        const data = response.data;
        setPhoneCodes(data);
        const defaultCode = data.find(c => c.iso3 === selectedCountry)?.phonecode || '91';
        setPhoneCode(`+${defaultCode}`);
        setDropdownVisible(true);
      })
      .catch(error => console.error('Error fetching phone codes:', error));
  };

  const handleCountryChange = (e) => {
    const country = e.target.value;
    setSelectedCountry(country);
    const code = phoneCodes.find(c => c.iso3 === country)?.phonecode || '91';
    setPhoneCode(`+${code}`);
    setPhoneNumber(''); // Reset phone number when country changes
  };

  const handleSelectClick = () => {
    if (!dropdownVisible) {
      fetchPhoneCodes();
    }
  };

  const handleCloseClick = () => {
    setSidebarVisible(false);
    navigate('/');
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value.trim();
    const digitsOnly = input.slice(phoneCode.length).replace(/\D/g, '');
    setPhoneNumber(digitsOnly);
  };

  const validateEmail = (value) => {
    const newErrors = { ...errors };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (value.trim() === '') {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(value)) {
      newErrors.email = 'Invalid email address';
    } else {
      newErrors.email = '';
    }

    setErrors(newErrors);
  };

  const validateForm = () => {
    const newErrors = { phone: '', email: '' };
    const phoneNumberOnly = phoneNumber.startsWith(phoneCode)
      ? phoneNumber.slice(phoneCode.length).trim()
      : phoneNumber.trim();

    // Validate Phone Number
    if (!phoneNumberOnly && !email) {
      newErrors.phone = 'Phone number or email is required';
      newErrors.email = 'Phone number or email is required';
    } else if (phoneNumberOnly && phoneNumberOnly.length < 10) {
      newErrors.phone = 'Phone number must be at least 10 digits';
    }

    // Validate Email
    if (email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        newErrors.email = 'Invalid email address';
      }
    }

    setErrors(newErrors);

    // Return whether the form is valid
    return !Object.values(newErrors).some(error => error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Stop if the form is not valid
    }

    const phoneNumberOnly = phoneNumber.startsWith(phoneCode)
      ? phoneNumber.slice(phoneCode.length).trim()
      : phoneNumber.trim();

    const payload = email
      ? { email }
      : { phonenumber: phoneCode + phoneNumberOnly };

    try {
      const response = await axios.post('https://yeahtrips.in/login', payload);
      const { success, otp } = response.data;

      if (success) {
        setEmail('');
        setPhoneNumber('');
        navigate('/otp', { state: { email, phonenumber: phoneCode + phoneNumberOnly } });
      } else {
        navigate('/signup');
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  return (
    <div>
     <Helmet>
      <title>Login | YEAH - Your Adventure Awaits</title>
      <meta name='description' content='Login to your YEAH account to manage your trips, view exclusive offers, and explore new destinations.' />
      <meta name='keywords' content='YEAH login, travel login, adventure trips, user account, manage bookings, YEAH portal, sign in to YEAH, account access' />
      <meta property='og:title' content='YEAH - Login to Your Account' />
      <meta property='og:description' content='Access your YEAH account to manage bookings, view upcoming trips, and more.' />
      <meta property='og:type' content='website' />
      <meta property='og:url' content='https://yeahtrips.in/login' />
     </Helmet>

      <div className='landingpagebottomofsignup'>
        <Landingpage />
      </div>
      <div className='overlay' style={{ display: isSidebarVisible ? 'block' : 'none' }}></div>
      <div className={isSidebarVisible ? 'sidebar' : 'sidebar hidden'}>
        <div className='topbar'>
          <div className='topbar_contents'>
            <h2>Login</h2>
            <img src={cross} alt="Close" style={{ cursor: 'pointer' }} className='close' onClick={handleCloseClick} />
          </div>
        </div>
        <div className='body'>
          <img src={Logo} alt="Logo" className='logo' />
          <div className='group1'>
            <h2>Welcome Back!</h2>
            <div className='tripsignupcontainertextss'>
              Log in to continue exploring and booking your next adventure.
            </div>
          </div>
          <div className='group2'>
            <div className='inp-container'>
              <label htmlFor='PhoneNumber'>Phone number</label><br />
              <div className="phone-input">
                <select
                  className={selectedCountry === 'IN' || selectedCountry === 'US' ? 'select-borderless' : ''}
                  onChange={handleCountryChange}
                  value={selectedCountry}
                  onClick={handleSelectClick}
                >
                  {phoneCodes.length > 0 ? (
                    phoneCodes.map((code) => (
                      <option key={code.iso3} value={code.iso3}>
                        {code.iso3} (+{code.phonecode})
                      </option>
                    ))
                  ) : (
                    <option value="IN">IN (+91)</option>
                  )}
                </select>
                <input
                  className='inputlogin'
                  type="text"
                  id="phone"
                  name="phone"
                  value={`${phoneCode}${phoneNumber}`}
                  onChange={handlePhoneChange}
                  placeholder="0000000000"
                />
              </div>
              {errors.phone && <div className='error'>{errors.phone}</div>}
            </div>
            <div className='linecontainer'>
              <div className='line'></div>
              <div style={{ color: 'rgba(93, 93, 93, 1)' }}>Or</div>
              <div className='line'></div>
            </div>

            <div className='inp-container'>
              <label htmlFor='Email'>Email</label><br />
              <input
                className='inputlogin'
                type='email'
                id='Email'
                name='email'
                placeholder='Enter your email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => validateEmail(email)}
              />
              {errors.email && <div className='error'>{errors.email}</div>}
            </div>
          </div>
          <div className='group3'>
            <button className='login' onClick={handleSubmit} >Log In</button>
            <div className='final-contents'>
              <span style={{ color: 'rgba(71, 84, 103, 1)' }}>Don't have an account?</span>
              <Link to='/signup'><span className='gradientcolorlogin'>Sign Up</span></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;







// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import './Login.css';
// import cross from './Images/x-circle.svg';
// import Logo from './Images/Logomark.svg';
// import { Link, useNavigate } from "react-router-dom";
// import Landingpage from '../../homepage/Landinhpage/Landingpage';

// function Login() {
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [phoneCodes, setPhoneCodes] = useState([]);
//   const [phoneCode, setPhoneCode] = useState('+91');
//   const [email, setEmail] = useState('');
//   const [selectedCountry, setSelectedCountry] = useState('IND');
//   const [isSidebarVisible, setSidebarVisible] = useState(true);
//   const [errors, setErrors] = useState({ phone: '', email: '' });
//   const [isButtonDisabled, setIsButtonDisabled] = useState(true);
//   const [dropdownVisible, setDropdownVisible] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     document.body.classList.toggle('no-scroll', isSidebarVisible);
//     return () => {
//       document.body.classList.remove('no-scroll');
//     };
//   }, [isSidebarVisible]);

//   useEffect(() => {
//     const isFormValid = (phoneNumber || email) && (!errors.phone && !errors.email);
//     setIsButtonDisabled(!isFormValid);
//   }, [phoneNumber, email, errors]);

//   useEffect(() => {
//     const fetchPhoneCodes = async () => {
//       try {
//         const response = await axios.get('https://betayeah.yeahtrips.in/phonecode');
//         const data = response.data;
//         setPhoneCodes(data);
//         const defaultCode = data.find(c => c.iso3 === selectedCountry)?.phonecode || '91';
//         setPhoneCode(`+${defaultCode}`);
//         setDropdownVisible(true);
//       } catch (error) {
//         console.error('Error fetching phone codes:', error);
//       }
//     };

//     fetchPhoneCodes();
//   }, [selectedCountry]);

//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://control.msg91.com/app/assets/otp-provider/otp-provider.js';
//     script.async = true;
//     script.onload = () => {
//       if (window.initSendOTP) {
//         const configuration = {
//           widgetId: '34684575714e313333363338',
//           tokenAuth: '{428816Aghtkkxj66c89d28P1}', // Replace {token} with your actual token
//           identifier: '', // Optionally set this if you have an identifier
//           exposeMethods: 'true', // Set to 'true' if you want to expose methods for OTP verification
//           success: (data) => {
//             console.log('success response', data);
//           },
//           failure: (error) => {
//             console.log('failure reason', error);
//           },
//         };
//         window.initSendOTP(configuration);
//       }
//     };
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   const handleCountryChange = (e) => {
//     const country = e.target.value;
//     setSelectedCountry(country);
//     const code = phoneCodes.find(c => c.iso3 === country)?.phonecode || '91';
//     setPhoneCode(`+${code}`);
//     setPhoneNumber(''); // Reset phone number when country changes
//   };

//   const handleSelectClick = () => {
//     if (!dropdownVisible) {
//       fetchPhoneCodes();
//     }
//   };

//   const handleCloseClick = () => {
//     setSidebarVisible(false);
//     navigate('/');
//   };

//   const handlePhoneChange = (e) => {
//     const input = e.target.value.trim();
    
//     // Ensure only digits after the + sign are editable
//     const digitsOnly = input.slice(phoneCode.length).replace(/\D/g, '');
//     setPhoneNumber(digitsOnly);
    
//     const newErrors = { ...errors };
//     if (digitsOnly.trim() === '') {
//       newErrors.phone = 'Phone number is required';
//     } else if (!/^\d+$/.test(digitsOnly.trim())) {
//       newErrors.phone = 'Phone number must contain only digits';
//     } else {
//       newErrors.phone = '';
//     }
//     setErrors(newErrors);
//   };

//   const validateEmail = (value) => {
//     const newErrors = { ...errors };
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

//     if (value.trim() === '') {
//       newErrors.email = 'Email is required';
//     } else if (!emailRegex.test(value)) {
//       newErrors.email = 'Invalid email address';
//     } else {
//       newErrors.email = '';
//     }

//     setErrors(newErrors);
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;

//     if (name === 'phone') {
//       handlePhoneChange(e);
//     }
//     if (name === 'email') {
//       setEmail(value);
//       validateEmail(value);
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Ensure either phone number or email is provided
//     if (!(phoneNumber || email)) {
//       alert('Either phone number or email is required');
//       return;
//     }

//     // Prepare the payload
//     const payload = email
//       ? { email } // Only email in payload
//       : { phonenumber: phoneCode + phoneNumber }; // Phone number with code in payload

//     if (isButtonDisabled) return;

//     try {
//       // Send OTP request to your backend
//       const response = await axios.post('https://betayeah.yeahtrips.in/login', payload);
//       const { success, message, otp } = response.data;

//       if (success) {
//         alert(`OTP received: ${otp}`);
//         setEmail("");
//         setPhoneNumber("");
//         navigate('/otp', { state: { email, phonenumber: phoneCode + phoneNumber } });
//       } else {
//         navigate('/signup');
//       }
//     } catch (error) {
//       console.error('Login error:', error);
//     }
//   };

//   return (
//     <div>
//       <div className='landingpagebottomofsignup'>
//         <Landingpage />
//       </div>
//       <div className='overlay' style={{ display: isSidebarVisible ? 'block' : 'none' }}></div>
//       <div className={isSidebarVisible ? 'sidebar' : 'sidebar hidden'}>
//         <div className='topbar'>
//           <div className='topbar_contents'>
//             <h2>Login</h2>
//             <img src={cross} alt="Close" style={{ cursor: 'pointer' }} className='close' onClick={handleCloseClick} />
//           </div>
//         </div>
//         <div className='body'>
//           <img src={Logo} alt="Logo" className='logo' />
//           <div className='group1'>
//             <h2>Welcome Back!</h2>
//             <div className='tripsignupcontainertextss'>
//               Log in to continue exploring and booking your next adventure.
//             </div>
//           </div>
//           <div className='group2'>
//             <div className='inp-container'>
//               <label htmlFor='PhoneNumber'>Phone number</label><br />
//               <div className="phone-input">
//                 <select
//                   className={selectedCountry === 'IN' || selectedCountry === 'US' ? 'select-borderless' : ''}
//                   onChange={handleCountryChange}
//                   value={selectedCountry}
//                   onClick={handleSelectClick}
//                 >
//                   {phoneCodes.length > 0 ? (
//                     phoneCodes.map((code) => (
//                       <option key={code.iso3} value={code.iso3}>
//                         {code.iso3} (+{code.phonecode})
//                       </option>
//                     ))
//                   ) : (
//                     <option value="IN">IN (+91)</option>
//                   )}
//                 </select>
//                 <input
//                   className='inputlogin'
//                   type="text"
//                   id="phone"
//                   name="phone"
//                   value={`${phoneCode}${phoneNumber}`}
//                   onChange={handlePhoneChange}
//                   placeholder="0000000000"
//                 />
//               </div>
//               {errors.phone && <div className='error'>{errors.phone}</div>}
//             </div>
//             <div className='inp-container'>
//               <label htmlFor='Email'>Email</label><br />
//               <input
//                 className='inputlogin'
//                 type='email'
//                 id='Email'
//                 name='email'
//                 placeholder='Enter your email'
//                 value={email}
//                 onChange={handleInputChange}
//               />
//               {errors.email && <div className='error'>{errors.email}</div>}
//             </div>
//           </div>
//           <div className='group3'>
//             <button className='login' onClick={handleSubmit} disabled={isButtonDisabled}>Log In</button>
//             <div className='final-contents'>
//               <span style={{ color: 'rgba(71, 84, 103, 1)' }}>Don't have an account?</span>
//               <Link to='/signup'><span className='gradientcolorlogin'>Sign Up</span></Link>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Login;
